(function() {
	angular
		.module('portal.manager.organization', [
			'ui.router',
			'portal.manager.navigation',
			'portal.manager.paginator',
			'angular.filter',
			'api.manager',
			'api.application.user',
			'ui.notifications'
		])
		.config(config)
		.controller('ManagerOrganizationController', controller);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('manager.organization', {
				controller: controller,
				controllerAs: 'model',
				url: '/organization',
				abstract: true,
				templateUrl: 'management/organization/organization.layout.tpl.html'
			})
			.state('manager.organization.organizations', {
				url: '/:id/organizations',
				views: {
					actions: {
						template:
							'<section il-manager-actions="paginator_organizations" type="organization" data-organization-id="model.organization.id"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_organizations" type="organization"></section>'
					}
				},
				data: { displayName: 'Management/Organization/Organizations' }
			})
			.state('manager.organization.groups', {
				url: '/:id/groups',
				views: {
					actions: {
						template:
							'<section il-manager-actions="paginator_groups" type="group" data-organization-id="model.organization.id"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_groups" type="group"></section>'
					}
				},
				data: { displayName: 'Management/Organization/Groups' }
			})
			.state('manager.organization.staff', {
				url: '/:id/staff',
				views: {
					actions: {
						template:
							'<section il-manager-actions="paginator_staff" type="staff" data-organization-id="model.organization.id"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_staff" type="staff"></section>'
					}
				},
				data: { displayName: 'Management/Organization/Staff' }
			})
			.state('manager.organization.students', {
				url: '/:id/students',
				views: {
					actions: {
						template:
							'<section il-manager-actions="paginator_students" type="student" data-organization-id="model.organization.id"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_students" type="student"></section>'
					}
				},
				data: { displayName: 'Management/Organization/Students' }
			});
	}

	controller.$inject = [
		'$scope',
		'$state',
		'Paginator',
		'NotificationFactory',
		'Navigation',
		'Organization',
		'User',
		'ApplicationUserService',
		'$timeout',
		'Types'
	];

	function controller(
		$scope,
		$state,
		Paginator,
		NotificationFactory,
		Navigation,
		Organization,
		User,
		ApplicationUserService,
		$timeout,
		Types
	) {
		var model = this,
			currentUser = ApplicationUserService.getUser();
		model.organization = undefined;
		model.id = undefined;
		model.state = undefined;
		model.stateChanged = stateChanged;

		var organizations = new Paginator(
			$scope,
			'paginator_organizations',
			function() {
				return Organization.children(model.id, organizations.params);
			},
			model.id
		);
		organizations.params = {
			additionalFields: ['lexileSettings', 'fullOrganizationCount', 'fullGroupCount', 'fullUserCount', 'fullStudentCount'],
			sortby: 'name'
		};

		var groups = new Paginator(
			$scope,
			'paginator_groups',
			function() {
				return Organization.classes(model.id, groups.params);
			},
			model.id
		);
		groups.params = {
			additionalFields: ['userCount', 'studentCount', 'users'],
			includeDescendants: true,
			sortby: 'name'
		};

		var staff = new Paginator(
			$scope,
			'paginator_staff',
			function() {
				return Organization.users(model.id, staff.params);
			},
			model.id
		);
		staff.params = {
			additionalFields: ['studentCount'],
			includeDescendants: true,
			sortby: 'lastName,firstName'
		};

		var students = new Paginator(
			$scope,
			'paginator_students',
			function() {
				return currentUser.role === 'Teacher'
					? User.assignedStudents(currentUser.id, students.params)
					: Organization.students(model.id, students.params);
			},
			model.id
		);

		students.params = {
			includeDescendants: true,
			sortby: 'lastName,firstName',
			additionalFields: ['lexileSettings']
		};
		if (currentUser.role === 'Teacher' && model.id) {
			students.params.orgid = model.id;
		}

		function getOrganization(id) {
			if (!id) {
				NotificationFactory.error({ message: 'Invalid id' });
			}

			model.id = id;
			if (model.organization && model.organization.id === id) {
				return;
			}

			Organization.get(id)
				.then(function(result) {
					model.organization = result;
					model.organization.displayText = result.name;
					model.organization.entityType = Types.organization;

					Navigation.fetchBreadcrumbs(model.organization);
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		function stateChanged(event, toState, toParams, fromState, fromParams) {
			if (toState.name.indexOf('manager.organization') !== -1) {
				model.state = toState.name;
				getOrganization(toParams.id);

				switch (model.state) {
					case 'manager.organization.organizations':
						$timeout(function() {
							$scope.paginator_organizations.doInitialLoad();
						});

						break;

					case 'manager.organization.groups':
						$timeout(function() {
							$scope.paginator_groups.doInitialLoad();
						});
						break;

					case 'manager.organization.staff':
						$timeout(function() {
							$scope.paginator_staff.doInitialLoad();
						});
						break;

					case 'manager.organization.students':
						$timeout(function() {
							$scope.paginator_students.doInitialLoad();
						});
						break;
				}
			}
		}

		$scope.$on('$stateChangeSuccess', stateChanged);
	}
})();
