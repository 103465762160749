(function() {
	angular
		.module('portal.manager.search', [
			'ui.router',
			'ui.loader',
			'portal.manager.paginator',
			'portal.manager.search.stateHistory',
			'api.manager',
			'ui.notifications'
		])
		.controller('ManagerSearchController', controller)
		.config(config);

	config.$inject = ['$stateProvider'];

	function config($stateProvider) {
		$stateProvider
			.state('manager.search', {
				url: '/search',
				controller: controller,
				controllerAs: 'model',
				abstract: true,
				templateUrl: 'management/search/search.layout.tpl.html'
			})
			.state('manager.search.term', {
				url: '/:searchTerm',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_search" type="search"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_search" type="search"></section>'
					}
				}
			});
	}

	controller.$inject = [
		'$q',
		'$scope',
		'$state',
		'Paginator',
		'Organization',
		'Student',
		'User',
		'Class',
		'Search',
		'SearchStateHistory'
	];

	function controller($q, $scope, $state, Paginator, Organization, Student, User, Class, Search, SearchStateHistory) {
		var model = this,
			api = {},
			dataType = {
				unknown: 'unknown',
				student: 'Student',
				organization: 'Organization',
				user: 'User',
				group: 'Group'
			},
			searchPaginator;

		model.state = undefined;
		model.lastStateInfo = {};

		model.goToLastSate = goToLastSate;
		model.stateChanged = stateChanged;

		searchPaginator = new Paginator($scope, 'paginator_search', function() {
			return Search.paged(searchPaginator.params).then(function(searchResponse) {
				var queue = setupQueue(searchResponse);
				return $q.all(queue).then(function(data) {
					var formatedData = formatData(data),
						parentOrgsQueue = setupParentOrgsQueue(formatedData);
					return $q.all(parentOrgsQueue).then(function(orgData) {
						var uniqueOrgData = makeUnique(orgData),
							displayData = setupDisplayData(formatedData, uniqueOrgData),
							formattedDeferred = $q.defer();
						formattedDeferred.resolve({
							items: displayData,
							totalCount: searchResponse.totalCount,
							continuationToken: searchResponse.continuationToken
						});
						return formattedDeferred.promise;
					});
				});
			});
		});

		function makeUnique(orgData) {
			var tempData = [];
			angular.forEach(orgData, function(org) {
				if (angular.isDefined(org.items)) {
					angular.forEach(org.items, function(o) {
						tempData.push(o);
					});
				} else if (angular.isArray(org)) {
					angular.forEach(org, function(o) {
						tempData.push(o);
					});
				} else {
					tempData.push(org);
				}
			});

			return _.uniqBy(tempData, 'id');
		}

		function setupDisplayData(formatedData, orgData) {
			var refOrgs = parseOrgData(orgData),
				displayData = [];

			angular.forEach(formatedData, function(item) {
				item.orgs = [];
				if (item.type === dataType.organization) {
					item.orgs = getParentOrg(refOrgs, item.parentId);
				} else if (item.type === dataType.student) {
					item.orgs = getParentOrg(refOrgs, item.organizationIds);
				} else if (item.type === dataType.group) {
					item.orgs = getParentOrg(refOrgs, item.organizationId);
				} else if (item.type === dataType.user) {
					item.orgs = getParentOrg(refOrgs, _.map(item.organizations, 'id'));
				}
				displayData.push(item);
			});
			return displayData;
		}

		function getParentOrg(orgData, orgId) {
			var orgs = [];
			angular.forEach(orgData, function(org) {
				if (angular.isArray(orgId)) {
					angular.forEach(orgId, function(id) {
						if (org.id === id) {
							orgs.push(org);
						}
					});
				} else {
					if (org.id === orgId) {
						orgs.push(org);
					}
				}
			});
			return orgs;
		}

		function parseOrgData(orgData) {
			var parsedOrgData = [];
			angular.forEach(orgData, function(org) {
				if (angular.isArray(org)) {
					angular.forEach(org, function(o) {
						if (!dataFoundInList(parsedOrgData, o, 'id')) {
							parsedOrgData.push(o);
						}
					});
				} else {
					if (!dataFoundInList(parsedOrgData, org, 'id')) {
						parsedOrgData.push(org);
					}
				}
			});
			return parsedOrgData;
		}

		function dataFoundInList(dataList, data, property) {
			angular.forEach(dataList, function(dataItem) {
				if (data[property] === dataItem[property]) {
					return true;
				}
			});
			return false;
		}

		function formatData(data) {
			var d = [],
				type = dataType.unknown;
			angular.forEach(data, function(dataItem) {
				type = getDataType(dataItem);
				dataItem.type = type;
				if (type === dataType.organization) {
					dataItem.displayText = dataItem.name;
				} else if (type === dataType.student) {
					dataItem.displayText = dataItem.username;
				} else if (type === dataType.group) {
					dataItem.displayText = dataItem.name;
				} else if (type === dataType.user) {
					dataItem.displayText = dataItem.username;
				}
				d.push(dataItem);
			});
			return d;
		}

		function getDataType(data) {
			var type = dataType.unknown;
			if (angular.isDefined(data.username) && angular.isDefined(data.password)) {
				type = dataType.student;
			} else if (angular.isDefined(data.organizationType)) {
				type = dataType.organization;
			} else if (angular.isDefined(data.username) && angular.isDefined(data.userType)) {
				type = dataType.user;
			} else if (angular.isDefined(data.groupType)) {
				type = dataType.group;
			}
			return type;
		}

		function setupQueue(data) {
			var queue = [];
			angular.forEach(data.items, function(dataItem) {
				if (dataItem.type === dataType.organization) {
					queue.push(api.organization.get(dataItem.id));
				} else if (dataItem.type === dataType.student) {
					queue.push(api.student.get(dataItem.id));
				} else if (dataItem.type === dataType.group) {
					queue.push(api.group.get(dataItem.id));
				} else if (dataItem.type === dataType.user) {
					queue.push(api.user.get(dataItem.id));
				}
			});
			return queue;
		}

		api = {
			organization: {
				get: function(orgId) {
					return Organization.get(orgId);
				}
			},
			student: {
				get: function(studentId) {
					return Student.get(studentId);
				},
				organizations: function(studentId) {
					return Student.organizations(studentId);
				}
			},
			group: {
				get: function(classId) {
					return Class.get(classId);
				}
			},
			user: {
				get: function(userId) {
					return User.get(userId);
				}
			}
		};

		function setupParentOrgsQueue(data) {
			var queue = [];
			angular.forEach(data, function(item) {
				if (item.type === dataType.organization) {
					queue.push(api.organization.get(item.parentId));
				} else if (item.type === dataType.student) {
					queue.push(api.student.organizations(item.id));
				} else if (item.type === dataType.group) {
					queue.push(api.organization.get(item.organizationId));
				} else if (item.type === dataType.user) {
					var deferred = $q.defer();
					deferred.resolve(item.organizations);
					queue.push(deferred.promise);
				}
			});
			return queue;
		}

		searchPaginator.params = {
			sortBy: 'displayText',
			search: ''
		};

		// TODO:
		//$scope.paginator_search.doInitialLoad(); //this needs to tweaked to be used with global search
		$scope.paginator_search.enabled = true;
		$scope.paginator_search.params.desc = false;

		function stateChanged(event, toState, toParams, fromState, fromParams) {
			searchPaginator.tokenBased = true;
			searchPaginator.resetContintuationTokenList();
			if (toParams.searchTerm === '') {
				model.goToLastSate();
			} else {
				model.state = toState.name;
				if (toState.name.indexOf('manager.search') !== -1) {
					$scope.paginator_search.params.search = toParams.searchTerm;
					//clear out the token if search term has changed
					if (toParams.searchTerm !== fromParams.searchTerm) {
						$scope.paginator_search.continuationToken = undefined;
					}
					$scope.paginator_search.refreshAndRewind();
				}
			}

			if (fromState.name.indexOf('search') === -1 && fromState.name !== 'manager.studentcard.student') {
				SearchStateHistory.set({ fromState: fromState, fromParams: fromParams });
			}
		}

		function goToLastSate() {
			var state = SearchStateHistory.get();
			$state.go(state.fromState.name, state.fromParams);
		}

		$scope.$on('$stateChangeSuccess', stateChanged);

		$scope.$on('hide.global.search', function() {
			if ($state.current.name.indexOf('search') !== -1) {
				model.goToLastSate();
			}
		});
	}
})();
