const { AssessmentProviders } = require('@imaginelearning/web-services');
const { HIDE_GROWTH_ANNOUNCEMENT, GROWTH_IDENTIFIER } = require('@imaginelearning/web-ui-reporting');

(function() {
	angular
		.module('dashboard.user', [
			'manager.modal.factory',
			'date.range.drop.down',
			'ui.notifications',
			'ui.loader',
			'ui.router',
			'api.reporting',
			'portal.service',
			'api.application.user',
			'api.manager',
			'manager.list',
			'paged.list'
		])
		.config(config)
		.controller('UserDashboardController', controller);

	config.$inject = ['$stateProvider'];
	function config($stateProvider) {
		$stateProvider.state('dashboard.user', {
			url: '/user/:id',
			controller: controller,
			controllerAs: 'model',
			// abstract: true,
			templateUrl: 'dashboards/user/user.html',
			data: {
				bodyClass: 'dashboard',
				contentClass: 'dashboard user'
			}
		});
	}

	controller.$inject = [
		'$rootScope',
		'$scope',
		'$q',
		'$state',
		'PagedList',
		'NotificationFactory',
		'ApplicationUserService',
		'UserRole',
		'managerAPIHelpers',
		'User',
		'ManagerModalFactory',
		'Portal',
		'PendoService',
		'ReactDependenciesService',
		'AuthenticationServiceEvents',
		'AssessmentProvider',
		'DateRangeService'
	];

	function controller(
		$rootScope,
		$scope,
		$q,
		$state,
		PagedList,
		NotificationFactory,
		ApplicationUserService,
		UserRole,
		managerAPIHelpers,
		User,
		ManagerModalFactory,
		Portal,
		PendoService,
		ReactDependenciesService,
		AuthenticationServiceEvents,
		AssessmentProvider,
		DateRangeService
	) {
		var model = this;
		model.application = Portal;
		model.busy = false;
		model.initialized = false;

		model.user = {};
		model.self = false;
		model.announcementId = null;
		model.homePageProps = {};
		model.defaultAssessmentProvider = null;

		model.stateChangeSuccess = stateChangeSuccess;

		$scope.$on('$stateChangeSuccess', stateChangeSuccess);

		function setHomePageProps(id) {
			model.homePageProps = {
				config: ReactDependenciesService.apiConfig(),
				userId: id,
				userRole: ApplicationUserService.getUser().userRole,
				dateRange: DateRangeService.last4Weeks(),
				resourcesTitle: 'Highlighted resources',
				announcementId: this.announcementId,
				onGroupClick: id => $state.go('dashboard.group.overview', { id }, { notify: true }),
				onOrganizationClick: id => $state.go('dashboard.organization.overview', { id }, { notify: true })
			};
		}

		function refreshModel(id) {
			if (model.busy || model.initialized) {
				return;
			}

			this.announcementId =
				model.defaultAssessmentProvider !== AssessmentProviders.ILE || !$rootScope.growthReportApi
					? HIDE_GROWTH_ANNOUNCEMENT
					: 'random';
			// Listen for if the feature flag does eventually load
			$rootScope.$on(AuthenticationServiceEvents.growthReportRolloverLoaded, () => {
				this.announcementId =
					model.defaultAssessmentProvider !== AssessmentProviders.ILE || !$rootScope.growthReportApi
						? HIDE_GROWTH_ANNOUNCEMENT
						: 'random';
				setHomePageProps(id);
			});

			setHomePageProps(id);

			model.busy = false;
			model.initialized = true;
		}

		function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
			var currentUser = ApplicationUserService.getUser();
			var id = toParams && toParams.id ? toParams.id : currentUser.id;
			AssessmentProvider.getForOrgAndChildren(currentUser.organizationIds[0]).then(provider => {
				model.defaultAssessmentProvider = provider.defaultAssessmentProvider;
				refreshModel(id);
			});
			$state.go('dashboard.user', { id: id }, { notify: true });
		}
	}
})();
