(function() {
	angular.module('portal.manager.manual.placement.factory', ['ui.modal.custom']).factory('ManagerManualPlacementFactory', factory);

	factory.$inject = ['CustomModal'];

	function factory(CustomModal) {
		let createFactory = {};
		createFactory.create = create;

		function create(student, callback) {
			let templateUrl = 'management/modals/placement/manual.placement.tpl.html';
			CustomModal.show(templateUrl, { student: student, callback: callback });
		}

		return createFactory;
	}
})();
