import { faCloudDownload, faGear, faPrint } from '@fortawesome/pro-solid-svg-icons';
const {
	testWindowArrayToParam,
	testWindowParamToArray,
	ConfigILE,
	GrowthApi,
	GrowthReportWithProvider
} = require('@imaginelearning/web-reporting');
import { organizationService } from '@imaginelearning/web-services';
angular
	.module('dashboard.growth', [, 'react.components', 'react.dependencies'])
	.config(config)
	.controller('GrowthReportController', controller);

config.$inject = ['$stateProvider'];
function config($stateProvider) {
	$stateProvider.state('dashboard.growth', {
		url: '/growth?districtId&schoolId&groupId&studentId&gradeLevel&test&testWindows',
		controller: controller,
		controllerAs: 'model',
		templateUrl: 'dashboards/growth/growth.html',
		data: {
			bodyClass: 'growth-report',
			contentClass: ''
		}
	});
}

controller.$inject = [
	'$rootScope',
	'$scope',
	'$state',
	'NotificationFactory',
	'Organization',
	'PendoService',
	'ReactDependenciesService',
	'ApplicationUserService',
	'AuthenticationServiceEvents'
];

function controller(
	$rootScope,
	$scope,
	$state,
	NotificationFactory,
	Organization,
	PendoService,
	ReactDependenciesService,
	ApplicationUserService,
	AuthenticationServiceEvents
) {
	var model = this;

	// props
	model.printProps = undefined;
	model.editSchoolYearModalProps = undefined;
	model.editUsageModalProps = undefined;
	model.editRestrictAccessModalProps = undefined;
	model.districtStudentTestSettings = undefined;
	model.schoolStudentTestSettings = undefined;
	model.groupPrintIconProps = { icon: faPrint };
	model.studentsPrintModalToggleProps = undefined;
	model.settingsProps = undefined;
	model.growthReportProps = undefined;
	model.reportLevel = 'organization'; //"organization", "group", "student"
	model.reportLevelId = null;
	model.dateLastUpdated = null;
	model.user = null;
	model.organization = {};

	// methods
	model.refreshModel = refreshModel;
	model.updateQueryParams = updateQueryParams;
	model.stateChangeSuccess = stateChangeSuccess;
	model.setDateLastUpdated = setDateLastUpdated;
	model.setOrg = setOrg;
	model.userData = _.pick(ApplicationUserService.getUser(), ['id', 'username', 'userRole']);
	$scope.$on('$stateChangeSuccess', model.stateChangeSuccess);

	model.districts = [];

	function updateQueryParams(filters) {
		const hierarchicalFilters = { ...filters?.hierarchicalFilters };
		const test = filters?.test;
		const testWindows = filters?.testWindows;
		const orgId =
			filters?.hierarchicalFilters?.schoolId ??
			(filters?.hierarchicalFilters?.districtId && model.user?.organization?.organizationType === 'District'
				? filters?.hierarchicalFilters?.districtId
				: null);

		if (orgId) {
			model.setOrg(orgId);
		}
		// If we change query params/filters, we will need to recalculate dateLastUpdated
		model.dateLastUpdated = null;
		if (filters?.hierarchicalFilters?.studentId) {
			model.reportLevel = 'student';
			model.reportLevelId = filters?.hierarchicalFilters?.studentId;
		} else {
			model.reportLevel = filters?.hierarchicalFilters?.groupId ? 'group' : 'organization';
			model.reportLevelId =
				filters?.hierarchicalFilters?.groupId || filters?.hierarchicalFilters?.schoolId || filters?.hierarchicalFilters?.districtId;
		}

		$state.go(
			$state.current.name,
			{
				...hierarchicalFilters,
				test,
				testWindows: testWindowArrayToParam(testWindows)
			},
			{ notify: false }
		);
	}

	function setReportProps($state, testWindows) {
		model.growthReportProps = {
			reportConfig: ConfigILE,
			growthApi: $rootScope.growthReportApi,
			config: ReactDependenciesService.apiConfig(),
			filters: {
				hierarchicalFilters: {
					districtId: $state?.params?.districtId,
					schoolId: $state?.params?.schoolId,
					groupId: $state?.params?.groupId,
					studentId: $state?.params?.studentId,
					gradeLevel: $state?.params?.gradeLevel
				},
				test: $state?.params?.test,
				testWindows
			},
			telemetryService: PendoService.pendoService,
			onApplyFilters: model.updateQueryParams,
			onClearFilters: model.updateQueryParams,
			setDateLastUpdated: model.setDateLastUpdated
		};
	}

	function setOrg(id) {
		const _organizationService = organizationService(ReactDependenciesService.apiConfig());
		var params = {
			additionalFields: ['parentName']
		};
		return _organizationService
			.get(id, params)
			.toPromise()
			.then(function(results) {
				model.organization = results;
				if (model.organization.organizationType !== 'Other') {
					model.editUsageModalProps = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: id,
						parentName: model.organization.parentName,
						onManagementModalOpen,
						onManagementModalClose
					};

					model.editSchoolYearModalProps = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: id,
						success: success,
						error: error,
						onManagementModalOpen,
						onManagementModalClose
					};
					model.editRestrictAccessModalProps = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: id,
						success: success,
						error: error,
						onManagementModalOpen,
						onManagementModalClose
					};
					model.districtStudentTestSettings = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: id,
						onManagementModalOpen,
						onManagementModalClose
					};
					model.schoolStudentTestSettings = {
						config: ReactDependenciesService.apiConfig(),
						organizationId: id,
						userRole: model.userData.userRole,
						onManagementModalOpen,
						onManagementModalClose
					};
				}
			})
			.catch(function(error) {
				model.error = NotificationFactory.error(error);
			});
	}

	function success() {
		NotificationFactory.success({
			heading: $filter('translate')('forms_common.messages.updatedHeader'),
			content: `${model.organization && model.organization.name ? model.organization.name : ''} ${$filter('translate')(
				'forms_common.messages.updatedMessage'
			)}`,
			closeAfter: 5
		});
	}

	function error() {
		NotificationFactory.error(error);
	}

	// PENDO LOCATION_API: remove when using routes
	function onManagementModalOpen(modalType) {
		PendoService.pendoService.updateLocationWithQueryParam(modalType ?? 'managementModal');
	}
	function onManagementModalClose() {
		PendoService.pendoService.revertToBrowserUrl();
	}

	function refreshModel(id) {
		let testWindows;
		if ($state?.params?.testWindows) {
			testWindows = testWindowParamToArray($state?.params?.testWindows);
		}
		model.settingsProps = {
			icon: faGear,
			onClick: () => console.log('Settings clicked')
		};
		if ($state?.params?.studentId) {
			model.reportLevel = 'student';
			model.reportLevelId = $state?.params?.studentId;
		} else {
			model.reportLevel = $state?.params?.groupId ? 'group' : 'organization';
			model.reportLevelId = $state?.params?.groupId || $state?.params?.schoolId || $state?.params?.districtId;
		}

		$rootScope.growthReportApi
			? setReportProps($state, testWindows)
			: $rootScope.$on(AuthenticationServiceEvents.growthReportRolloverLoaded, () => {
					setReportProps($state, testWindows);
			  });

		if (id) {
			return setOrg(id);
		}
	}

	function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
		if (toState.name === 'dashboard.growth') {
			model.refreshModel(toParams.schoolId || toParams.districtId);
		}
	}

	function setDateLastUpdated(dateLastUpdated) {
		if (dateLastUpdated) {
			const options = {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				hour12: true
			};
			const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateLastUpdated));
			const [datePart, timePart] = formattedDate.split(' at ');
			model.dateLastUpdated = `${datePart}, ${timePart}`;
		}
	}

	$rootScope.$watch(
		function() {
			return ApplicationUserService.getUser();
		},
		function(user) {
			model.user = user;
		}
	);
}
