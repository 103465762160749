(function() {
	angular.module('ildashboard.audit.history').controller('AuditHistoryController', controller);

	controller.$inject = [
		'$scope',
		'$q',
		'$filter',
		'NotificationFactory',
		'Organization',
		'Class',
		'Student',
		'User',
		'AuditHistory',
		'PagedList',
		'schoolYear',
		'CurrentDate'
	];

	function controller(
		$scope,
		$q,
		$filter,
		NotificationFactory,
		Organization,
		Class,
		Student,
		User,
		AuditHistory,
		PagedList,
		schoolYear,
		CurrentDate
	) {
		var ctrl = this;
		ctrl.id = $scope.$id;
		ctrl.initialized = false;
		ctrl.busy = false;
		ctrl.error = false;

		ctrl.searchActive = false;
		ctrl.searchTerm = undefined;
		ctrl.entity = undefined;
		ctrl.record = undefined;
		ctrl.entityList = undefined;
		ctrl.historyList = undefined;
		ctrl.historyItems = undefined;

		ctrl.sourceTypes = [
			{ name: 'Students', value: 'student' },
			{ name: 'Users', value: 'user' },
			{ name: 'Groups', value: 'group' },
			{ name: 'Organizations', value: 'organization' }
		];
		ctrl.sourceType = ctrl.sourceTypes[0].value;

		ctrl.searchTypes = [
			{ name: 'Deleted', value: 'deleted' },
			{ name: 'Existing', value: 'existing' }
		];
		ctrl.searchType = ctrl.searchTypes[0].value;

		ctrl.entityList = new PagedList(Student.pendingarchive);

		// methods
		ctrl.refreshModel = refreshModel;
		ctrl.reconcileOrganizations = reconcileOrganizations;

		function reconcileOrganizations(item) {
			var orgs = _.map(item.organizations, function(org) {
				return org.name && org.name.length ? org.name : 'Deleted Organization';
			});
			return orgs.join(', ');
		}

		function refreshModel() {
			ctrl.entity = undefined;
			ctrl.record = undefined;
			ctrl.searchActive = false;
			if (ctrl.entityList.error) {
				NotificationFactory.close(ctrl.entityList.error);
				ctrl.entityList.error = ctrl.listError = false;
			}

			switch (ctrl.sourceType) {
				case 'organization':
					ctrl.searchType = 'existing';
					ctrl.entityList = new PagedList(ctrl.searchType === 'existing' ? Organization.query : Organization.pendingarchive);
					ctrl.entityList.params.sortby = 'name';
					if (ctrl.searchType === 'existing') {
						ctrl.searchPlaceholder = 'Search Organizations';
						ctrl.entityList.params.search = ctrl.searchTerm && ctrl.searchTerm.length ? ctrl.searchTerm : undefined;
						ctrl.entityList.params.additionalFields = ['parentName'];
					}
					break;

				case 'group':
					ctrl.searchType = 'existing';
					ctrl.entityList = new PagedList(ctrl.searchType === 'existing' ? Class.query : Class.pendingarchive);
					ctrl.entityList.params.sortby = 'name';
					if (ctrl.searchType === 'existing') {
						ctrl.searchPlaceholder = 'Search Groups';
						ctrl.entityList.params.search = ctrl.searchTerm && ctrl.searchTerm.length ? ctrl.searchTerm : undefined;
						ctrl.entityList.params.additionalFields = ['parentName'];
					}
					break;

				case 'user':
					ctrl.searchType = 'existing';
					ctrl.entityList = new PagedList(ctrl.searchType === 'existing' ? User.query : User.pendingarchive);
					ctrl.entityList.params.sortby = 'userName';
					if (ctrl.searchType === 'existing') {
						ctrl.searchPlaceholder = 'Search Users';
						ctrl.entityList.params.search = ctrl.searchTerm && ctrl.searchTerm.length ? ctrl.searchTerm : undefined;
						ctrl.entityList.params.additionalFields = ['organizations'];
					}
					break;

				case 'student':
					ctrl.entityList = new PagedList(ctrl.searchType === 'existing' ? Student.search : Student.pendingarchive);
					if (ctrl.searchType === 'existing') {
						ctrl.entityList.params.sortby = 'userName';
						ctrl.searchPlaceholder = 'Search Students';
						ctrl.entityList.params.search = ctrl.searchTerm && ctrl.searchTerm.length ? ctrl.searchTerm : undefined;
						ctrl.entityList.params.additionalFields = ['organizations'];
					} else {
						ctrl.entityList.params.sortby = 'Value.Username';
						ctrl.searchPlaceholder = 'Search Students by Username';
						ctrl.entityList.params.userName = ctrl.searchTerm && ctrl.searchTerm.length ? ctrl.searchTerm : undefined;
					}
					break;
			}

			if (ctrl.entityList) {
				ctrl.entityList.showPerPage = false;
				ctrl.entityList.load();
				ctrl.searchActive = ctrl.searchTerm && ctrl.searchTerm.length ? true : false;
				ctrl.fiterText = ctrl.searchTerm && ctrl.searchTerm.length ? angular.copy(ctrl.searchTerm) : undefined;
			}
		}

		refreshModel();

		$scope.$watch(
			function() {
				return ctrl.entity;
			},
			function(value, lastValue) {
				if (value && value.id) {
					ctrl.busy = true;
					AuditHistory.query({ id: value.id })
						.then(function(result) {
							ctrl.historyItems = result.items || [];
							ctrl.historyList = new PagedList(ctrl.historyItems);
							ctrl.historyList.showPerPage = false;
							ctrl.historyList.perPage = 20;
							ctrl.historyList.load();
						})
						.catch(function(error) {
							ctrl.error = NotificationFactory.error(error);
						})
						.finally(function() {
							ctrl.busy = false;
							ctrl.error = false;
						});
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return ctrl.entityList.busy;
			},
			function(value, lastValue) {
				if (value !== lastValue) {
					if (value === true) {
						ctrl.entity = undefined;
						ctrl.record = undefined;
					} else {
						ctrl.entity =
							ctrl.entityList && ctrl.entityList.items && ctrl.entityList.items.length ? ctrl.entityList.items[0] : undefined;
					}
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return ctrl.entityList.error;
			},
			function(value, lastValue) {
				if (value !== lastValue) {
					ctrl.listError = value;
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return ctrl.historyList !== undefined ? ctrl.historyList.items : false;
			},
			function(value, lastValue) {
				if (value && value !== lastValue) {
					ctrl.record = value && value.length ? value[0] : undefined;
				}
			},
			true
		);
	}
})();
