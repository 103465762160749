(function() {
	angular.module('portal.manager.utils.import').service('IlImport', service);

	service.$inject = [
		'$q',
		'$state',
		'$timeout',
		'uuid',
		'Student',
		'DemographicsSettings',
		'NotificationFactory',
		'ManagerBulkOperations'
	];

	function service($q, $state, $timeout, uuid, Student, DemographicsSettings, NotificationFactory, ManagerBulkOperations) {
		var _csv = {},
			_validator = {},
			validationStatus = {},
			report = {},
			cache = {},
			bulkStudentsPatch = [],
			bulkStudentsCreate = [],
			service = this;

		service.validate = validate;
		service.import = startImport;
		service.reset = reset;

		function reset() {
			cache = {};
			report = {
				targetOrganization: {},
				createCount: 0,
				modifyCount: 0,
				outOfReachCount: 0,
				errors: [],
				warnings: [],
				existingStudents: [],
				passedInStudents: [],
				recordsToProcessCount: 0
			};
			bulkStudentsPatch = [];
			bulkStudentsCreate = [];
		}

		function startImport(progressCallback, successCallback, errorCallback, partialSuccessCallback) {
			bulkStudentsPatch = [];
			bulkStudentsCreate = [];
			_buildImportData(progressCallback);
			_importData(progressCallback, successCallback, errorCallback, partialSuccessCallback);
		}

		function validate(csvData, sourceOrganization, successCallback, errorCallback) {
			initialize()
				.then(function() {
					_doValidate(csvData, sourceOrganization, function(report) {
						if (report.errors.length) {
							errorCallback(report);
						} else {
							successCallback(report);
						}
					});
				})
				.catch(function(response) {
					NotificationFactory.error(response);
				});
		}

		function initialize() {
			return DemographicsSettings.get($state.params.id)
				.then(function(settings) {
					bulkStudentsPatch = [];
					bulkStudentsCreate = [];
					validationStatus = {
						recordCount: 0,
						recordProcessed: 0
					};
					cache = {};
					report = {
						createCount: 0,
						errors: [],
						existingStudents: [],
						modifyCount: 0,
						outOfReachCount: 0,
						passedInStudents: [],
						recordsToProcessCount: 0,
						targetOrganization: {},
						warnings: []
					};

					_validator.types.demographics = {
						name: 'demographics',
						values: [],
						rawValues: {},
						translation: {}
					};
					if (settings.allowedDemographics && settings.allowedDemographics.length) {
						_.forEach(settings.allowedDemographics, function(demo) {
							var demoUpper = demo.toUpperCase().trim();
							_validator.types.demographics.values.push(demoUpper);
							_validator.types.demographics.rawValues[demoUpper] = demo;
						});
					}
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		_validator.checkTypes = {
			required: 'Required Check',
			length: 'Length Check',
			range: 'Range Check',
			unique: 'Unique Check',
			dataType: 'Data Type Check',
			columns: 'Column Check',
			data: 'Data Check'
		};

		_validator.dataMapper = {
			Username: 'username'
		};

		_csv.columns = {
			firstName: 'FirstName',
			lastName: 'LastName',
			studentId: 'Student ID',
			username: 'Username',
			password: 'Password',
			gradeLevel: 'GradeLevel',
			sessionTime: 'Session Time',
			firstLanguage: 'FirstLanguage',
			demographics: 'Demographics'
		};

		_validator.types = {
			gradeLevel: {
				name: 'gradeLevel',
				values: ['pk', 'k', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'other'],
				translation: {
					pk: 'PreK',
					k: 'Kindergarten',
					'1': 'First',
					'2': 'Second',
					'3': 'Third',
					'4': 'Fourth',
					'5': 'Fifth',
					'6': 'Sixth',
					'7': 'Seventh',
					'8': 'Eighth',
					'9': 'Ninth',
					'10': 'Tenth',
					'11': 'Eleventh',
					'12': 'Twelfth'
				}
			},
			firstLanguage: {
				name: 'firstLanguage',
				values: ['eng', 'jpn', 'esp', 'chs', 'kor', 'ptb', 'fra', 'hat', 'msa', 'mah', 'vit', 'rus', 'tgl', 'yue', 'hmn', 'som'],
				translation: {
					eng: 'English',
					jpn: 'Japanese',
					esp: 'Spanish',
					chs: 'Mandarin',
					kor: 'Korean',
					ptb: 'PortugueseBrazil',
					fra: 'French',
					hat: 'HaitianCreole',
					msa: 'Arabic',
					mah: 'Marshallese',
					vit: 'Vietnamese',
					rus: 'Russian',
					tgl: 'Tagalog',
					yue: 'Cantonese',
					hmn: 'Hmong',
					som: 'Somali'
				}
			}
		};

		_validator.requiredCheck = function(record, property) {
			var blankCheck = angular.isDefined(record[property]) && record[property] !== '',
				row = validationStatus.recordProcessed + 2,
				checkType = _validator.checkTypes.required,
				errorMessage;

			if (!blankCheck) {
				errorMessage = 'Column ' + property + ' value is blank. This property is required';
				_logError(record, row, checkType, property, errorMessage);
			}
		};

		_validator.lengthCheck = function(record, property, minLength, maxLength) {
			var value = record[property],
				lengthCheck = record[property].length >= minLength && record[property].length <= maxLength,
				row = validationStatus.recordProcessed + 2,
				checkType = _validator.checkTypes.length,
				errorMessage;
			if (!lengthCheck) {
				errorMessage =
					property + ' value, "' + value + '", is supposed to be between ' + minLength + ' to ' + maxLength + ' characters long';
				_logError(record, row, checkType, property, errorMessage);
			}
		};

		_validator.rangeCheck = function(record, property, minValue, maxValue) {
			var value = record[property],
				rangeCheck = value >= minValue && value <= maxValue,
				row = validationStatus.recordProcessed + 2,
				checkType = _validator.checkTypes.range,
				errorMessage;
			if (!rangeCheck) {
				errorMessage = property + ' value, "' + value + '", is supposed to be between ' + minValue + ' to ' + maxValue;
				_logError(record, row, checkType, property, errorMessage);
			}
		};

		_validator.uniqueCheck = function(record, property, csvDataSet) {
			var errorMessage,
				row = validationStatus.recordProcessed + 2,
				checkType = _validator.checkTypes.unique,
				csvDataSetMatchFound = 0;

			_.forEach(csvDataSet, function(dataItem) {
				if (dataItem[property] === record[property]) {
					csvDataSetMatchFound++;
				}
			});

			if (csvDataSetMatchFound > 1) {
				errorMessage = 'duplicate of, "' + record[property] + '", found in csv file';
				_logError(record, row, checkType, property, errorMessage);
			}
		};

		_validator.dataTypeCheck = function(record, property, dataType) {
			var value = record[property],
				invalidValueCheck,
				errorMessage,
				checkType = _validator.checkTypes.dataType,
				row = validationStatus.recordProcessed + 2;

			if (dataType === _validator.types.gradeLevel.name) {
				invalidValueCheck = _validator.types.gradeLevel.values.indexOf(value.toLowerCase().trim()) === -1;
				if (invalidValueCheck) {
					errorMessage =
						property +
						' value, "' +
						value +
						'", not valid, value must be one of "' +
						_validator.types.gradeLevel.values.toString() +
						'"';
					_logError(record, row, checkType, property, errorMessage);
				} else {
					record[property] = value.toLowerCase().trim();
				}
			}
			if (dataType === _validator.types.firstLanguage.name) {
				invalidValueCheck = _validator.types.firstLanguage.values.indexOf(value.toLowerCase().trim()) === -1;
				if (invalidValueCheck) {
					errorMessage =
						property +
						' value, "' +
						value +
						'", not valid, value must be one of "' +
						_validator.types.firstLanguage.values.toString() +
						'"';
					_logError(record, row, checkType, property, errorMessage);
				} else {
					record[property] = value.toLowerCase().trim();
				}
			}
			// since demographics column is optional lets validate the value
			if (dataType === _validator.types.demographics.name && value && value.length) {
				var values = value.split(';');
				_.forEach(values, function(val) {
					val = val.toUpperCase().trim();
					if (val !== '' && _validator.types.demographics.values && _validator.types.demographics.values.length > 0) {
						invalidValueCheck = _validator.types.demographics.values.indexOf(val) === -1;
						if (invalidValueCheck) {
							errorMessage =
								property +
								' value, "' +
								val.trim() +
								'", not valid, value must be one of "' +
								_validator.types.demographics.values.toString() +
								'"';
							_logError(record, row, checkType, property, errorMessage);
						}
					}
				});
			}
		};

		function _logError(record, row, checkType, column, message) {
			report.errors.push({
				record: record,
				row: row,
				checkType: checkType,
				column: column,
				message: message
			});
		}

		function _logWarning(record, row, checkType, column, message) {
			report.warnings.push({
				record: record,
				row: row,
				checkType: checkType,
				column: column,
				message: message
			});
		}

		function _csvDataCheck(csvData) {
			var check = true,
				property,
				data = csvData[0];
			if (csvData.length > 0) {
				for (property in _csv.columns) {
					if (
						property !== 'sessionTime' &&
						property !== 'demographics' &&
						property !== 'studentId' &&
						!angular.isDefined(data[_csv.columns[property]])
					) {
						_logError(
							{},
							0,
							_validator.checkTypes.columns,
							_csv.columns[property],
							'Column "' + _csv.columns[property] + '" is missing in csv file'
						);
						check = false;
					}
				}
			} else {
				_logError({}, 0, _validator.checkTypes.data, null, 'There is no data in csv file');
				check = false;
			}
			return check;
		}

		function _doValidate(csvData, sourceOrganization, callback) {
			// validate properties exists
			if (_csvDataCheck(csvData)) {
				validationStatus.recordCount = csvData.length;
				report.passedInStudents = csvData;
				report.targetOrganization = sourceOrganization;
				_buildReport(csvData, sourceOrganization, callback);
			} else {
				$timeout(function() {
					_doneValidate(callback);
				}, 1000);
			}
		}

		function _fetchStudentRecord(studentId, successCallback, failCallback) {
			Student.get(studentId)
				.then(successCallback)
				.catch(failCallback)
				.finally(_buildReportFinally);
		}

		function _fetchStudentRecordSuccess(response) {
			report.existingStudents.push(response);
		}

		function _fetchStudentRecordFail(error) {
			var studentId = error.data.message.split('(')[1].split(')')[0];
			_.forEach(cache.build.recordsChecked, function(record, index) {
				if (record.id === studentId) {
					var message =
						'It appears record with username ' +
						record.username +
						' exists and you do not have rights to make changes to that record.  Please try a different username';
					_logWarning(null, index + 2, 'API Fetch Student Data', null, message);
					report.outOfReachCount++;
				}
			});
		}

		function _buildReportFinally() {
			var recordsToProcessCount = 0;
			cache.build.tally++;
			if (cache.build.tally >= cache.build.recordsChecked.length) {
				_.forEach(cache.build.csvData, function(record) {
					_validateRecord(record);
					if (report.existingStudents.length > 0) {
						_checkIfRecordExists(record, function(response) {
							if (response.exists) {
								report.modifyCount++;
							} else {
								report.createCount++;
							}
						});
					}
					validationStatus.recordProcessed++;
				});
				if (report.existingStudents.length < 1) {
					report.createCount = cache.build.csvData.length - (report.modifyCount + report.outOfReachCount);
					validationStatus.recordProcessed = cache.build.csvData.length;
				}
				recordsToProcessCount = report.createCount + report.modifyCount - report.outOfReachCount;
				report.recordsToProcessCount = recordsToProcessCount < 0 ? 0 : recordsToProcessCount;

				report.errors = _.sortBy(report.errors, 'row');
				report.warnings = _.sortBy(report.warnings, 'row');
				_doneValidate(cache.build.callback);
			}
		}

		function _buildReport(csvData, sourceOrganization, callback) {
			cache.build = {};
			cache.build.tally = 0; // track record fetch
			cache.build.csvData = csvData;
			cache.build.callback = callback;
			var data = _.map(csvData, function(record) {
					return record[_csv.columns.username];
				}),
				found = false;
			Student.checkAvailability(sourceOrganization.id, data)
				.then(function(recordsChecked) {
					cache.build.recordsChecked = recordsChecked;
					// start fetching
					_.forEach(recordsChecked, function(record) {
						if (record.id) {
							_fetchStudentRecord(record.id, _fetchStudentRecordSuccess, _fetchStudentRecordFail);
							found = true;
						} else {
							cache.build.tally++;
						}
					});
					if (!found) {
						_buildReportFinally();
					}
				})
				.catch(function(error) {
					NotificationFactory.error(error);
					if (error.message) {
						_logError(null, 0, 'API Fetch Data', null, 'Failed to verify student exists because ' + error.message);
					}
					callback(report);
				});
		}

		function _doneValidate(callback) {
			if (validationStatus.recordCount === validationStatus.recordProcessed) {
				callback(report);
			}
		}

		function _validateRecord(record) {
			var currentDataSet = report.passedInStudents;

			// set defaults
			if (!record[_csv.columns.sessionTime]) {
				record[_csv.columns.sessionTime] = 20;
			}

			_validator.requiredCheck(record, _csv.columns.firstName);
			_validator.requiredCheck(record, _csv.columns.lastName);
			_validator.requiredCheck(record, _csv.columns.username);
			_validator.requiredCheck(record, _csv.columns.password);
			_validator.requiredCheck(record, _csv.columns.gradeLevel);
			_validator.requiredCheck(record, _csv.columns.firstLanguage);

			if (record[_csv.columns.firstName]) {
				_validator.lengthCheck(record, _csv.columns.firstName, 1, 64);
			}
			if (record[_csv.columns.lastName]) {
				_validator.lengthCheck(record, _csv.columns.lastName, 1, 64);
			}
			if (record[_csv.columns.username]) {
				_validator.lengthCheck(record, _csv.columns.username, 4, 128);
			}
			if (record[_csv.columns.password]) {
				_validator.lengthCheck(record, _csv.columns.password, 4, 256);
			}

			_validator.rangeCheck(record, _csv.columns.sessionTime, 10, 99);

			_validator.uniqueCheck(record, _csv.columns.username, currentDataSet);

			if (record[_csv.columns.studentId]) {
				_validator.lengthCheck(record, _csv.columns.studentId, 4, 64);
				_validator.uniqueCheck(record, _csv.columns.studentId, currentDataSet);
			}

			if (record[_csv.columns.gradeLevel]) {
				_validator.dataTypeCheck(record, _csv.columns.gradeLevel, _validator.types.gradeLevel.name);
			}
			if (record[_csv.columns.firstLanguage]) {
				_validator.dataTypeCheck(record, _csv.columns.firstLanguage, _validator.types.firstLanguage.name);
			}
			if (record[_csv.columns.demographics]) {
				_validator.dataTypeCheck(record, _csv.columns.demographics, _validator.types.demographics.name);
			}
		}

		function _checkIfRecordExists(record, callback) {
			var exists = false,
				existingRecord = {};
			_.forEach(report.existingStudents, function(student) {
				if (
					(!exists && record[_csv.columns.username] === student.username && !student.available) ||
					(angular.isDefined(student.tag) && record[_csv.columns.studentId] === student.tag)
				) {
					exists = true;
					existingRecord = student;
				}
			});

			if (angular.isDefined(callback) && typeof callback === 'function') {
				callback({
					exists: exists,
					existingRecord: existingRecord
				});
			}
		}

		function _buildImportData(progressCallback) {
			progressCallback({
				status: 'Building data set',
				message: 'Init import data build ... '
			});
			var buildCount = 1;
			_.forEach(report.passedInStudents, function(student) {
				_checkIfRecordExists(student, function(response) {
					progressCallback({
						status: 'Building data set',
						message:
							'(' +
							buildCount +
							'/' +
							report.passedInStudents.length +
							') Building import data for - ' +
							student[_csv.columns.firstName] +
							' ' +
							student[_csv.columns.lastName]
					});
					var item = {};
					if (response.exists) {
						var orgIds = response.existingRecord.organizationIds;
						if (orgIds.indexOf(report.targetOrganization.id) === -1) {
							orgIds.push(report.targetOrganization.id);
						}
						item = {
							firstName: student[_csv.columns.firstName],
							lastName: student[_csv.columns.lastName],
							gradeLevel: _validator.types.gradeLevel.translation[student[_csv.columns.gradeLevel]],
							password: student[_csv.columns.password],
							username: response.existingRecord.username,
							tag: student[_csv.columns.studentId],
							Id: response.existingRecord.id, // fyi -> needs to be `Id` for patch
							sessionTime: parseInt(student[_csv.columns.sessionTime]),
							firstLanguage: _validator.types.firstLanguage.translation[student[_csv.columns.firstLanguage]],
							writtenLanguage: response.existingRecord.writtenLanguage,
							organizationIds: orgIds,
							groupIds: response.existingRecord.groupIds
						};
						item.demographics = student[_csv.columns.demographics]
							? _trimCollection(student[_csv.columns.demographics].split(';'))
							: [];
						bulkStudentsPatch.push(item);
					} else {
						item = {
							firstName: student[_csv.columns.firstName],
							lastName: student[_csv.columns.lastName],
							gradeLevel: _validator.types.gradeLevel.translation[student[_csv.columns.gradeLevel]],
							password: student[_csv.columns.password],
							username: student[_csv.columns.username],
							tag: student[_csv.columns.studentId],
							Id: _guid(),
							sessionTime: parseInt(student[_csv.columns.sessionTime]),
							firstLanguage: _validator.types.firstLanguage.translation[student[_csv.columns.firstLanguage]],
							writtenLanguage: _validator.types.firstLanguage.translation[student[_csv.columns.firstLanguage]],
							organizationIds: [report.targetOrganization.id],
							groupIds: []
						};
						item.demographics = student[_csv.columns.demographics]
							? _trimCollection(student[_csv.columns.demographics].split(';'))
							: [];
						bulkStudentsCreate.push(item);
					}
					buildCount++;
				});
			});
		}

		function _trimCollection(items) {
			var collection = [];
			if (items && angular.isArray(items)) {
				_.forEach(items, function(item) {
					var i = _validator.types.demographics.rawValues[item.trim().toUpperCase()];
					if (i && i !== null) {
						collection.push(i);
					}
				});
			}
			return collection;
		}

		function _guid() {
			return uuid.v4();
		}

		function _importData(progressCallback, successCallback, errorCallback, partialSuccessCallback) {
			var promises = {
				create: bulkStudentsCreate.length ? ManagerBulkOperations.createOrUpdate.students(bulkStudentsCreate) : undefined,
				update: bulkStudentsPatch.length ? ManagerBulkOperations.patch.students(bulkStudentsPatch) : undefined
			};

			progressCallback({
				status: 'Sending data set',
				message: 'Sending data set via bulk api'
			});

			if (bulkStudentsPatch.length || bulkStudentsCreate.length) {
				$q.all(promises)
					.then(function(response) {
						console.log(['then response', response]);
					})
					.catch(function(error) {
						console.log(['error response', error]);
					})
					.finally(function() {
						var responseData = [],
							progressStatus = {
								partial: false,
								success: false,
								error: false
							};

						if (promises.create && promises.create.$$state && promises.create.$$state.value) {
							responseData.push(promises.create.$$state.value);
						}
						if (promises.update && promises.update.$$state && promises.update.$$state.value) {
							responseData.push(promises.update.$$state.value);
						}
						if (responseData.length) {
							var progressReport = {
								created: 0,
								updated: 0,
								failed: 0
							};

							progressCallback({
								status: 'Done',
								message: 'Api response received'
							});

							_.forEach(responseData, function(resp) {
								var r = resp.data ? resp.data : resp.results ? resp : [];
								if (r.results && r.results.length) {
									progressReport.created += r.studentsCreated;
									progressReport.updated += r.studentsUpdated;

									progressStatus.error = r.status === 'FinishedSomeFailures';
									progressStatus.success = r.status === 'FinishedAllSuccess';
									progressStatus.partial = r.status === 'FinishedAllFailures';

									var oResults = _.chain(r.results)
											.keyBy('id')
											.value(),
										students = _.merge(bulkStudentsPatch, bulkStudentsCreate),
										oStudents = _.chain(students)
											.keyBy('Id')
											.value();

									for (var id in oResults) {
										if (oResults.hasOwnProperty(id)) {
											var item = oResults[id],
												failed = !!item.errorDetails,
												message = !failed
													? 'Student - ' +
													  oStudents[id].firstName +
													  ' ' +
													  oStudents[item.id].lastName +
													  ' was ' +
													  item.status
													: 'Student - ' +
													  oStudents[id].firstName +
													  ' ' +
													  oStudents[item.id].lastName +
													  ' failed because - ' +
													  item.errorDetails;

											if (failed) {
												progressReport.failed++;
											}

											progressCallback({
												status: 'API Response',
												message: message
											});
										}
									}
								}
							});

							progressCallback({
								status: 'Report Summary',
								message: 'students created = ' + progressReport.created
							});

							progressCallback({
								status: 'Report Summary',
								message: 'students updated = ' + progressReport.updated
							});

							progressCallback({
								status: 'Report Summary',
								message: 'students failed = ' + progressReport.failed
							});

							if (progressStatus.partial) {
								partialSuccessCallback(responseData);
							} else if (progressStatus.error) {
								errorCallback(responseData);
							} else if (progressStatus.success) {
								successCallback(responseData);
							}
						}
					});
			}
		}
	}
})();
