(function() {
	angular.module('portal.manager.paginator.directive', []).directive('ilManagerPaginator', directive);

	function directive() {
		return {
			restrict: 'EA',
			template: '<section il-notification-overlay notification-id="paginator.errorOverlayId" ng-include="templateString"></section>',
			replace: true,
			scope: {
				type: '@',
				paginator: '=ilManagerPaginator'
			},
			link: function(scope, element, attrs) {
				scope.templateString = 'management/pagination/paginator.' + scope.type + '.tpl.html';
			}
		};
	}
})();
