(function() {
	angular
		.module('portal.manager', [
			'api.urls',
			'api.application.user',
			'ui.checkbox.indeterminate',
			'angular.filter',
			'ui.router',
			'ui.loader',
			'ui.popup',
			'ui.notifications',
			'ui.modal.common',
			'ui.manager.multiselect',
			'ui.manager.select',
			'ui.directives.backButton',
			'ui.directives.emptyresult',
			'ui.directives.ngEnter',
			'ui.directives.assistModeToggle',
			'utils.cookies',
			'portal.localization',
			'portal.manager.components',
			'portal.manager.navigation',
			'portal.manager.navigation.directive',
			'portal.manager.actions.directive',
			'portal.manager.paginator',
			'portal.manager.paginator.controls',
			'portal.manager.paginator.directive',
			'portal.manager.paginator.clear.directive',
			'portal.manager.create.entity.factory',
			'portal.manager.manual.placement.factory',
			'portal.manager.actions.create.organization',
			'portal.manager.actions.create.group',
			'portal.manager.actions.create.staff',
			'portal.manager.actions.create.student',
			'portal.manager.actions.manual.placement',
			'portal.manager.home',
			'portal.manager.group',
			'portal.manager.organization',
			'portal.manager.staff',
			'portal.manager.student',
			'portal.manager.search',
			'portal.manager.studentcard',
			'growth.common.localization'
		])
		.config(config)
		.controller('ManagerController', controller);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('managerRoot', {
				parent: 'root.layout',
				controller: controller,
				controllerAs: 'model',
				templateUrl: 'management/management.layout.tpl.html',
				abstract: true,
				data: {
					contentClass: 'white',
					bodyClass: 'manager'
				}
			})
			.state('manager', {
				parent: 'managerRoot',
				url: 'manager',
				abstract: true,
				views: {
					'': {
						template: '<div ui-view></div>'
					}
				}
			});

		$urlRouterProvider.when('/manager', [
			'$state',
			function($state) {
				$state.go('home.organizations', {}, { notify: true });
			}
		]);
	}

	controller.$inject = ['$scope', '$state', '$timeout', '$window', 'ApplicationUserService', 'urls', 'UserRole'];

	function controller($scope, $state, $timeout, $window, ApplicationUserService, urls, UserRole) {
		var model = this;
		model.showSearch = false;
		model.showNavigation = true;
		model.searchTerm = '';

		model.toggleSearch = function() {
			model.showSearch = !model.showSearch;
			if (!model.showSearch) {
				$scope.$broadcast('hide.global.search');
			} else {
				$timeout(function() {
					var searchInput = angular.element('#search');
					searchInput.focus();
				}, 0);
			}
		};

		$scope.$watch(
			function() {
				return ApplicationUserService.getUser();
			},
			function(user) {
				if (!!user && user.role === UserRole.parent) {
					// redirect to MPP Login page
					$window.location.replace(urls.mppLoginUrl);
				}
			},
			true
		);

		$scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			var searchMode = $state.current.name.indexOf('search') !== -1;
			model.showNavigation = !searchMode;
			model.searchTerm = searchMode ? model.searchTerm : '';
		});

		model.onChange = function() {
			model.showSearch = !!(model.searchTerm && model.searchTerm.length);
			$state.go('manager.search.term', { searchTerm: model.searchTerm }, { notify: true });
		};
	}
})();
