(function() {
	angular
		.module('dashboard.download', ['ui.router', 'api.reporting'])
		.controller('DownloadController', controller)
		.config(config);

	config.$inject = ['$stateProvider'];

	function config($stateProvider) {
		$stateProvider.state('dashboard.download', {
			url: '/download/?id&type',
			controller: 'DownloadController',
			controllerAs: '$ctrl',
			templateUrl: 'dashboards/download/download.html'
		});
	}

	controller.$inject = ['$state', 'CsvDownloadModal'];

	function controller($state, CsvDownloadModal) {
		var ctrl = this;
		ctrl.init = init;

		function init() {
			CsvDownloadModal.download({
				type: 'csv-export',
				id: $state.params.id,
				displayText: 'Download CSV Export File',
				completedText: 'File downloaded'
			});
		}

		init();
	}
})();
