(function() {
	angular
		.module('portal.manager.actions.create.student', [
			'api.application.user',
			'api.manager',
			'portal.manager.paginator',
			'portal.manager.utils',
			'api.oralLanguage',
			'ui.notifications',
			'ui.modal.common',
			'utils.gradeLevel',
			'utils.localization'
		])
		.controller('CreateStudentController', controller);

	controller.$inject = [
		'$scope',
		'$q',
		'$state',
		'ManagerBulkOperations',
		'Paginator',
		'Student',
		'Organization',
		'User',
		'NotificationFactory',
		'getDisplayText',
		'isEntitySynced',
		'$timeout',
		'$filter',
		'CommonModal',
		'OralLanguage',
		'ApplicationUserService',
		'UserRole',
		'DemographicsSettings',
		'ComponentsDropdownDataTypes',
		'ComponentsDropdownModes',
		'UsernameService',
		'DistrictStudentIdService'
	];

	function controller(
		$scope,
		$q,
		$state,
		ManagerBulkOperations,
		Paginator,
		Student,
		Organization,
		User,
		NotificationFactory,
		getDisplayText,
		isEntitySynced,
		$timeout,
		$filter,
		CommonModal,
		OralLanguage,
		ApplicationUserService,
		UserRole,
		DemographicsSettings,
		ComponentsDropdownDataTypes,
		ComponentsDropdownModes,
		UsernameService,
		DistrictStudentIdService
	) {
		var editModel = this,
			userRootOrganization = ApplicationUserService.getRootOrganization(),
			orgParentIds = [
				angular.isDefined(userRootOrganization) && angular.isDefined(userRootOrganization.id) ? userRootOrganization.id : ''
			],
			paginatorTypes,
			paginatorType,
			searchPaginator;

		editModel.showDemographics = undefined;
		editModel.allowedDemographics = undefined;

		editModel.uiModes = {
			edit: 'edit-existing-student',
			create: 'create-new-student',
			add: 'add-existing-student',
			unknown: 'unknown'
		};

		editModel.enabledOptions = [
			{
				text: $filter('translate')('management.terms.enabled'),
				value: true
			},
			{
				text: $filter('translate')('management.terms.disabled'),
				value: false
			}
		];

		paginatorTypes = {
			search: 'search',
			selected: 'selected'
		};

		editModel.uiMode = editModel.uiModes.unknown;
		editModel.studentSearchStr = '';
		editModel.selectedStudents = [];
		editModel.demographics = [];
		editModel.showSelected = showSelected;
		editModel.error = false;

		editModel.languages = [];
		editModel.isValid = isValid;
		editModel.editComplete = editComplete;
		editModel.updateLexileLastTouchUser = updateLexileLastTouchUser;
		editModel.getEntity = getEntity;
		editModel.saveEntity = saveEntity;
		editModel.updateEntity = updateEntity;
		editModel.save = save;
		editModel.getParent = getParent;
		editModel.printStudentLoginCard = printStudentLoginCard;
		editModel.searchStudent = searchStudent;
		editModel.resetForm = resetForm;
		editModel.validateUsernameInOrganziations = validateUsernameInOrganziations;
		editModel.validateStudentIdInOrganziations = validateStudentIdInOrganziations;

		var clearModel = function() {
			editModel.error = false;
			editModel.busy = false;
			editModel.synced = false;
			editModel.saveAndCreate = false;

			editModel.entity = {};
			editModel.entity.sessionTime = 20;
			editModel.entity.firstLanguage = 'English';

			editModel.organizationIds = [];
			editModel.siteCodes = [];
			editModel.organizations = [];
			editModel.parentOrganizationEditable = ApplicationUserService.getUserRole() !== UserRole.teacher;

			editModel.groupIds = [];
			editModel.groups = [];
			editModel.currentUser = ApplicationUserService.getUser();

			editModel.lexileLastTouchUser = {};
		};
		clearModel();

		editModel.organizationField = {
			parentIds: undefined, // tobe defined in init
			defaultIds: [],
			dataType: ComponentsDropdownDataTypes.organization,
			subType: undefined,
			includeParent: true,
			fieldIsReady: false,
			label: 'Organization',
			mode: ComponentsDropdownModes.multiselect,
			modelIsReady: false,
			disabled: false,
			required: true
		};

		editModel.groupField = {
			parentIds: undefined,
			defaultIds: undefined,
			dataType: ComponentsDropdownDataTypes.group,
			subType: undefined,
			includeParent: false,
			fieldIsReady: false,
			label: 'Group',
			mode: ComponentsDropdownModes.multiselect,
			modelIsReady: false,
			parentFieldIsReady: editModel.organizationField.fieldIsReady,
			disabled: true,
			required: false
		};

		setPaginator(paginatorTypes.search);

		startWatchers();

		function startWatchers() {
			$scope.$watch(
				function() {
					return editModel.organizationField.fieldIsReady;
				},
				_onOrganizationFieldReadyChange,
				true
			);
			$scope.$watchCollection(
				function() {
					return editModel.organizations;
				},
				_onOrganizationsChange,
				true
			);
			$scope.$watchCollection(
				function() {
					return editModel.groups;
				},
				_onGroupsChange,
				true
			);
			$scope.$watch(
				function() {
					return editModel.uiMode;
				},
				_onUiModeChange,
				true
			);
			$scope.$watch(
				function() {
					return searchPaginator.selectedItems;
				},
				_onSelectedItemsChange,
				true
			);
			$scope.$watchCollection('params', _onScopeParamsChange, true);
			$scope.$watch(
				function() {
					return editModel.demographics;
				},
				_demographicChange,
				true
			);
		}

		function _onOrganizationFieldReadyChange(n, o) {
			if (o !== n) {
				if (n === true) {
					editModel.groupField.parentIds = _.map(editModel.organizations, 'id');
					editModel.groupField.disabled = false;
				} else {
					editModel.groupField.parentIds = undefined;
					editModel.groupField.disabled = true;
				}
				editModel.groupField.parentFieldIsReady = n;
			}
		}

		function _onOrganizationsChange(n, o) {
			if (o !== n) {
				editModel.entity.organizationIds = _.map(editModel.organizations, 'id');
				validateUsernameInOrganziations();
				validateStudentIdInOrganziations();
				// PTL-909
				if (editModel.groups && editModel.groups.length) {
					editModel.groups = _.filter(editModel.groups, function(group) {
						return _.indexOf(editModel.entity.organizationIds, group.organizationId) !== -1;
					});
					editModel.groupField.defaultIds = editModel.entity.groupIds = _.map(editModel.groups, 'id');
				}
			}
		}

		function _onGroupsChange(n, o) {
			if (o !== n) {
				editModel.entity.groupIds = _.map(editModel.groups, 'id');
			}
		}

		function _onSelectedItemsChange(n, o) {
			if (n !== o) {
				editModel.selectedStudents =
					searchPaginator.selectedItems > 0 ? _.clone(searchPaginator.selectedItems) : editModel.selectedStudents;
			}
		}

		function _onUiModeChange(n, o) {
			if (o !== n) {
				setFocus();
				if (n === editModel.uiModes.add) {
					editModel.organizationField.defaultIds = [$scope.params.parentId];
					editModel.organizationField.modelIsReady = true;

					editModel.groupField.required = true;
					editModel.groupField.modelIsReady = true;
				} else if (n === editModel.uiModes.create) {
					editModel.groupField.required = ApplicationUserService.getUserRole() === UserRole.teacher;
				} else if (n === editModel.uiModes.edit) {
					editModel.groupField.required = false;
					editModel.groupField.disabled = false;
				}
			}
		}

		function _onScopeParamsChange(n, o) {
			if (n) {
				initialize();
			}
		}

		function _demographicChange(n) {
			if (n) {
				var demographics = [];
				for (var k = 0; k < n.length; ++k) {
					if (n[k].label && n[k].checked) {
						demographics.push(n[k].label);
					}
				}
				editModel.entity.demographics = demographics;
			}
		}

		function searchStudent() {
			if (paginatorType !== paginatorTypes.search) {
				setPaginator(paginatorTypes.search);
			}
			if (editModel.studentSearchStr.length >= 2) {
				$scope.paginator_student_search.params.search = editModel.studentSearchStr;
				$scope.paginator_student_search.params.limit = 10;
				$timeout(function() {
					$scope.paginator_student_search.refreshAndRewind();
				});
			}
		}

		function setPaginator(type) {
			var options;
			paginatorType = type;
			if (type === paginatorTypes.search) {
				options = {
					showPerPage: false
				};
				searchPaginator = undefined;
				searchPaginator = new Paginator(
					$scope,
					'paginator_student_search',
					function() {
						return Student.search(searchPaginator.params);
					},
					undefined,
					options
				);
				searchPaginator.selectedItems = editModel.selectedStudents;
				searchPaginator.perPage = 10;
				searchPaginator.arrayData = false;
				delete window.searchPaginator;
			} else if (type === paginatorTypes.selected) {
				options = {
					dataAlwaysChecked: true,
					showPerPage: false,
					unCheckFunction: function(item) {
						var searchPaginatorIndex = searchPaginator.items.indexOf(item),
							localIndex = editModel.selectedStudents.indexOf(item);
						if (searchPaginatorIndex !== -1) {
							searchPaginator.totalCount--;
							searchPaginator.items.splice(searchPaginatorIndex, 1);
							editModel.selectedStudents.splice(localIndex, 1);
						}
					}
				};
				searchPaginator = undefined;
				searchPaginator = new Paginator(
					$scope,
					'paginator_student_search',
					function() {
						return {
							totalCount: editModel.selectedStudents.length,
							items: _.sortBy(editModel.selectedStudents, ['lastName'])
						};
					},
					undefined,
					options
				);
				searchPaginator.perPage = 10;
				searchPaginator.dataSrcArray = true;
				window.searchPaginator = searchPaginator;
			}
			searchPaginator.params = {
				sortBy: 'lastName.firstName',
				limit: 10
			};
			$scope.paginator_student_search.enabled = true;
		}

		function showSelected() {
			if (editModel.selectedStudents.length) {
				setPaginator(paginatorTypes.selected);
				$timeout(function() {
					$scope.paginator_student_search.refreshAndRewind();
				});
			}
		}

		function setFocus() {
			$timeout(function() {
				var defaultField = angular.element('article[name="organizations"] input');
				defaultField.focus();
			}, 0);
		}

		function editComplete() {
			if ($scope.params.callback) {
				$scope.params.callback();
			}

			if (editModel.saveAndCreate === false) {
				CommonModal.close();
			} else {
				editModel.resetForm();
			}
		}

		function isValid() {
			var valid = false;
			if (editModel.uiMode === editModel.uiModes.add) {
				valid = !!(
					editModel.selectedStudents.length > 0 &&
					editModel.entity &&
					editModel.entity.organizationIds &&
					editModel.entity.organizationIds.length > 0
				);

				if (editModel.groupField.required === true) {
					valid = valid && angular.isDefined(editModel.entity.groupIds) && editModel.entity.groupIds.length > 0;
				}
				return valid;
			}
			if (editModel.uiMode === editModel.uiModes.create || editModel.uiMode === editModel.uiModes.edit) {
				valid = !!(
					editModel.entity &&
					editModel.entity.firstName &&
					editModel.entity.firstName.length &&
					editModel.entity &&
					editModel.entity.lastName &&
					editModel.entity.lastName.length &&
					editModel.entity &&
					editModel.entity.username &&
					editModel.entity.username.length &&
					editModel.entity &&
					editModel.entity.tag &&
					editModel.entity.tag.length &&
					editModel.entity &&
					editModel.entity &&
					editModel.entity.gradeLevel &&
					editModel.entity.gradeLevel.length &&
					editModel.entity &&
					editModel.entity.sessionTime &&
					editModel.entity.sessionTime > 9 &&
					editModel.entity.sessionTime < 121 &&
					editModel.entity &&
					editModel.entity.firstLanguage &&
					editModel.entity.firstLanguage.length &&
					editModel.entity &&
					editModel.entity.organizationIds &&
					editModel.entity.organizationIds.length > 0
				);

				if (editModel.uiMode === editModel.uiModes.create) {
					valid =
						valid &&
						!!(editModel.entity.password && editModel.entity.password.length > 3 && editModel.entity.password.length < 257);
				}

				if (editModel.uiMode === editModel.uiModes.edit && editModel.entity.password?.length > 0) {
					valid = valid && !!(editModel.entity.password.length > 3 && editModel.entity.password.length < 257);
				}

				if (editModel.groupField.required === true) {
					return valid && editModel.entity.groupIds && editModel.entity.groupIds.length > 0;
				} else {
					return valid;
				}
			}
		}

		function initialize() {
			clearModel();

			editModel.organizationField.parentIds = angular.isUndefined($scope.params.parentId) ? orgParentIds : [$scope.params.parentId];

			// don't rely on route, the end result here is to
			// default to the user's root org if none is provided
			// if ($state.current.name === 'home.students') {
			// 	editModel.organizations = [userRootOrganization];
			// }

			OralLanguage.query()
				.then(function(results) {
					editModel.languages = _.sortBy(results, function(result) {
						return result === 'English' ? 0 : result;
					});

					editModel.groupField.required = ApplicationUserService.getUserRole() === UserRole.teacher;

					if ($scope.params.id && $scope.params.id.length) {
						editModel.uiMode = editModel.uiModes.edit;
						editModel.getEntity($scope.params.id);
					} else {
						editModel.uiMode = editModel.uiModes.add;
						if ($scope.params.parentId) {
							editModel.getParent();
							initDemographics($scope.params.parentId);
						} else {
							editModel.organizations = [userRootOrganization];
							editModel.entity.lexileSettings = {
								optIn: false
							};
							initDemographics(userRootOrganization.id);
							setFocus();
						}
					}
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				});
		}

		function initDemographics(orgId) {
			if (editModel.showDemographics === undefined && editModel.allowedDemographics === undefined) {
				DemographicsSettings.get(orgId)
					.then(function(settings) {
						editModel.showDemographics = settings.showDemographics; // show / hide
						editModel.allowedDemographics = settings.allowedDemographics;
						setupDemographics();
					})
					.catch(function(error) {
						NotificationFactory.error(error);
					})
					.finally(function() {
						editModel.busy = false;
					});
			} else {
				setupDemographics();
			}
		}

		function setupDemographics() {
			// init demographics
			var demographics = [];

			// setup checkbox list settings
			if (editModel.allowedDemographics && editModel.showDemographics && editModel.entity) {
				for (var l = 0; l < editModel.allowedDemographics.length; ++l) {
					demographics.push({
						label: editModel.allowedDemographics[l],
						checked: false
					});
				}

				// setup checkbox list values based on entity demographic values
				var entityDemo, demo;
				for (var i = 0; i < editModel.entity.demographics.length; ++i) {
					entityDemo = editModel.entity.demographics[i];
					for (var k = 0; k < demographics.length; ++k) {
						demo = demographics[k];
						if (entityDemo === demo.label) {
							demographics[k].checked = true;
						}
					}
				}
				editModel.demographics = demographics;
			}
		}

		//TFS 47640 - Mismatch between chinese oral and written languages
		function getWrittenLanguage(firstLanguage) {
			return firstLanguage === 'Mandarin'
				? 'ChineseSimplified'
				: firstLanguage === 'Cantonese'
				? 'ChineseTraditional'
				: firstLanguage;
		}

		function updateLexileLastTouchUser() {
			User.get(editModel.entity.lexileSettings.changeUserId)
				.then(function(user) {
					editModel.lexileLastTouchUser = user;
					editModel.lexileStatus = _.clone(editModel.entity.lexileSettings.optIn);
				})
				['catch'](function(error) {
					if (error.status === 404) {
						//forbidden === the user does not have permission to retrieve the last touched user record
					}
					editModel.lexileStatus = _.clone(editModel.entity.lexileSettings.optIn);
					editModel.lexileLastTouchUser = {
						firstName: 'unknown',
						lastName: 'unknown'
					};
				});
		}

		function getEntity(entityId) {
			editModel.busy = true;
			Student.get(entityId, { additionalFields: ['demographics', 'alternateStudentNumber'] })
				.then(function(result) {
					editModel.entity = result;
					editModel.synced = isEntitySynced(editModel.entity);
					editModel.originalUsername = editModel.entity.username;
					editModel.originalStudentId = editModel.entity.tag;

					editModel.organizationField.defaultIds = result.organizationIds;
					editModel.groupField.defaultIds = result.groupIds;

					editModel.organizationField.modelIsReady = true;
					editModel.groupField.modelIsReady = true;

					var changeUserId = _.get(editModel, 'entity.lexileSettings.changeUserId', '');
					if (changeUserId.length) {
						editModel.updateLexileLastTouchUser();
					}

					editModel.entity.demographics = editModel.entity.demographics ? editModel.entity.demographics : [];
					var orgId =
						editModel.entity.organizationIds && editModel.entity.organizationIds.length
							? editModel.entity.organizationIds[0]
							: editModel.entity.organizationIds[0]
							? editModel.entity.organizationIds[0]
							: undefined;

					return getSiteCodes(result.organizationIds).then(function(siteCodes) {
						editModel.originalSiteCodes = siteCodes;
						return !orgId ? $q.when({ showDemographics: false, allowedDemographics: [] }) : DemographicsSettings.get(orgId);
					});
				})
				.then(function(settings) {
					editModel.showDemographics = settings.showDemographics; // show / hide
					editModel.allowedDemographics = settings.allowedDemographics;
					setupDemographics();
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				})
				.finally(function() {
					editModel.busy = false;
				});
		}

		function validateUsernameInOrganziations() {
			if ($scope.studentCreateForm && $scope.studentCreateForm.userName) {
				$scope.studentCreateForm.userName.$setValidity('unique', true);
			}
			if (
				editModel.entity.username &&
				editModel.entity.organizationIds &&
				(editModel.entity.username !== editModel.originalUsername ||
					_.difference(editModel.entity.organizationIds, editModel.organizationField.defaultIds).length !== 0)
			) {
				editModel.busy = true;
				getSiteCodes(editModel.entity.organizationIds).then(function(result) {
					siteCodes = result;
					if (editModel.entity.username === editModel.originalUsername) {
						siteCodes = _.difference(result, editModel.originalSiteCodes);
					}
					$q.all(
						siteCodes.map(function(siteCode) {
							return UsernameService.checkStudentAvailability(editModel.entity.username, siteCode);
						})
					)
						.then(function(results) {
							var errorSiteCodes = [];
							results.forEach(function(result, index) {
								if (result.usernames.length > 0 && result.usernames.indexOf(editModel.entity.username.toLowerCase()) >= 0) {
									errorSiteCodes.push(siteCodes[index]);
								}
							});
							if (errorSiteCodes.length > 0) {
								$scope.studentCreateForm.userName.errorSiteCodes = errorSiteCodes.join(',');
								$scope.studentCreateForm.userName.$setValidity('unique', false);
							}
						})
						.catch(function(error) {
							NotificationFactory.error(error);
						})
						.finally(function() {
							editModel.busy = false;
						});
				});
			}
		}

		function validateStudentIdInOrganziations() {
			if ($scope.studentCreateForm && $scope.studentCreateForm.studentId) {
				$scope.studentCreateForm.studentId.$setValidity('unique', true);
			}
			if (
				editModel.entity.tag &&
				editModel.entity.organizationIds &&
				(editModel.entity.tag !== editModel.originalStudentId ||
					_.difference(editModel.entity.organizationIds, editModel.organizationField.defaultIds).length !== 0)
			) {
				editModel.busy = true;
				getSiteCodes(editModel.entity.organizationIds).then(function(result) {
					siteCodes = result;
					if (editModel.entity.tag === editModel.originalStudentId) {
						siteCodes = _.difference(result, editModel.originalSiteCodes);
					}
					$q.all(
						siteCodes.map(function(siteCode) {
							return DistrictStudentIdService.checkAvailability(editModel.entity.tag, siteCode);
						})
					)
						.then(function(results) {
							var errorSiteCodes = [];
							results.forEach(function(result, index) {
								if (result && result.length > 0) {
									errorSiteCodes.push(siteCodes[index]);
								}
							});
							if (errorSiteCodes.length > 0) {
								$scope.studentCreateForm.studentId.errorSiteCodes = errorSiteCodes.join(',');
								$scope.studentCreateForm.studentId.$setValidity('unique', false);
							}
						})
						.catch(function(error) {
							NotificationFactory.error(error);
						})
						.finally(function() {
							editModel.busy = false;
						});
				});
			}
		}

		function saveEntity() {
			editModel.busy = true;
			var submittedStudent = angular.copy(editModel.entity);
			delete submittedStudent.groups;
			delete submittedStudent.organizations;

			Student.save(submittedStudent)
				.then(function(student) {
					NotificationFactory.success({
						heading:
							$filter('translate')('management.edit.messages.studentAccountHeader') +
							$filter('translate')('management.edit.messages.createdHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.created'),
						closeAfter: 5,
						persist: true
					});

					editModel.editComplete();
				})
				['catch'](function(error) {
					//username is already in use
					if (error.status === 409 && error.statusText === 'Conflict') {
						//"Invalid AuthUser(70dc34ce-a525-4be1-9fcd-a5280130a020)\r\n DuplicateKey: Username[aaaaaaaaaaaaaaaaaaaaa] causes duplicate key\r\n
						if (error.data && error.data.message && error.data.message.match(/DuplicateKey: Username/gi) !== null) {
							if ($scope.studentCreateForm && $scope.studentCreateForm.userName) {
								$scope.studentCreateForm.userName.$setValidity('unique', false);
							}
						}

						//"Invalid Student(70dc34ce-a525-4be1-9fcd-a5280130a020)\r\n DuplicateKey: Tag[AAAAAAAAAAAAAAAAAAAAA] causes duplicate key\r\n
						if (error.data && error.data.message && error.data.message.match(/DuplicateKey: Tag/gi) !== null) {
							if ($scope.studentCreateForm && $scope.studentCreateForm.studentId) {
								$scope.studentCreateForm.studentId.$setValidity('unique', false);
							}
						}
					} else {
						NotificationFactory.error(error);
					}
				})
				['finally'](function() {
					editModel.busy = false;
					//trigger ui changes based on current user studentCount
					$scope.$emit('CURRENT_USER_STUDENT_CHECK');
				});
		}

		function updateEntity() {
			editModel.busy = true;
			var submittedStudent = angular.copy(editModel.entity);
			delete submittedStudent.groups;
			delete submittedStudent.organizations;

			//PK-791
			if (submittedStudent.artifacts) {
				delete submittedStudent.artifacts;
			}

			if (submittedStudent.password?.length === 0) {
				delete submittedStudent.password;
			}

			Student.update(submittedStudent)
				.then(function() {
					NotificationFactory.success({
						heading:
							$filter('translate')('management.edit.messages.studentAccountHeader') +
							$filter('translate')('management.edit.messages.updatedHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.updated'),
						closeAfter: 5,
						persist: true
					});

					editModel.editComplete();
				})
				['catch'](function(error) {
					if (error.status === 400) {
						// PTL-909, student has groups assigned without belonging to their parent organization
						// this should never be hit now, but just in case
						// Invalid Student(************************************).\r\n  Membership: Groups[************************************] do not belong to any Organization that the Student belongs to.\r\n
						if (
							error.data &&
							error.data.message &&
							error.data.message.match(/Invalid Student/gim) !== null &&
							error.data.message.match(/Membership: Groups/gim) !== null &&
							error.data.message.match(/do not belong to any Organization/gim) !== null
						) {
							NotificationFactory.error({
								force: true,
								heading: 'Error saving student record.',
								content:
									'Please verify ' +
									editModel.entity.lastName +
									', ' +
									editModel.entity.firstName +
									' belongs to the parent organization of all selected groups.'
							});
						}
					} else if (error.status === 409 && error.statusText === 'Conflict') {
						//username is already in use
						//"Invalid AuthUser(************************************)\r\n DuplicateKey: Username[*********************] causes duplicate key\r\n
						if (error.data && error.data.message && error.data.message.match(/DuplicateKey: Username/gi) !== null) {
							if ($scope.studentCreateForm && $scope.studentCreateForm.userName) {
								$scope.studentCreateForm.userName.$setValidity('unique', false);
							}
						}

						//student.tag is already in use
						//"Invalid Student(************************************)\r\n DuplicateKey: Tag[*********************] causes duplicate key\r\n
						if (error.data && error.data.message && error.data.message.match(/DuplicateKey: Tag/gi) !== null) {
							if ($scope.studentCreateForm && $scope.studentCreateForm.studentId) {
								$scope.studentCreateForm.studentId.$setValidity('unique', false);
							}
						}
					} else {
						NotificationFactory.error(error);
					}
				})
				['finally'](function() {
					editModel.busy = false;
					//trigger ui changes based on current user studentCount
					$scope.$emit('CURRENT_USER_STUDENT_CHECK');
				});
		}

		function resetForm() {
			if (editModel.uiMode === editModel.uiModes.add) {
				editModel.selectedStudents.length = 0;
				searchPaginator.items.length = 0;
				searchPaginator.selectedItems.length = 0;

				setPaginator(paginatorTypes.selected);
				editModel.saveAndCreate = !editModel.saveAndCreate;
				editModel.studentSearchStr = '';
				if ($scope.studentCreateForm) {
					$scope.studentCreateForm.$setPristine();
				}
				setFocus();
			}
			if (editModel.uiMode === editModel.uiModes.create || editModel.uiMode === editModel.uiModes.edit) {
				editModel.entity.demographics = [];
				editModel.demographics = [];
				setupDemographics();
				editModel.entity.username = undefined;
				editModel.entity.firstName = undefined;
				editModel.entity.lastName = undefined;
				editModel.entity.password = undefined;
				editModel.entity.tag = undefined;
				editModel.entity.alternateStudentNumber = undefined;
				editModel.entity.gradeLevel = undefined;
				editModel.entity.sessionTime = 20;
				editModel.entity.firstLanguage = 'English';
				if ($scope.studentCreateForm) {
					$scope.studentCreateForm.$setPristine();
					$scope.studentCreateForm.$setUntouched();
				}
				setFocus();
			}
		}

		function save() {
			if (editModel.isValid()) {
				switch (editModel.uiMode) {
					case editModel.uiModes.add:
						var students = [],
							change = false;
						editModel.entity.groupIds = _.uniq(editModel.entity.groupIds);
						editModel.entity.organizationIds = _.uniq(editModel.entity.organizationIds);

						angular.forEach(editModel.selectedStudents, function(student) {
							angular.forEach(editModel.entity.organizationIds, function(orgId) {
								if (student.organizationIds.indexOf(orgId) === -1) {
									student.organizationIds.push(orgId);
									change = true;
								}
							});
							editModel.entity.groupIds = angular.isDefined(editModel.entity.groupIds) ? editModel.entity.groupIds : [];
							angular.forEach(editModel.entity.groupIds, function(groupId) {
								if (student.groupIds.indexOf(groupId) === -1) {
									student.groupIds.push(groupId);
									change = true;
								}
							});
							if (change) {
								students.push(student);
							}
						});
						if (students.length) {
							ManagerBulkOperations.createOrUpdate.students(students).then(function(response) {
								if (response.status === 'FinishedAllSuccess') {
									// reset all
									if (editModel.saveAndCreate === false) {
										NotificationFactory.success({
											heading:
												$filter('translate')('management.edit.messages.studentAccountHeader') +
												$filter('translate')('management.edit.messages.updatedHeader'),
											content:
												getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.updated'),
											closeAfter: 5,
											persist: true
										});
										editModel.editComplete();
									} else {
										editModel.resetForm();
									}
								} else {
									// ToDo: need to determine what we do here.
									editModel.error = true;
								}
							});
						} else {
							// ToDo: need to notify no students were updated
							// ToDo: because they already exist in the group or org
							editModel.error = true;
						}
						break;
					case editModel.uiModes.create:
					case editModel.uiModes.edit:
						editModel.entity.writtenLanguage = getWrittenLanguage(editModel.entity.firstLanguage);
						editModel.entity.groupIds = _.uniq(_.map(editModel.groups, 'id'));
						editModel.entity.organizationIds = _.uniq(_.map(editModel.organizations, 'id'));
						editModel.entity.alternateStudentNumber =
							editModel.entity.alternateStudentNumber === '' ? null : editModel.entity.alternateStudentNumber;

						if (editModel.uiMode === editModel.uiModes.create) {
							editModel.saveEntity();
						} else {
							editModel.updateEntity();
						}
						break;
				}
			} else {
				$scope.studentCreateForm.$submitted = true;
			}
		}

		function getParent() {
			editModel.busy = true;
			Organization.get($scope.params.parentId, {
				additionalFields: ['lexileSettings']
			})
				.then(function(result) {
					editModel.entity.organizationIds = [result.id];
					editModel.entity.lexileSettings = {
						optIn: result.lexileSettings ? result.lexileSettings.optIn : false
					};
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
					setFocus();
				});
		}

		function getSiteCodes(organizationIds) {
			return $q.all(
				organizationIds.map(function(organization) {
					return Organization.siteCode(organization);
				})
			);
		}

		function printStudentLoginCard() {
			CommonModal.close();
			$state.go('manager.studentcard.student', { studentId: editModel.entity.id });
		}
	}
})();
