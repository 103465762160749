(function() {
	angular.module('portal.manager.search.stateHistory', []).factory('SearchStateHistory', factory);

	factory.$inject = [];

	function factory() {
		var lastState;
		return {
			set: function(state) {
				lastState = state;
			},
			get: function() {
				return lastState;
			}
		};
	}
})();
