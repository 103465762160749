(function() {
	angular
		.module('portal.manager.home', [
			'ui.router',
			'portal.manager.navigation',
			'portal.manager.paginator',
			'angular.filter',
			'api.manager',
			'api.application.user',
			'portal.service',
			'ui.notifications'
		])
		.config(config)
		.controller('ManagerHomeController', controller);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('home', {
				parent: 'manager',
				controller: controller,
				controllerAs: 'model',
				abstract: true,
				templateUrl: 'management/home/home.layout.tpl.html'
			})
			.state('home.organizations', {
				url: '/organizations',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_organizations" type="organization"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_organizations" type="organization"></section>'
					}
				},
				data: { displayName: 'Management/Home/Organizations' }
			})
			.state('home.groups', {
				url: '/groups',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_groups" type="group"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_groups" type="group"></section>'
					}
				},
				data: { displayName: 'Management/Home/Groups' }
			})
			.state('home.staff', {
				url: '/staff',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_staff" type="staff"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_staff" type="staff"></section>'
					}
				},
				data: { displayName: 'Management/Home/Staff' }
			})
			.state('home.students', {
				url: '/students',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_students" type="student"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_students" type="student"></section>'
					}
				},
				data: { displayName: 'Management/Home/Students' }
			});
	}

	controller.$inject = [
		'$scope',
		'$state',
		'Portal',
		'Paginator',
		'NotificationFactory',
		'ApplicationUserService',
		'UserRole',
		'Navigation',
		'Organization',
		'Class',
		'User',
		'Student',
		'ILAdmin',
		'$timeout'
	];

	function controller(
		$scope,
		$state,
		Portal,
		Paginator,
		NotificationFactory,
		ApplicationUserService,
		UserRole,
		Navigation,
		Organization,
		Class,
		User,
		Student,
		ILAdmin,
		$timeout
	) {
		var model = this,
			ilAdminOrgId = Portal.appOrgId,
			organizations,
			groups,
			students,
			staff,
			currentUser = ApplicationUserService.getUser();

		model.state = undefined;
		model.id = undefined;
		model.stateChanged = stateChanged;

		function goHome() {
			Navigation.current = {
				entityType: 'home',
				displayText: 'Home \u00BB ' + currentUser.username
			};
		}

		organizations = new Paginator($scope, 'paginator_organizations', function() {
			return currentUser.role === UserRole.ilAdmin
				? Organization.children(ilAdminOrgId, organizations.params)
				: currentUser.role === UserRole.ilSiteAdmin
				? ILAdmin.organizations(currentUser.id, organizations.params)
				: User.organizations(currentUser.id, organizations.params);
		});

		organizations.params = {
			additionalFields: ['lexileSettings', 'fullOrganizationCount', 'fullGroupCount', 'fullUserCount', 'fullStudentCount'],
			sortby: 'name'
		};
		if (currentUser.role === UserRole.ilSiteAdmin) {
			organizations.params.rootSiteOrgsOnly = true;
		}

		groups = new Paginator($scope, 'paginator_groups', function() {
			return Class.query(groups.params);
		});
		groups.params = {
			additionalFields: ['studentCount', 'users'],
			sortby: 'name'
		};

		staff = new Paginator($scope, 'paginator_staff', function() {
			return User.query(staff.params);
		});
		staff.params = {
			additionalFields: ['studentCount'],
			sortby: 'lastName,firstName'
		};

		if (ApplicationUserService.getUserRole() === UserRole.teacher) {
			students = new Paginator($scope, 'paginator_students', function() {
				return User.assignedStudents(ApplicationUserService.getUser().id, students.params);
			});
		} else {
			students = new Paginator($scope, 'paginator_students', function() {
				return Student.search(students.params);
			});
		}
		students.params = {
			sortby: 'lastName,firstName',
			additionalFields: ['lexileSettings']
		};

		function stateChanged(event, toState, toParams, fromState, fromParams) {
			if (toState.name.indexOf('home.') !== -1) {
				model.state = toState.name;
				goHome();

				switch (model.state) {
					case 'home.organizations':
						$timeout(function() {
							$scope.paginator_organizations.doInitialLoad();
						});
						break;

					case 'home.groups':
						$timeout(function() {
							$scope.paginator_groups.doInitialLoad();
						});
						break;

					case 'home.staff':
						$timeout(function() {
							$scope.paginator_staff.doInitialLoad();
						});
						break;

					case 'home.students':
						$timeout(function() {
							$scope.paginator_students.doInitialLoad();
						});
						break;
				}
			}
		}

		$scope.$on('$stateChangeSuccess', stateChanged);
	}
})();
