(function() {
	angular
		.module('portal.manager.paginator', [
			'portal.service',
			'portal.manager.create.entity.factory',
			'utils.gradeLevel',
			'portal.manager.navigation',
			'api.manager.constants',
			'ui.modal.common',
			'config',
			'ui.notifications',
			'web.dropdownMenu',
			'api.featureFlags'
		])
		.factory('Paginator', factory);

	factory.$inject = [
		'ApplicationUserService',
		'ManagerCreateEntityFactory',
		'NotificationFactory',
		'Organization',
		'Class',
		'User',
		'Student',
		'CommonModal',
		'Navigation',
		'Types',
		'getNameList',
		'getDisplayText',
		'isEntitySynced',
		'$filter',
		'$state',
		'$q',
		'Portal',
		'FeatureFlagsService'
	];

	function factory(
		ApplicationUserService,
		ManagerCreateEntityFactory,
		NotificationFactory,
		Organization,
		Class,
		User,
		Student,
		CommonModal,
		Navigation,
		Types,
		getNameList,
		getDisplayText,
		isEntitySynced,
		$filter,
		$state,
		$q,
		Portal,
		FeatureFlagsService
	) {
		var Paginator = function($scope, name, fetchFunction, id, options) {
			this.currentUser = ApplicationUserService.getUser();
			this.dataAlwaysChecked = false;
			this.unCheckFunction = undefined;
			this.showPerPage = true;

			var model = this;
			model.showStudentImpersonation = false;
			FeatureFlagsService.evaluate('StudentImpersonation').then(function(result) {
				model.showStudentImpersonation = !!result && result === 'show';
			});

			if (options !== undefined) {
				this.dataAlwaysChecked = options.dataAlwaysChecked !== undefined ? options.dataAlwaysChecked : this.dataAlwaysChecked;
				this.unCheckFunction = options.unCheckFunction !== undefined ? options.unCheckFunction : this.unCheckFunction;
				this.showPerPage = options.showPerPage !== undefined ? options.showPerPage : this.showPerPage;
			}

			this.fetchFunction = fetchFunction;
			this.getNameList = getNameList;
			this.isEntitySynced = isEntitySynced;
			this.canEditOrg = canEditOrg;
			this.Navigation = Navigation;

			this.pageSizes = [
				{ value: 10, text: '10 per page' },
				{ value: 25, text: '25 per page' },
				{ value: 50, text: '50 per page' },
				{ value: 100, text: '100 per page' },
				{ value: 150, text: '150 per page' },
				{ value: 200, text: '200 per page' }
			];

			this.pageDisplayText = '';
			this.tokenBased = false;
			this.dataSrcArray = false;
			this.continuationToken = undefined;
			this.continuationTokenList = {};
			this.pageDirection = '';
			this.id = id;
			this.params = {};
			this.params.search = '';
			this.items = [];
			this.selectAll = false;
			this.selectedItems = [];
			this.showSelected = false;
			this.totalCount = 0; //total number of items
			this.errorOverlayId = undefined;
			this.loading = false; //true while loading content
			this.enabled = false;
			this.perPage = this.pageSizes[1].value; //how many items on each page
			this.pageNumber = 1; //which page the user wants to see
			this.lastPageNumber = 0;
			this._loadedPageNumer = -1; //which page is currently loaded
			var that = this;
			this.pageCount = function() {
				return Math.ceil(that.totalCount / that.perPage);
			};

			this.toInteger = function(text) {
				return parseInt(text, 10);
			};

			//bind all functions for conveience
			for (var k in this) {
				if (typeof this[k] === 'function') {
					this[k] = this[k].bind(this);
				}
			}

			//add to dependant's scope
			$scope[name] = this;

			//update pagination after user removes items
			$scope.$watch(
				function() {
					return $scope[name].selectedItems;
				},
				function(value, lastValue) {
					if ($scope[name].showSelected === true && value && value.length !== lastValue.length) {
						if ($scope[name].pageCount() < $scope[name].pageNumber) {
							if (value.length === 0) {
								$scope[name].showSelected = false;
								$scope[name].refreshAndRewind();
							} else {
								$scope[name].pageNumber = $scope[name].pageCount();
								$scope[name].refresh();
							}
						}
					}
				},
				true
			);

			//reload list when search is cleared out
			$scope.$watch(
				function() {
					return $scope[name].params.search;
				},
				function(value, lastValue) {
					if ((!value || value.length === 0) && lastValue && lastValue.length) {
						$scope[name].refreshAndRewind();
					}
				},
				true
			);

			function canEditOrg(organization) {
				return Portal.noQueryIds.indexOf(organization.id) === -1;
			}

			// selected items are never cleared to allow tab switching
			function clearPaginator() {
				$scope[name].items = [];
				$scope[name].selectedItems = [];
				$scope[name].selectAll = false;
				$scope[name].showSelected = false;
			}

			$scope.$on('paginator.clear', clearPaginator);

			function stateChangeSuccess() {
				$scope[name].showSelected = false;
			}

			$scope.$on('$stateChangeSuccess', stateChangeSuccess);
		};

		Paginator.prototype = {
			_removeEntityFromGroup: function(item, itemType, groupId) {
				var promise,
					paginator = this;

				switch (itemType) {
					case Types.student:
						promise = Class.removeStudent(groupId, item.id);
						break;

					case Types.staff:
						promise = Class.removeUser(groupId, item.id);
						break;
				}

				CommonModal.close();
				promise
					.then(function() {
						//refresh to remove item from list
						paginator.refresh();
						//send confirmation notification
						NotificationFactory.success({
							heading: $filter('translate')('management.removefromgroup.' + itemType + '.removed'),
							content: getDisplayText(item) + $filter('translate')('management.removefromgroup.removed'),
							closeAfter: 5
						});
					})
					['catch'](function(error) {
						NotificationFactory.error(error);
					});
			},

			_deleteEntity: function(item, itemType) {
				var promise,
					paginator = this;
				switch (itemType) {
					case Types.organization:
						promise = Organization.archive(item.id);
						break;

					case Types.group:
						promise = Class.remove(item.id);
						break;

					case Types.student:
						promise = Student.archive(item.id);
						break;

					case Types.staff:
						promise = User.remove(item.id);
						break;
				}

				CommonModal.close();
				promise
					.then(function() {
						//refresh to remove item from list
						paginator.refresh();
						//send confirmation notification
						NotificationFactory.success({
							heading: $filter('translate')('management.deletemodal.' + itemType + '.deleted'),
							content: getDisplayText(item) + $filter('translate')('management.deletemodal.deleted'),
							closeAfter: 5
						});
					})
					['catch'](function(error) {
						NotificationFactory.error(error);
					});
			},

			_fetchPage: function() {
				if (!this.enabled || this.loading || this._loadedPageNumer === this.pageNumber) {
					//we are not enabled, already busy, or already on the right page
					return;
				}

				if (this.pageNumber >= this.totalCount / this.perPage + 1) {
					//if we are past the end, wrap around
					this.pageNumber = 1;
					//this needs to be reset when cycling from end to beginning
					this.continuationToken = undefined;
				}

				if (this.pageNumber < 1) {
					//if we are past the beginning, wrap around
					this.pageNumber = this.pageCount();
					//this needs to be reset when cycling from beginning to end
					this.continuationToken = undefined;
				}

				//go fetch the data
				this.items.length = this.dataSrcArray ? this.items.length : 0;
				this._loadedPageNumer = this.pageNumber;
				var offset = this.perPage * (this.pageNumber - 1);

				this.loading = true;
				this.errorOverlayId = undefined;
				this.params = this.params || {};
				this.params.offset = offset;
				this.params.limit = this.perPage;

				if (this.showSelected === true) {
					//we are showing only the currently selected items - ex: bulk edit mode
					this.loading = false;
					this.totalCount = this.selectedItems.length;
					this.items = _.slice(this.selectedItems, this.params.offset, this.params.offset + this.params.limit);
				}
				//we are showing the contents of a server query which relies on an id
				else {
					if (this.id && this.id.length) {
						this.fetchFunction(this.id, this.params).then(
							function(results, headers) {
								this.loading = false;
								this.totalCount = results.totalCount;
								this.items = results.items;
								this.updatePageDisplayText();
							}.bind(this),
							function(err) {
								this.errorOverlayId = NotificationFactory.error(err);
								this.loading = false;
							}.bind(this)
						);
					}
					//generic query
					else {
						if (this.continuationToken !== undefined && this.continuationToken !== '') {
							this.params.continuationtoken = this.continuationToken;
						} else {
							delete this.params.continuationtoken;
						}

						if (this.pageDirection === 'back') {
							this.params.continuationtoken =
								this.lastPageNumber === 2 ? undefined : this._getContinuationToken(this.pageNumber);
						}
						if (this.fetchFunction(this.params).then !== undefined) {
							this.fetchFunction(this.params).then(
								function(results, headers) {
									this.loading = false;
									this.totalCount = results.totalCount;
									this.continuationToken = results.continuationToken;
									this._addContinuationToken(this.pageNumber + 1, results.continuationToken);
									this.items = results.items;
									this.updatePageDisplayText();
								}.bind(this),
								function(err) {
									this.errorOverlayId = NotificationFactory.error(err);
									this.loading = false;
								}.bind(this)
							);
						} else {
							var data = this.fetchFunction();
							this.items = [];
							this.loading = false;
							this.totalCount = data.totalCount;
							this.updatePageDisplayText();
							if (this.dataSrcArray) {
								for (var i = this.params.offset; i < this.totalCount; ++i) {
									if (data.items[i] !== undefined) {
										this.items.push(data.items[i]);
									}
									if (this.items.length === this.params.limit) {
										break;
									}
								}
								// make sure all data checked
								if (this.dataAlwaysChecked) {
									for (var k = 0; k < this.items.length; ++k) {
										var item = this.items[k];
										if (!this.isSelected(item)) {
											this.toggleSelected(item);
										}
									}
								}
							} else {
								this.items = data.items;
							}
						}
					}
				}
			},

			resetContintuationTokenList: function() {
				delete this.continuationTokenList;
				this.continuationTokenList = {};
			},

			_addContinuationToken: function(pageNumber, token) {
				if (token !== '' && this.continuationTokenList[pageNumber] === undefined) {
					this.continuationTokenList[pageNumber] = token;
				}
			},

			_getContinuationToken: function(pageNumber) {
				return this.continuationTokenList[pageNumber];
			},

			doInitialLoad: function() {
				if (!this.enabled) {
					this.enabled = true;
					this.params.desc = false;
				}
				//this.showSelected = false;
				this.params.search = '';
				//this.params.desc = false;
				this.refreshAndRewind();
			},

			//refresh current page
			refresh: function() {
				if (!this.enabled) {
					return;
				}
				//invalidate the current page
				this._loadedPageNumer = -1;
				//get the current page
				this._fetchPage();
			},

			//go to first page
			refreshAndRewind: function() {
				if (!this.enabled) {
					return;
				}
				this.pageNumber = 1;
				this.totalCount = 0;
				this.refresh();
			},

			//go back a page
			back: function() {
				if (!this.enabled) {
					return;
				}
				this.lastPageNumber = _.clone(this.pageNumber);
				this.pageNumber--;
				this.pageDirection = 'back';
				this.refresh();
			},

			//go forward a page
			forward: function() {
				if (!this.enabled) {
					return;
				}
				this.lastPageNumber = _.clone(this.pageNumber);
				this.pageNumber++;
				this.pageDirection = 'forward';
				this.refresh();
			},

			//go to last page
			last: function() {
				if (!this.enabled) {
					return;
				}
				this.pageNumber = this.pageCount();
				this.refresh();
			},

			// toggle showing the query results or the selected items
			toggleViewMode: function() {
				this.showSelected = !this.showSelected;
				this.refreshAndRewind();
			},

			//get paged slice of selected items
			fetchSelectedItems: function(params) {
				return $q.when(_.slice(this.selectedItems, params.offset, params.limit));
			},

			isSelected: function(item) {
				return (
					_.findIndex(this.selectedItems, function(selectedItem) {
						return selectedItem.id === item.id;
					}) !== -1
				);
			},

			allSelected: function() {
				var paginator = this;
				return (
					_.every(paginator.items, function(item) {
						return paginator.isSelected(item);
					}) === true
				);
			},

			toggleSelected: function(item) {
				var index = _.findIndex(this.selectedItems, function(selectedItem) {
					return selectedItem.id === item.id;
				});

				if (index === -1) {
					this.selectedItems.push(item);
				} else {
					if (this.unCheckFunction && this.dataAlwaysChecked) {
						this.unCheckFunction(item);
					}
					this.selectedItems.splice(index, 1);
				}

				if (this.showSelected === true) {
					//refresh and show the user the item has been removed
					this.refresh();
				}
			},

			toggleSelectItem: function(item, selected) {
				//selected - bool - selected state items should be put in
				var index = _.findIndex(this.selectedItems, function(selectedItem) {
					return selectedItem.id === item.id;
				});

				if (index === -1 && selected) {
					this.selectedItems.push(item);
				} else {
					if (!selected) {
						if (this.unCheckFunction && this.dataAlwaysChecked && !paginator.showSelected) {
							this.unCheckFunction(item);
						}
						this.selectedItems.splice(index, 1);
					}
				}
			},

			toggleSelectAll: function() {
				var paginator = this,
					allItemsSelected =
						_.every(paginator.items, function(item) {
							return paginator.isSelected(item);
						}) === true;

				angular.forEach(paginator.items, function(item) {
					paginator.toggleSelectItem(item, !allItemsSelected);
				});

				if (paginator.showSelected === true) {
					//refresh and show the user the item has been removed
					paginator.refresh();
				}
			},

			editItem: function(item, itemType) {
				var paginator = this;

				function callback() {
					paginator.refresh();
				}

				ManagerCreateEntityFactory.edit(itemType, item.id, callback);
			},

			deleteItem: function(item, itemType) {
				var paginator = this;
				var params = {
					heading: $filter('translate')('management.deletemodal.' + itemType + '.header') + getDisplayText(item),
					content: $filter('translate')('management.deletemodal.' + itemType + '.content'),
					buttons: [
						{ name: 'CANCEL', text: 'Cancel', callBack: CommonModal.close },
						{
							name: 'DELETE',
							text: 'Delete',
							callBack: function() {
								paginator._deleteEntity(item, itemType);
							}
						}
					]
				};
				CommonModal.show(params);
			},

			removeFromGroup: function(item, itemType, groupId) {
				var paginator = this;
				var params = {
					heading: $filter('translate')('management.removefromgroup.' + itemType + '.header') + getDisplayText(item),
					content: $filter('translate')('management.removefromgroup.' + itemType + '.content'),
					buttons: [
						{ name: 'CANCEL', text: 'Cancel', callBack: CommonModal.close },
						{
							name: 'REMOVE',
							text: 'Remove',
							callBack: function() {
								paginator._removeEntityFromGroup(item, itemType, groupId);
							}
						}
					]
				};
				CommonModal.show(params);
			},

			sort: function(field) {
				//only toggle sort order if the same field is being sorted
				if (this.params.sortby === field) {
					if (this.params.desc === undefined) {
						this.params.desc = false;
					} else {
						this.params.desc = !this.params.desc;
					}
				} else {
					this.params.sortby = field;
					this.params.desc = false;
				}
				this.refresh();
			},

			goToItem: function(item) {
				var types = {
						user: 'staff',
						student: 'student'
					},
					routes = {
						organization: 'manager.organization.organizations',
						group: 'manager.group.students'
					},
					route = routes[item.type.toLowerCase()];
				if (item.type.toLowerCase() === 'user' || item.type.toLowerCase() === 'student') {
					ManagerCreateEntityFactory.edit(types[item.type.toLowerCase()], item.id);
				} else {
					$state.go(route, { id: item.id }, { notify: true });
				}
			},

			updatePageDisplayText: function() {
				this.pageDisplayText = [
					this.pageNumber === 1 ? 1 : 1 + (this.pageNumber - 1) * this.perPage,
					'-',
					this.totalCount < this.perPage
						? this.totalCount
						: this.pageNumber === this.pageCount()
						? this.totalCount
						: this.pageNumber * this.perPage,
					'of',
					this.totalCount
				].join(' ');
			}
		};

		return Paginator;
	}
})();
