(function() {
	angular
		.module('portal.manager.navigation', [
			'api.manager',
			'ui.router',
			'ui.modal.common',
			'portal.service',
			'portal.manager.create.entity.factory',
			'ui.notifications'
		])
		.config(config)
		.factory('Navigation', factory);

	config.$inject = ['$qProvider'];

	function config($qProvider) {
		$qProvider.errorOnUnhandledRejections(false);
	}

	factory.$inject = [
		'Organization',
		'NotificationFactory',
		'ManagerCreateEntityFactory',
		'Portal',
		'$q',
		'$state',
		'isEntitySynced',
		'getDisplayText',
		'Types'
	];

	function factory(
		Organization,
		NotificationFactory,
		ManagerCreateEntityFactory,
		Portal,
		$q,
		$state,
		isEntitySynced,
		getDisplayText,
		Types
	) {
		var navigation = this;
		navigation.breadcrumbs = [];
		navigation.breadcrumbsLoading = false;
		navigation.current = {};
		navigation.errorOverlay = false;
		navigation.isEntitySynced = isEntitySynced;
		navigation.showEditIcon = showEditIcon;

		navigation.editEntity = editEntity;

		function editEntity(itemType, id) {
			function callback() {
				var params = angular.copy($state.params);
				$state.go($state.current.name, params, { reload: true });
			}
			ManagerCreateEntityFactory.edit(itemType, id, callback);
		}

		function showEditIcon() {
			return Portal.noQueryIds.indexOf(navigation.current?.id) === -1;
		}

		var fetchAncestors = function(child) {
			var ancestors = [];
			// Return the result of an IIFE so that we can re-use the function in the function body for recursion
			return (function fetch(child) {
				var id = child.parentId || child.organizationId || null;
				if (id === null) {
					return $q.when();
				} else {
					return Organization.get(id)
						.then(function(response) {
							response.displayText = response.name;
							response.route = "manager.organization.organizations({ 'id': '" + response.id + "' })";
							ancestors.push(response);
							return fetch(response);
						})
						['catch'](function(error) {
							if (error.status !== 404 && error.status !== -1) {
								//404 === hit root org
								navigation.errorOverlay = NotificationFactory.error(error);
							}
						});
				}
			})(child).then(function() {
				return ancestors;
			});
		};

		navigation.fetchBreadcrumbs = function(entity) {
			if (!entity || !entity.id) {
				NotificationFactory.error('Invalid entity');
				return;
			}

			if (entity.entityType === Types.student) {
				entity.parentId = null;
				angular.forEach(entity.organizationIds, function(id) {
					if (
						_.find(navigation.breadcrumbs, function(crumb) {
							return crumb.id === id || (crumb.parentId && crumb.parentId === id);
						}) !== undefined
					) {
						entity.parentId = id;
					}
				});
				entity.parentId = entity.parentId === null ? entity.organizationIds[0] : entity.parentId;
			}

			if (entity.entityType === Types.staff) {
				entity.parentId = null;
				angular.forEach(entity.organizationIds, function(id) {
					if (
						_.find(navigation.breadcrumbs, function(crumb) {
							return crumb.id === id || (crumb.parentId && crumb.parentId === id);
						}) !== undefined
					) {
						entity.parentId = id;
					}
				});
				entity.parentId = entity.parentId === null ? entity.organizationIds[0] : entity.parentId;
			}

			//if (entity.entityType === Types.artifact) {//cross this bridge if we get to it
			//}

			navigation.current = entity;
			navigation.current.displayText = getDisplayText(navigation.current);
			navigation.current.route = 'organization.organizations';
			navigation.errorOverlay = false;

			//navigation.breadcrumbs = [];
			navigation.breadcrumbs = [navigation.current];
			navigation.breadcrumbsLoading = true;
			fetchAncestors(entity).then(function(ancestors) {
				navigation.breadcrumbs = navigation.breadcrumbs.concat(ancestors);
				navigation.breadcrumbs.push({
					displayText: 'Home',
					route: 'home.organizations'
				});
				navigation.breadcrumbsLoading = false;
			});
		};

		return navigation;
	}
})();
