/**
 * Created by joehawkins on 7/3/14.
 */

(function() {
	var root = this;
	var translations = {
		en: {
			common: {
				filter: 'Filter',
				loading: 'Loading...',
				legal: 'Privacy Policy',
				logout: 'Log Out',
				save: 'Save',
				create: 'Create',
				submit: 'Submit',
				update: 'Update',
				edit: 'Edit',
				cancel: 'Cancel',
				close: 'Close',
				remove: 'Delete',
				removeFromGroup: 'Remove from class',
				import: 'Import',
				export: 'Export',
				yes: 'Yes',
				no: 'No',
				dontShow: "Don't show this message again.",
				all: 'All',
				partial: 'Partial',
				none: 'None',
				selectedCount: '<strong>Selected</strong> %s',
				page: 'Page',
				clearSelected: 'Deselect All',
				togglePage: 'Select All',
				results: 'Results',
				selectStudent: 'Select a Student',
				selectClass: 'Select a Group',
				selectGrade: 'Select a Grade'
			},

			gradeLevels: {
				preK: 'Pre Kindergarten',
				kindergarten: 'Kindergarten',
				grade1: 'Grade 1',
				grade2: 'Grade 2',
				grade3: 'Grade 3',
				grade4: 'Grade 4',
				grade5: 'Grade 5',
				grade6: 'Grade 6',
				grade7: 'Grade 7',
				grade8: 'Grade 8',
				grade9: 'Grade 9',
				grade10: 'Grade 10',
				grade11: 'Grade 11',
				grade12: 'Grade 12',
				other: 'Other'
			},
			languages: {
				english: 'English',
				spanish: 'Spanish'
			},
			oralLanguages: {
				English: 'English',
				Japanese: 'Japanese',
				Spanish: 'Spanish',
				Mandarin: 'Mandarin',
				Korean: 'Korean',
				PortugueseBrazil: 'Portuguese (Brazil)',
				French: 'French',
				HaitianCreole: 'Haitian Creole',
				Arabic: 'Arabic',
				Marshallese: 'Marshallese',
				Vietnamese: 'Vietnamese',
				Russian: 'Russian',
				Tagalog: 'Tagalog',
				Cantonese: 'Cantonese',
				Hmong: 'Hmong',
				Somali: 'Somali'
			},
			writtenLanguages: {
				English: 'English',
				Japanese: 'Japanese',
				Spanish: 'Spanish',
				ChineseTraditional: 'Chinese (Traditional)',
				ChineseSimplified: 'Chinese (Simplified)',
				Korean: 'Korean',
				PortugueseBrazil: 'Portuguese (Brazil)',
				French: 'French',
				HaitianCreole: 'Haitian Creole',
				Arabic: 'Arabic',
				Marshallese: 'Marshallese',
				Vietnamese: 'Vietnamese',
				Russian: 'Russian',
				Tagalog: 'Tagalog',
				Hmong: 'Hmong',
				Somali: 'Somali'
			},
			userTypes: {
				admin: 'Administrator',
				districtAdmin: 'District Administrator',
				schoolAdmin: 'School Administrator',
				teacher: 'Instructor'
			},
			orgTypes: {
				district: 'District',
				school: 'School',
				other: 'Other'
			},
			countries: {
				usa: 'United States',
				uk: 'United Kingdom',
				costaRica: 'Costa Rica',
				haiti: 'Haiti',
				japan: 'Japan',
				vietnam: 'Vietnam'
			},
			apps: {
				portfolio: 'Student Portfolio',
				dashboard: 'Dashboard',
				studentDashboard: 'Student Dashboard',
				action_areas: 'Action Areas',
				growth: 'Growth',
				progress: 'Progress',
				reports_builder: 'Reports Builder',
				rewards: 'Rewards',
				usage: 'Usage',
				activities: 'Activities',
				resources: 'Teacher Resources',
				help: 'Help',
				manager: 'Management',
				management: 'Management',
				teachersManager: 'Students & Groups',
				login: 'Log In',
				forgottenPassword: 'Forgotten Password',
				resetPassword: 'Reset Password',
				preview: 'PREVIEW'
			},
			header: {
				site_title: 'Imagine Language & Literacy',
				espanol_site: 'Imagine Español',
				print: 'Print',
				support: 'Help',
				options: 'Options'
			},
			login: {
				currentPassIncorrect: 'Current password is incorrect, please try again.',
				instructions: 'Use your Imagine Learning account to log in.',
				username: 'Username',
				usernamePlaceholder: 'Email',
				password: 'Password',
				forgottenPassword: 'Forgot password?',
				backToLogin: 'Back to login',
				remember: 'Remember Me',
				login: 'Log in',
				emptyUsername: 'Please enter your email',
				usernameConfirm: 'Confirm Username',
				usernameMatchError: 'Username does not match',
				emptyPassword: 'Please enter your password',
				invalidEmail: 'Invalid email address',
				usernameLengthError: 'Email address must be less than 256 characters',
				emailHint: 'Check to make sure that you entered your email address correctly in this format: you@example.com.',
				invalidCredentials: 'Invalid username or password',
				unspecifiedError: 'An error has occurred. Please try again.',
				invalidCredentialsHint:
					'You entered your username or password (or both) incorrectly, or the username you entered is not associated with an existing Imagine Learning account.',
				invalidPassword: 'Your current password is incorrect.  Please try again.',
				resetPasswordEmailSent:
					"Email sent. Check your inbox for instructions on how to reset your password. If you don't receive an email, please contact Imagine Learning Technical Support (866-457-8776). Or visit us at help.imaginelearning.com.",
				resetPasswordInvalidLogin: 'There is no user with the given login. Please check the spelling and try again.',
				resetPassword: 'Reset Password',
				resetPasswordInstruction: "Enter your email address below. We'll send you instructions for resetting your password.",
				resetPasswordSuccessHeader: 'User account updated.',
				resetPasswordSuccessContent: 'Password for user %s has been changed.',
				invalidResetCode: 'Sorry, this reset password link is not valid. Try resending the password reset email.',
				sendEmail: 'Send Email'
			},
			manager: {
				select: 'Select',
				existingStudents: 'Existing Students',
				selectExistingStudent: 'Select an Existing Student',
				sortBy: 'Sort By',
				organization: 'Organization',
				organizations: 'Organizations',
				orgs: 'Orgs',
				type: 'Type',
				organizationType: 'Type of Organization',
				class: 'Class',
				classes: 'Classes',
				school: 'School',
				schools: 'Schools',
				gradeLevel: 'Grade Level',
				grade: 'Grade',
				licenses: 'Licenses',
				teacher: 'Instructor',
				teachers: 'Instructors',
				teacherList: 'List of instructors',
				student: 'Student',
				students: 'Students',
				user: 'User',
				userName: 'Username',
				name: 'Name',
				users: 'Users',
				allGrades: 'All grade levels',
				allOrganizations: 'All organizations',
				allStudents: 'All students',
				firstName: 'First Name',
				lastName: 'Last Name',
				password: 'Password',
				studentsFirstLanguage: "Student's First Language",
				oralLanguage: 'Oral Instruction Language',
				printoutLanguage: 'Student Printout Language',
				className: 'Class/Course Name',
				classNumber: 'Class Number',
				sectionName: 'Room/Section Name',
				classType: 'Class Type',
				printOptions: 'Print Options',
				sessionTime: 'Session Time',
				language: 'Language',
				newPassword: 'New Password',
				confirmPassword: 'Confirm New Password',
				userType: 'Type of User',
				address: 'Street Address',
				address2: 'Street Address 2',
				city: 'City',
				state: 'State',
				zip: 'Zip Code',
				country: 'Country',
				parentOrganization: 'Parent Organization',
				licenseCap: 'License Cap',
				organizationNumber: 'Organization Number',
				siteCode: 'Site Code',
				selectOne: 'Select One',
				dontChange: 'No Change',
				logoutNotifyHeader: 'Account Timeout',
				logoutNotifyTextPre: 'For security purposes, you will be logged out of your account in',
				logoutNotifyTextPost: 'seconds.',
				logoutNotifyTextClickCancel: 'Click Cancel below to stay logged in.',
				changePassword: 'Change Password',
				changePasswordFor: 'Change Password for User: ',
				currentPassword: 'Current Password',
				studentsTab: {
					studentSource: 'Show me students from',
					myStudents: 'My Students',
					allStudents: 'All Students'
				},
				heading: {
					createStudents: 'Create Student',
					updateStudent: 'Edit Student',
					bulkUpdateStudents: 'Bulk Edit Students',
					deleteStudent: 'Delete Student',
					removeStudent: 'Remove Student',
					bulkDeleteStudents: 'Delete Students',
					bulkRemoveStudents: 'Remove Students',
					importStudents: 'Import Students',
					prepareImport: 'Prepare Data for Import',
					selectImportFile: 'Select File for Import',
					pressImport: 'Press Import Button &amp; Import',
					studentPassword: 'Student Password',
					studentInfo: 'Student Info',
					organizationClassInfo: 'Organizations &amp; Class Info',
					languageInfo: 'Language Info',
					createClass: 'Create Class',
					updateClass: 'Edit Class',
					deleteClass: 'Delete Class',
					usersStudents: 'Users &amp; Organizations',
					setPassword: 'Set Password',
					createUser: 'Create User',
					editUser: 'Edit User',
					deleteUser: 'Delete User',
					createOrg: 'Create Organization',
					editOrg: 'Edit Organization',
					theseStudentsWillUpdate: 'The following students will be updated:',
					deleteOrg: 'Delete Organization'
				},
				message: {
					information: 'Information:',
					warning: 'Warning!',
					userHasNoStudentsMessage: 'Please add students to your classes so you may use this area to review their data.',
					cannotDeleteOrganizationMessage:
						'Organizations cannot be deleted until all child Organizations, Users, Classes and Students have been removed.',
					cannotEditOwnOrg: 'You cannot edit your own organization.',
					cannotDeleteClassMessage: 'Classes cannot be deleted until all Students have been removed.',
					cannotDeleteSelfMessage: 'Users cannot delete themselves.',
					cannotDeleteUserMessage: 'Users cannot be deleted while they are associated with any Classes.',
					deleteClassMessage: 'Are you sure you want to delete <span>%s</span>?',
					deleteStudentMessage: 'Are you sure you want to delete <span>%s</span>?',
					removeStudentMessage: 'Are you sure you want to remove <span>%s</span> from all classes?',
					removeStudentMessageTeacher: 'Are you sure you want to remove <span>%s</span> from all your classes?',
					bulkDeleteStudentsMessage: 'Are you sure you want to delete the following students?',
					bulkRemoveStudentsMessage: 'Are you sure you want to remove the following students from all classes?',
					bulkRemoveStudentsMessageTeacher: 'Are you sure you want to remove the following students from all your classes?',
					deleteOrganizationMessage: 'Are you sure you want to delete <span>%s</span>?',
					deleteUserMessage: 'Are you sure you want to delete <span>%1$s %2$s</span>?',
					studentPassword: 'The password for <span>%1$s %2$s</span> is:',
					adminOneOrg: 'Administrators can only be associated with one school.'
				},
				error: {
					usernameRequired: 'Enter a username.',
					emailFormatInvalid: 'Enter a valid email address.',
					emailFormatRequired: 'Enter an email address.',
					usernameFormat: 'Username format error.',
					usernameUnique: 'The username must be unique.',
					userNameInUse: 'Username is already in use.',
					usernameMinLength: 'Username must be at least 3 characters.',
					userFirstNameLength: 'First name may not be longer than 32 characters.',
					userLastNameLength: 'Last name may not be longer than 32 characters.',
					userNameLength: 'Username may not be longer than 128 characters.',
					studentFirstNameLength: 'First name may not be longer than 64 characters.',
					studentLastNameLength: 'Last name may not be longer than 64 characters.',
					classNameLength: 'Class name may not be longer than 256 characters',
					siteCodeLength: 'Site Code may not be longer than 128 characters',
					organizationNameLength: 'Organization name may not be longer than 256 characters',
					passwordLength: 'Password must be at least 8 characters.',
					passwordMaxLength: 'Password may not be longer than 256 characters.',
					studentPasswordLength: 'Password must be at least 4 characters.',
					passwordRequired: 'Enter a password.',
					confirmPassword: 'Confirm the password.',
					passwordMatch: 'Passwords do not match.',
					passwordLengthConfirm: 'See password error.',
					firstNameRequired: 'Enter a first name.',
					lastNameRequired: 'Enter a last name.',
					organizationIdRequired: 'Select an organization.',
					adminOnlyOneOrg: 'An Administrator can only belong to one organization.',
					gradeLevelRequired: 'Select a Grade Level.',
					oralLanguageRequired: 'Select an Oral Instruction Language.',
					printoutLanguageRequired: 'Select a Printout Language.',
					classNameRequired: 'Enter a class name.',
					classTypeRequired: 'Select a class type.',
					teacherRequired: 'Select an instructor.',
					printOptionsRequired: 'Select a print option.',
					sessionTimeRequired: 'Enter a session time.',
					sessionTimeLength: 'Session time must be between 5 and 120.',
					sessionTimeFormat: 'Only numbers are allowed.',
					languageRequired: 'Select a language.',
					userTypeRequired: 'Select a type of user.',
					parentOrgRequired: 'Select a parent organization.',
					badParentOrg: 'Select a different parent organization.',
					orgTypeRequired: 'Select an organization type.',
					nameRequired: 'Enter a name.',
					countryRequired: 'Select a country.',
					addressRequired: 'Enter an address.',
					cityRequired: 'Enter a city.',
					stateRequired: 'Select a state.',
					zipRequired: 'Enter a zip code between 5 and 10 characters.',
					currentPasswordRequired: 'Please enter your current password',
					unknownError: 'Sorry, an unknown error has occurred.'
				}
			},
			progress: {
				recordings: 'Recordings',
				vadRecording: 'Audio not available'
			}
		}
	};

	if (root.angular) {
		root.angular.module('portal.localization', ['utils.localization']).config(config);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations(translations);
	}
})();

// (function(){
// 	var root = this;
// 	var translations = {
// 		'en': {
// 			common: {
// 				filter: 'Filter',
// 				loading: 'Loading...',
// 				legal: 'Privacy Policy',
// 				logout: 'Log Out',
// 				save: 'Save',
// 				create: 'Create',
// 				submit: 'Submit',
// 				update: 'Update',
// 				edit: 'Edit',
// 				cancel: 'Cancel',
// 				close: 'Close',
// 				remove: 'Delete',
// 				removeFromGroup: 'Remove from class',
// 				'import': 'Import',
// 				'export': 'Export',
// 				yes: 'Yes',
// 				no: 'No',
// 				dontShow: 'Don\'t show this message again.',
// 				all: 'All',
// 				partial: 'Partial',
// 				none: 'None',
// 				selectedCount: '<strong>Selected</strong> %s',
// 				page: 'Page',
// 				clearSelected: 'Deselect All',
// 				togglePage: 'Select All',
// 				results: 'Results',
// 				selectStudent: 'Select a Student',
// 				selectClass: 'Select a Group',
// 				selectGrade: 'Select a Grade'
// 			},
// 			apps: {
// 				portfolio: 'Student Portfolio',
// 				dashboard: 'Dashboard',
// 				studentDashboard: 'Student Dashboard',
// 				action_areas: 'Action Areas',
// 				growth: 'Growth',
// 				progress: 'Progress',
// 				reports_builder: 'Reports Builder',
// 				rewards: 'Rewards',
// 				usage: 'Usage',
// 				activities: 'Activities',
// 				resources: 'Teacher Resources',
// 				help: 'Help',
// 				manager: 'Management',
// 				management: 'Management',
// 				teachersManager: 'Students & Groups',
// 				login: 'Log In',
// 				forgottenPassword: 'Forgotten Password',
// 				resetPassword: 'Reset Password',
// 				preview: 'PREVIEW'
// 			},
// 			header: {
// 				site_title: 'Imagine Learning Portal',
// 				print: 'Print',
// 				support: 'Help',
// 				options: 'Options'
// 			}
// 		}
// 	};
//
// 	if(root.angular){
// 		root.angular
// 			.module('portal.localization', [
// 				'utils.localization'
// 			])
// 			.config(config);
// 	}
// 	else if(typeof module !== 'undefined' && module.exports){
// 		// Node.js
// 		module.exports = translations;
// 	}
//
// 	config.$inject = ['LocalizeProvider'];
//
// 	function config(LocalizeProvider) {
// 		LocalizeProvider.translations(translations);
// 	}
//
// })();
