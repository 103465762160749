(function() {
	angular.module('ildashboard').config(config);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('ilDashboardRoot', {
				controller: 'ILDashboardController',
				controllerAs: 'ctrl',
				parent: 'root.layout',
				templateUrl: 'ildashboard/template.html',
				abstract: true,
				data: {
					bodyClass: 'dashboard ildashboard'
				}
			})
			.state('ildashboard', {
				parent: 'ilDashboardRoot',
				url: 'ildashboard',
				abstract: true,
				views: {
					'': {
						template: '<div ui-view></div>'
					}
				}
			});

		$urlRouterProvider.when('/ildashboard', [
			'$state',
			function($state) {
				$state.go('ildashboard.auditHistory', {}, { notify: true });
			}
		]);
	}
})();
