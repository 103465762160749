import * as Papa from 'papaparse';

(function() {
	'use strict';

	angular
		.module('portal.manager.utils.fileParser', [])
		.constant('IlFileParserFileTypes', {
			csv: 'csv',
			xls: 'xls',
			xlsx: 'xlsx'
		})
		.factory('IlFileParser', factory);

	factory.$inject = ['IlFileParserFileTypes'];

	function factory(IlFileParserFileTypes) {
		if (typeof Papa === 'undefined') {
			throw new Error("angular-PapaParse's JavaScript requires PapaParse");
		}
		var config = {
			delimiter: '',
			newline: '',
			header: true,
			dynamicTyping: false,
			preview: 0,
			encoding: '',
			worker: false,
			comments: false,
			step: undefined,
			complete: undefined,
			error: undefined,
			download: false,
			skipEmptyLines: true,
			chunk: undefined,
			fastMode: true
		};

		function parseXLSFile(file, successCallback, errorCallback) {
			var reader = new FileReader();
			reader.onload = function(e) {
				var data = '';
				var bytes = new Uint8Array(e.target.result);
				var length = bytes.length;
				for (var i = 0; i < length; i++) {
					data += String.fromCharCode(bytes[i]);
				}
				var workbook = XLSX.read(data, { type: 'binary' }),
					json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { type: 'binary' });
				successCallback({ data: json });
			};
			reader.readAsArrayBuffer(file);
		}

		function parseCSVFile(file, successCallback, errorCallback) {
			config.complete = successCallback;
			config.error = errorCallback;
			return Papa.parse(file, config);
		}

		function doParse(file, type, successCallback, errorCallback) {
			if (type === IlFileParserFileTypes.csv) {
				parseCSVFile(file, successCallback, errorCallback);
			}
			if (type === IlFileParserFileTypes.xls || type === IlFileParserFileTypes.xlsx) {
				parseXLSFile(file, successCallback, errorCallback);
			}
		}

		return {
			parseFile: function(file, type, successCallback, errorCallback) {
				doParse(file, type, successCallback, errorCallback);
			}
		};
	}
})();
