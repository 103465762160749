(function() {
	angular
		.module('portal.manager.actions.manual.placement', [
			'api.manager',
			'ui.notifications',
			'ui.modal.common',
			'utils.gradeLevel',
			'utils.localization'
		])
		.controller('ManualPlacementController', controller);

	controller.$inject = ['$scope', 'NotificationFactory', '$filter', 'CommonModal', 'Student'];

	function controller($scope, NotificationFactory, $filter, CommonModal, Student) {
		let controller = this;

		controller.save = save;
		controller.student = $scope.params.student;
		controller.placement = undefined;

		controller.options = ['BeginningReader', 'BelowGrade', 'OnGrade', 'AboveGrade'];

		function save() {
			let promise = Student.manualPlacement(controller.student.id, controller.student.gradeLevel, controller.placement);
			promise
				.then(response => {
					NotificationFactory.success({
						heading: $filter('translate')('management.manualPlacement.success.header'),
						content: $filter('translate')('management.manualPlacement.success.message') + response.data.actualPlacementPoint,
						closeAfter: 5,
						persist: true
					});
					if ($scope.params.callback) {
						$scope.params.callback(response);
					}
					CommonModal.close();
				})
				.catch(error => {
					NotificationFactory.error(error);
					CommonModal.close();
				});
		}
	}
})();
