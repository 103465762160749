(function() {
	angular
		.module('portal.manager.actions.directive', [
			'portal.manager.navigation',
			'portal.manager.studentcard',
			'portal.manager.utils',
			'api.manager',
			'api.oralLanguage',
			'ui.notifications',
			'ui.modal.common',
			'ui.modal.custom',
			'ui.router',
			'utils.localization',
			'utils.gradeLevel'
		])
		.controller('ManagerActionsController', controller)
		.directive('ilManagerActions', directive);

	function directive() {
		return {
			restrict: 'EA',
			templateUrl: 'management/actions/actions.tpl.html',
			replace: true,
			scope: {
				type: '@',
				paginator: '=ilManagerActions',
				organizationId: '='
			},
			controller: controller,
			controllerAs: 'vm'
		};
	}

	controller.$inject = [
		'$scope',
		'$q',
		'$state',
		'$rootScope',
		'Navigation',
		'ManagerCreateEntityFactory',
		'ManagerManualPlacementFactory',
		'NotificationFactory',
		'ManagerBulkOperations',
		'CommonModal',
		'ManagerStudentCardFactory',
		'$filter',
		'isEntitySynced',
		'ApplicationUserService',
		'UserRole',
		'DemographicsSettings',
		'OralLanguage',
		'AssessmentProviders',
		'AssessmentProvider',
		'GradeLevel'
	];

	function controller(
		$scope,
		$q,
		$state,
		$rootScope,
		Navigation,
		ManagerCreateEntityFactory,
		ManagerManualPlacementFactory,
		NotificationFactory,
		ManagerBulkOperations,
		CommonModal,
		ManagerStudentCardFactory,
		$filter,
		isEntitySynced,
		ApplicationUserService,
		UserRole,
		DemographicsSettings,
		OralLanguage,
		AssessmentProviders,
		AssessmentProvider,
		GradeLevel
	) {
		var userRootOrganization = ApplicationUserService.getRootOrganization(),
			userRole = ApplicationUserService.getUserRole(),
			isAdmin = userRole === UserRole.ilAdmin || userRole === UserRole.ilSiteAdmin,
			orgParentIds = [
				angular.isDefined(userRootOrganization) && angular.isDefined(userRootOrganization.id) ? userRootOrganization.id : ''
			],
			vm = this;

		vm.enabledOptions = [
			{
				text: $filter('translate')('management.terms.unchanged'),
				value: undefined
			},
			{
				text: $filter('translate')('management.terms.enabled'),
				value: true
			},
			{
				text: $filter('translate')('management.terms.disabled'),
				value: false
			}
		];

		vm.bulk_actions = [];
		vm.bulk_action = undefined;
		vm.actions = {
			edit: {
				value: 'edit',
				text: 'Edit',
				action: function() {
					vm.toggleBulkEdit();
				}
			},
			printLoginCards: {
				value: 'printLoginCards',
				text: 'Print Login Cards',
				action: function() {
					vm.bulkPrintLoginCards($scope.type);
				}
			},
			delete: {
				value: 'delete',
				text: 'Delete',
				action: function() {
					vm.bulkDelete($scope.type);
				}
			},
			removeFromGroup: {
				value: 'removeFromGroup',
				text: 'Remove From Group',
				action: function() {
					var groupId = $state.params.id;
					angular.forEach($scope.paginator.selectedItems, function(student) {
						student.groupIds = _.without(student.groupIds, groupId);
					});
					vm.bulkRemoveFromGroup('student');
				}
			},
			manualPlacement: {
				value: 'manualPlacement',
				text: 'Manual Placement',
				action: function() {
					ManagerManualPlacementFactory.create($scope.paginator.selectedItems[0]);
				}
			}
		};

		vm.model = {};
		vm.model.bulkEdit = $scope.paginator.showSelected || false;

		vm.studentBulkEditModel = {};
		vm.studentBulkEditModel.languages = [];
		vm.studentBulkEditModel.gradeLevel = undefined;
		vm.studentBulkEditModel.password = undefined;
		vm.studentBulkEditModel.sessionTime = undefined;
		vm.studentBulkEditModel.language = undefined;
		vm.studentBulkEditModel.lexileOption = undefined;
		vm.studentBulkEditModel.indeterminate = true;
		vm.studentBulkEditModel.organizations = [];
		vm.studentBulkEditModel.groups = [];
		vm.studentBulkEditModel.demographics = [];
		vm.hideAddButton = userRole !== UserRole.ilAdmin && $scope.type === 'organization';
		vm.hideDeleteButton = userRole !== UserRole.teacher && $scope.type !== 'organization';
		vm.hideActionsButton = $scope.type === 'organization';

		vm.studentBulkEditModel.orgField = {
			required: false,
			disabled: false,
			default: {
				ids: []
			},
			parent: {
				ids: orgParentIds,
				list: []
			}
		};
		vm.studentBulkEditModel.grpField = {
			required: false,
			disabled: true,
			default: {
				ids: []
			},
			parent: {
				ids: [],
				list: vm.studentBulkEditModel.organizations
			}
		};

		vm.staffBulkEditModel = {};
		vm.staffBulkEditModel.userTypes = [
			{ name: 'Administrator', value: 'Admin' },
			{ name: 'Teacher', value: 'Teacher' }
		];
		vm.staffBulkEditModel.userType = undefined;
		vm.staffBulkEditModel.organizations = [];
		vm.staffBulkEditModel.groups = [];
		vm.staffBulkEditModel.orgField = {
			required: false,
			disabled: false,
			default: {
				ids: []
			},
			parent: {
				ids: orgParentIds,
				list: []
			}
		};
		vm.staffBulkEditModel.grpField = {
			required: false,
			disabled: true,
			default: {
				ids: []
			},
			parent: {
				ids: [],
				list: vm.staffBulkEditModel.organizations
			}
		};

		vm.demographics = [];
		vm.showDemographics = undefined;
		vm.allowedDemographics = undefined;
		vm.useDemographics = userRole !== UserRole.teacher && userRole !== UserRole.ilSiteAdmin;

		vm.doAction = doAction;
		vm.buildActions = buildActions;
		vm.staffBulkEditModelIsValid = staffBulkEditModelIsValid;
		vm.studentBulkEditModelIsValid = studentBulkEditModelIsValid;
		vm.toggleBulkEdit = toggleBulkEdit;
		vm.search = search;
		vm.createNew = createNew;
		vm.bulkUpdate = bulkUpdate;
		vm.bulkDelete = bulkDelete;
		vm.bulkRemoveFromGroup = bulkRemoveFromGroup;
		vm.bulkPrintLoginCards = bulkPrintLoginCards;
		vm.selectedCountIndicator = selectedCountIndicator;
		vm.selectedStudentCount = selectedStudentCount;
		vm.selectedStudentCountIndicator = selectedStudentCountIndicator;
		vm.selectedDeletableItemCount = selectedDeletableItemCount;
		vm.selectedDeletableItemCountIndicator = selectedDeletableItemCountIndicator;

		vm.actionField = {
			options: vm.bulk_actions,
			label: 'Actions ' + vm.selectedCountIndicator()
		};

		// start watchers
		$scope.$watch(
			function() {
				return vm.studentBulkEditModel.organizations;
			},
			function(n, o) {
				if (o !== n) {
					vm.studentBulkEditModel.grpField.parent.list = n;
					vm.studentBulkEditModel.grpField.disabled = true;
					if (n.length) {
						vm.studentBulkEditModel.grpField.disabled = false;
					}
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return vm.staffBulkEditModel.organizations;
			},
			function(n, o) {
				if (o !== n) {
					vm.staffBulkEditModel.grpField.parent.list = n;
					vm.staffBulkEditModel.grpField.disabled = true;
					if (n.length) {
						vm.staffBulkEditModel.grpField.disabled = false;
					}
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return vm.demographics;
			},
			function(n) {
				if (n) {
					var demographics = [];
					for (var k = 0; k < n.length; ++k) {
						if (n[k].label && n[k].checked) {
							demographics.push(n[k].label);
						}
					}
					vm.studentBulkEditModel.demographics = demographics;
				}
			},
			true
		);

		function init() {
			//user unselected last item, clear out action mode
			if ($scope.paginator) {
				$scope.$watch(
					function() {
						return $scope.paginator.selectedItems;
					},
					function(n, o) {
						if (!n || !n.length) {
							vm.model.bulkEdit = false;
						} else {
							vm.buildActions();
						}
					},
					true
				);
			}

			// init lists
			var promise, orgId;
			promise = OralLanguage.query()
				.then(function(results) {
					vm.studentBulkEditModel.languages = _.sortBy(results, function(result) {
						return result === 'English' ? 0 : result;
					});
					orgId = $scope.organizationId ? $scope.organizationId : orgParentIds[0];
					return orgId ? DemographicsSettings.get(orgId) : $q.when();
				})
				.then(function(settings) {
					if (settings) {
						vm.showDemographics = settings.showDemographics;
						vm.allowedDemographics = settings.allowedDemographics;
					}
					setupDemographics();
				});

			promise.catch(function(error) {
				NotificationFactory.error(error);
			});
		}

		init();

		function setupDemographics() {
			// init demographics
			var demographics = [];

			// setup checkbox list settings
			if (vm.allowedDemographics && vm.showDemographics) {
				for (var l = 0; l < vm.allowedDemographics.length; ++l) {
					demographics.push({
						label: vm.allowedDemographics[l],
						checked: false
					});
				}
				vm.demographics = demographics;
			}
		}

		function doAction() {
			if (angular.isDefined(vm.actions[vm.bulk_action])) {
				vm.actions[vm.bulk_action].action();
			}
		}

		function buildActions() {
			vm.bulk_actions = [];
			vm.bulk_action = undefined;
			if ($scope.type === 'staff' || $scope.type === 'student') {
				vm.bulk_actions.push(vm.actions.edit);
			}

			if ($scope.type !== 'organization' && $scope.type !== 'staff') {
				vm.bulk_actions.push(vm.actions.printLoginCards);
			}

			if (vm.hideDeleteButton) {
				vm.bulk_actions.push(vm.actions.delete);
			}

			if ($state.current.name === 'manager.group.students') {
				vm.bulk_actions.push(vm.actions.removeFromGroup);
			}

			if (
				isAdmin &&
				$scope.type === 'student' &&
				$scope.paginator.selectedItems.length === 1 &&
				$scope.paginator.selectedItems[0].gradeLevel !== 'PreK'
			) {
				AssessmentProvider.getForOrgAndChildren(
					$scope.paginator.selectedItems[0].organizationIds[0],
					GradeLevel.getGradeLevelNumber($scope.paginator.selectedItems[0].gradeLevel)
				).then(provider =>
					provider.defaultAssessmentProvider !== AssessmentProviders.ILE
						? vm.bulk_actions.push(vm.actions.manualPlacement)
						: angular.noop()
				);
			}

			vm.actionField.label = 'Actions ' + vm.selectedCountIndicator();
			vm.actionField.options = vm.bulk_actions;
		}

		function isValid(field, hasLength) {
			return field !== undefined && field !== null && !!(hasLength === true && field.length > 0);
		}

		function staffBulkEditModelIsValid() {
			return (
				(vm.staffBulkEditModel.userType !== undefined &&
					vm.staffBulkEditModel.userType !== null &&
					vm.staffBulkEditModel.userType.length > 0) ||
				vm.staffBulkEditModel.organizations.length > 0 ||
				vm.staffBulkEditModel.groups.length > 0
			);
		}

		function studentBulkEditModelIsValid() {
			return (
				(vm.studentBulkEditModel.gradeLevel !== undefined &&
					vm.studentBulkEditModel.gradeLevel !== null &&
					vm.studentBulkEditModel.gradeLevel.length > 0) ||
				(vm.studentBulkEditModel.password !== undefined &&
					vm.studentBulkEditModel.password !== null &&
					vm.studentBulkEditModel.password.length > 0) ||
				(vm.studentBulkEditModel.sessionTime !== undefined && vm.studentBulkEditModel.sessionTime !== null) ||
				(vm.studentBulkEditModel.language !== undefined &&
					vm.studentBulkEditModel.language !== null &&
					vm.studentBulkEditModel.language.length > 0) ||
				vm.studentBulkEditModel.lexileOption !== undefined ||
				vm.studentBulkEditModel.organizations.length > 0 ||
				vm.studentBulkEditModel.groups.length > 0 ||
				vm.studentBulkEditModel.demographics.length > 0
			);
		}

		function toggleBulkEdit() {
			vm.studentBulkEditModel.gradeLevel = undefined;
			vm.studentBulkEditModel.password = undefined;
			vm.studentBulkEditModel.sessionTime = undefined;
			vm.studentBulkEditModel.language = undefined;
			vm.studentBulkEditModel.lexileOption = undefined;
			vm.studentBulkEditModel.indeterminate = true;
			vm.studentBulkEditModel.organizations = [];
			vm.studentBulkEditModel.groups = [];
			vm.studentBulkEditModel.demographics = [];

			vm.staffBulkEditModel.userType = undefined;
			vm.staffBulkEditModel.organizations = [];
			vm.staffBulkEditModel.groups = [];

			vm.model.bulkEdit = !vm.model.bulkEdit;
			$scope.paginator.toggleViewMode();
			init();
		}

		function search() {
			$scope.paginator.params.search = vm.searchStr;
			$scope.paginator.refreshAndRewind();
		}

		function createNew() {
			function callback() {
				$scope.paginator.refresh();
			}

			var parentId;
			if (Navigation.current.entityType !== 'home') {
				if (Navigation.breadcrumbs[0] && Navigation.breadcrumbs[0].organizationType) {
					parentId = Navigation.breadcrumbs[0].id;
				} else if (Navigation.breadcrumbs[1] && Navigation.breadcrumbs[1].organizationType) {
					parentId = Navigation.breadcrumbs[1].id;
				}
			}
			ManagerCreateEntityFactory.create($scope.type, parentId, callback);
		}

		function bulkUpdate(itemType) {
			var count = $scope.paginator.selectedItems.length,
				promise = null,
				items = [],
				label = itemType === 'student' ? itemType : 'staff account';

			NotificationFactory.alert({
				heading: 'Bulk Edit',
				content: `Updating ${count} ${label}${count > 1 ? 's' : ''}`
			});

			switch (itemType) {
				case 'staff':
					angular.forEach($scope.paginator.selectedItems, function(item) {
						var bulkItem = { Id: item.id };
						if (vm.staffBulkEditModel.userType !== undefined) {
							bulkItem.userType = vm.staffBulkEditModel.userType;
						}

						if (vm.staffBulkEditModel.organizations.length > 0) {
							bulkItem.organizationIds = _.uniq(
								item.organizationIds.concat(_.map(vm.staffBulkEditModel.organizations, 'id'))
							);
						}

						if (vm.staffBulkEditModel.groups.length > 0) {
							bulkItem.groupIds = _.uniq(item.groupIds.concat(_.map(vm.staffBulkEditModel.groups, 'id')));
						}
						items.push(bulkItem);
					});
					promise = ManagerBulkOperations.patch.users(items);
					break;

				case 'student':
					angular.forEach($scope.paginator.selectedItems, function(item) {
						var bulkItem = { Id: item.id };
						if (vm.studentBulkEditModel.gradeLevel !== undefined) {
							bulkItem.gradeLevel = vm.studentBulkEditModel.gradeLevel;
						}

						if (vm.studentBulkEditModel.password !== undefined && vm.studentBulkEditModel.password.length) {
							bulkItem.password = vm.studentBulkEditModel.password;
						}

						if (vm.studentBulkEditModel.sessionTime !== undefined) {
							bulkItem.sessionTime = vm.studentBulkEditModel.sessionTime;
						}

						if (vm.studentBulkEditModel.language !== undefined) {
							bulkItem.firstLanguage = vm.studentBulkEditModel.language;
						}

						if (vm.studentBulkEditModel.lexileOption !== undefined) {
							bulkItem.lexileSettings = {
								optIn: vm.studentBulkEditModel.lexileOption
							};
							bulkItem.lexileOptIn = vm.studentBulkEditModel.lexileOption;
						}

						if (vm.studentBulkEditModel.organizations.length > 0) {
							bulkItem.organizationIds = _.uniq(
								item.organizationIds.concat(_.map(vm.studentBulkEditModel.organizations, 'id'))
							);
						}

						if (vm.studentBulkEditModel.demographics.length > 0) {
							bulkItem.demographics = vm.studentBulkEditModel.demographics;
						}

						if (vm.studentBulkEditModel.groups.length > 0) {
							bulkItem.groupIds = _.uniq(item.groupIds.concat(_.map(vm.studentBulkEditModel.groups, 'id')));
						}

						items.push(bulkItem);
					});
					promise = ManagerBulkOperations.patch.students(items);
					break;
			}

			if (promise !== null) {
				vm.toggleBulkEdit();

				promise
					.then(function(result) {
						//clear out cache to ensure the list refreshes as expected
						$rootScope.$broadcast('utils.timed_cache.clear');

						NotificationFactory.clear();
						NotificationFactory.success({
							heading: $filter('translate')('management.bulkEdit.' + itemType + '.header'),
							content: $filter('translateFormatString')(
								'management.bulkEdit.' + itemType + '.content',
								$scope.paginator.selectedItems.length
							),
							closeAfter: 5
						});

						$scope.paginator.selectedItems = [];
					})
					.catch(function(error) {
						NotificationFactory.error(error);
					})
					.finally(function() {
						$scope.paginator.refresh();
					});
			}
		}

		function bulkRemoveFromGroup(itemType) {
			var count = $scope.paginator.selectedItems.length,
				type = itemType;

			function doBulkRemoveFromGroup() {
				CommonModal.close();
				NotificationFactory.alert({
					heading: $filter('translate')('management.bulkEdit.' + itemType + '.header'),
					content: `Removing ${count} ${itemType}${count > 1 ? 's' : ''}`
				});

				var promise = null,
					items = [];

				switch (type) {
					case 'staff':
						angular.forEach($scope.paginator.selectedItems, function(item) {
							var bulkItem = { Id: item.id };
							bulkItem.groupIds = _.uniq(item.groupIds.concat(_.map(vm.staffBulkEditModel.groups, 'id')));
							items.push(bulkItem);
						});
						promise = ManagerBulkOperations.patch.staff(items);
						break;

					case 'student':
						angular.forEach($scope.paginator.selectedItems, function(item) {
							var bulkItem = { Id: item.id };
							bulkItem.groupIds = _.uniq(item.groupIds.concat(_.map(vm.studentBulkEditModel.groups, 'id')));
							items.push(bulkItem);
						});
						promise = ManagerBulkOperations.patch.students(items);
						break;
				}

				if (promise !== null) {
					promise
						.then(function(result) {
							//clear out cache to ensure the list refreshes as expected
							$rootScope.$broadcast('utils.timed_cache.clear');
							NotificationFactory.clear();
							NotificationFactory.success({
								heading: $filter('translate')('management.bulkEdit.' + type + '.header'),
								content: $filter('translateFormatString')(
									'management.bulkEdit.' + type + '.content',
									$scope.paginator.selectedItems.length
								),
								closeAfter: 5
							});

							$scope.paginator.selectedItems = [];
						})
						.catch(function(error) {
							NotificationFactory.clear();
							NotificationFactory.error(error);
						})
						.finally(function() {
							$scope.paginator.refresh();
						});
				}
			}

			var params = {
				heading: $filter('translate')('management.bulkRemoveFromGroup.' + type + '.header'),
				content: $filter('translateFormatString')('management.bulkRemoveFromGroup.' + type + '.content', count),
				buttons: [
					{ name: 'CANCEL', text: 'CANCEL', callBack: CommonModal.close },
					{ name: 'SAVE', text: 'REMOVE FROM GROUP', callBack: doBulkRemoveFromGroup }
				]
			};

			CommonModal.show(params);
		}

		function bulkDelete(itemType) {
			var count = $scope.paginator.selectedItems.length,
				label = itemType === 'student' ? itemType : 'staff account';

			function doBulkDelete() {
				CommonModal.close();
				NotificationFactory.alert({
					heading: 'Bulk Delete',
					content: `Deleting ${count} ${label}${count > 1 ? 's' : ''}`
				});
				var promise = null;

				var deletableItems = _.reject($scope.paginator.selectedItems, function(item) {
					return isEntitySynced(item) === true;
				});

				switch (itemType) {
					case 'organization':
						promise = ManagerBulkOperations.delete.organizations(deletableItems);
						break;

					case 'group':
						promise = ManagerBulkOperations.delete.groups(deletableItems);
						break;

					case 'staff':
						promise = ManagerBulkOperations.delete.staff(deletableItems);
						break;

					case 'student':
						promise = ManagerBulkOperations.archive.students(deletableItems);
						break;
				}

				if (promise !== null) {
					promise
						.then(function(result) {
							//clear out cache to ensure the list refreshes as expected
							$rootScope.$broadcast('utils.timed_cache.clear');

							NotificationFactory.clear();
							NotificationFactory.success({
								heading: $filter('translate')('management.bulkDelete.' + itemType + '.header'),
								content: $filter('translateFormatString')('management.bulkDelete.' + itemType + '.deleted', count),
								closeAfter: 5
							});

							$scope.paginator.selectedItems = [];
						})
						.catch(function(error) {
							NotificationFactory.clear();
							NotificationFactory.error(error);
						})
						.finally(function() {
							$scope.paginator.refresh();
						});
				}
			}

			var params = {
				heading: $filter('translate')('management.bulkDelete.' + itemType + '.header'),
				content: $filter('translateFormatString')('management.bulkDelete.' + itemType + '.content', count),
				buttons: [
					{ name: 'CANCEL', text: 'CANCEL', callBack: CommonModal.close },
					{ name: 'DELETE', text: 'DELETE', callBack: doBulkDelete }
				]
			};

			CommonModal.show(params);
		}

		function bulkPrintLoginCards(itemType) {
			ManagerStudentCardFactory.reset();
			if (itemType === 'group') {
				angular.forEach($scope.paginator.selectedItems, function(item) {
					ManagerStudentCardFactory.add(itemType, item);
				});
				$state.go('manager.studentcard.bulk', { type: 'group' }, { notify: true });
			} else if (itemType === 'student') {
				angular.forEach($scope.paginator.selectedItems, function(item) {
					ManagerStudentCardFactory.add(itemType, item);
				});
				$state.go('manager.studentcard.bulk', { type: 'student' }, { notify: true });
			}
		}

		//don't need to show (0) if there are no items selected...
		function selectedCountIndicator() {
			if (!$scope.paginator) {
				return '';
			}
			return $scope.paginator.selectAll || $scope.paginator.selectedItems.length
				? '(' + ($scope.paginator.selectAll === true ? $scope.paginator.totalCount : $scope.paginator.selectedItems.length) + ')'
				: '';
		}

		function sum(srcArray) {
			if (!srcArray || !srcArray.length) {
				return 0;
			}
			var rtn = 0;
			angular.forEach(srcArray, function(num) {
				rtn += num;
			});
			return rtn;
		}

		function selectedStudentCount() {
			var type = '',
				studentCounts,
				count = 0;
			if (!$scope.paginator) {
				return '';
			}
			if ($scope.paginator.selectedItems.length) {
				type = angular.isDefined($scope.paginator.selectedItems[0]['studentCount']) ? 'group' : 'student';
				if (type === 'student') {
					count = $scope.paginator.selectedItems.length;
				}
				if (type === 'group') {
					studentCounts = _.map($scope.paginator.selectedItems, 'studentCount');
					count = studentCounts && studentCounts.length ? sum(studentCounts) : 0;
				}
			}
			return count;
		}

		function selectedStudentCountIndicator() {
			if (!$scope.paginator) {
				return '';
			}

			return '(' + vm.selectedStudentCount() + ')';
		}

		//to maintain consistency - synced items may not be bulk deleted
		function selectedDeletableItemCount() {
			if (!$scope.paginator) {
				return '';
			}

			var canBeDeleted = _.reject($scope.paginator.selectedItems, function(item) {
				return isEntitySynced(item) === true;
			});

			return canBeDeleted.length;
		}

		function selectedDeletableItemCountIndicator() {
			if (!$scope.paginator) {
				return '';
			}

			var canBeDeleted = vm.selectedDeletableItemCount();
			return canBeDeleted > 0 ? '(' + canBeDeleted + ')' : '';
		}
	}
})();
