(function() {
	angular.module('ildashboard').controller('ILDashboardController', controller);

	controller.$inject = ['$scope', 'NotificationFactory', 'ApplicationUserService', 'UserRole', 'ILAdmin', '$timeout', 'ENV'];

	function controller($scope, NotificationFactory, ApplicationUserService, UserRole, ILAdmin, $timeout, ENV) {
		let ctrl = this;
		ctrl.iladmin = undefined;
		ctrl.showIlAdministratorTab = _.get(ENV, 'enabledDevelopmentFeatures.showIlAdministratorTab', false);

		$timeout(function() {
			const user = ApplicationUserService.getUser();
			if (user && user.id) {
				ILAdmin.get(user.id)
					.then(function(result) {
						ctrl.iladmin = result || undefined;
						ctrl.userIsILAdmin = ctrl.iladmin.role === UserRole.ilAdmin;
						ctrl.userIsILSiteAdmin = ctrl.iladmin.role === UserRole.ilSiteAdmin;
					})
					.catch(function(error) {
						NotificationFactory.error(error);
					})
					.finally(function() {});
			}
		}, 0);
	}
})();
