(function() {
	angular
		.module('portal.manager.actions.create.organization', [
			'api.manager',
			'portal.manager.utils',
			'test.enablement',
			'test.ranges',
			'ui.directives.sitecode.validator',
			'ui.modal.common',
			'ui.notifications',
			'utils.date',
			'utils.localization',
			'organization.assessment.provider'
		])
		.controller('CreateOrganizationController', controller);

	controller.$inject = [
		'$scope',
		'$filter',
		'IlFileParser',
		'getDisplayText',
		'isEntitySynced',
		'NotificationFactory',
		'Organization',
		'UserRole',
		'IlImport',
		'$q',
		'$timeout',
		'CurrentDate',
		'DateUtils',
		'TimeZones',
		'ApplicationUserService',
		'ComponentsDropdownDataTypes',
		'ComponentsDropdownModes',
		'DemographicsSettings',
		'CommonModal'
	];

	function controller(
		$scope,
		$filter,
		IlFileParser,
		getDisplayText,
		isEntitySynced,
		NotificationFactory,
		Organization,
		UserRole,
		IlImport,
		$q,
		$timeout,
		CurrentDate,
		DateUtils,
		TimeZones,
		ApplicationUserService,
		ComponentsDropdownDataTypes,
		ComponentsDropdownModes,
		DemographicsSettings,
		CommonModal
	) {
		var editModel = this,
			userRootOrganization = ApplicationUserService.getRootOrganization(),
			orgParentIds = [
				angular.isDefined(userRootOrganization) && angular.isDefined(userRootOrganization.id) ? userRootOrganization.id : ''
			],
			additionalFields = [
				'LexileSettings',
				'RestrictStudentAccessTime',
				'UsageGoal',
				'SchoolYearSessions',
				'isSchoolYearInherited',
				'parentName',
				'settings'
			];

		editModel.enabledOptions = [
			{
				text: $filter('translate')('management.terms.enabled'),
				value: true
			},
			{
				text: $filter('translate')('management.terms.disabled'),
				value: false
			}
		];

		editModel.timeZones = TimeZones;
		editModel.organizationTypes = ['Other', 'District', 'School'];
		editModel.daysArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		editModel.monthsArray = [
			{ name: 'January', value: 1, days: 31 },
			{ name: 'February', value: 2, days: 29 },
			{ name: 'March', value: 3, days: 31 },
			{ name: 'April', value: 4, days: 30 },
			{ name: 'May', value: 5, days: 31 },
			{ name: 'June', value: 6, days: 30 },
			{ name: 'July', value: 7, days: 31 },
			{ name: 'August', value: 8, days: 31 },
			{ name: 'September', value: 9, days: 30 },
			{ name: 'October', value: 10, days: 31 },
			{ name: 'November', value: 11, days: 30 },
			{ name: 'December', value: 12, days: 31 }
		];
		editModel.showDemographics = false;

		editModel.import = {};

		editModel.import.states = {
			getFile: 'getFile',
			validateFile: 'validateFile',
			validateFileSuccess: 'validateFileSuccess',
			validateFileFailed: 'validateFileFailed',
			uploadData: 'uploadData'
		};

		editModel.import.bulk = {};

		editModel.import.bulk.statuses = {
			none: 'none',
			processing: 'processing',
			success: 'success',
			partialSuccess: 'partial-success',
			failed: 'failed'
		};

		editModel.import.state = editModel.import.states.getFile;
		editModel.import.organizations = [];
		editModel.import.progress = [];
		editModel.import.dropElement = undefined;
		editModel.import.fileName = undefined;
		editModel.import.report = {};

		editModel.import.bulk.status = editModel.import.bulk.statuses.none;

		editModel.import.importFileChanged = importFileChanged;
		editModel.import.importDropHandler = importDropHandler;
		editModel.import.importDragEnterHandler = importDragEnterHandler;
		editModel.import.importDragLeaveHandler = importDragLeaveHandler;
		editModel.import.uploadFileData = uploadFileData;
		editModel.import.reset = reset;

		editModel.importOrgField = {
			parentIds: undefined, // tobe defined in init
			defaultIds: [],
			dataType: ComponentsDropdownDataTypes.organization,
			subType: undefined,
			includeParent: true,
			fieldIsReady: true,
			label: 'Organization',
			mode: ComponentsDropdownModes.singleselect,
			modelIsReady: true,
			disabled: false,
			readOnly: true,
			required: true
		};

		editModel.organizationField = {
			parentIds: undefined, // tobe defined in init
			defaultIds: [],
			dataType: ComponentsDropdownDataTypes.organization,
			subType: undefined,
			includeParent: true,
			fieldIsReady: true,
			label: 'Parent Organization',
			mode: ComponentsDropdownModes.singleselect,
			modelIsReady: false,
			disabled: false,
			required: true
		};

		_.map(editModel.monthsArray, function(month) {
			var counter = 0;
			var result = new Array(month.days);
			month.days = _.map(result, function(day) {
				day = { value: ++counter };
				return day;
			});
			return month;
		});

		editModel.isValid = isValid;
		editModel.clearError = clearError;
		editModel.save = save;
		editModel.updateEntity = updateEntity;
		editModel.saveEntity = saveEntity;
		editModel.getParent = getParent;
		editModel.getEntity = getEntity;
		editModel.editComplete = editComplete;
		editModel.getMediaServers = getMediaServers;
		editModel.daySelected = daySelected;
		editModel.dayAbbr = dayAbbr;
		editModel.getSmallTime = getSmallTime;
		editModel.toggleDay = toggleDay;
		editModel.currentUser = ApplicationUserService.getUser();
		editModel.validateOrganizationName = validateOrganizationName;

		editModel.disableSiteCodeField =
			ApplicationUserService.getUserRole() !== UserRole.ilSiteAdmin && ApplicationUserService.getUserRole() !== UserRole.ilAdmin;

		var clearModel = function() {
			editModel.id = '';
			editModel.busy = false;
			editModel.originalLexileSetting = false;
			editModel.createMode = false;
			editModel.saveAndCreate = false;
			editModel.synced = false;

			editModel.modal = {};
			editModel.modal.tab = 'details';
			editModel.modal.usageEnabled = false;
			editModel.modal.importEnabled = false;
			editModel.modal.mediaServerEnabled = false;

			editModel.entity = {};
			editModel.originalEntity = {};
			editModel.organizations = [];
			editModel.parentOrganization = {};
			editModel.parentOrganizationId = [];
			editModel.parentOrganizationName = undefined;
			editModel.parentOrganizationEditable = true;
			editModel.parentOrganizationVisible = true;
			editModel.mediaServers = [];
			editModel.mediaServersFilter = '';
			editModel.originalMediaServerIds = [];
			//editModel.rootSiteCode = undefined;
			editModel.parentOrganizationSiteCode = undefined;
			editModel.badParentOrgMsg = false;
			editModel.errorOverlay = false;

			editModel.daysDirty = false;
			editModel.restrictStudentAccessTime = {};
			editModel.backupRestrictStudentAccessTime = {};
			editModel.siteCode = null;
			editModel.cachedEntity = {};
			editModel.cachedSettings = {};
			editModel.lexileLastTouchUser = {};
		};
		clearModel();

		$scope.$watch(
			function() {
				return editModel.organizations;
			},
			function(n, o) {
				if (o !== n) {
					editModel.parentOrganizationId = _.map(n, 'id');
					validateOrganizationName();
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return editModel.import.organizations;
			},
			function(n, o) {
				if (o !== n) {
					if (n.length === 0) {
						editModel.import.state = editModel.import.states.getFile;
					}
				}
			},
			true
		);

		$timeout(function() {
			editModel.import.importInput = angular.element('#importFile');
			editModel.import.dropElement = angular.element('.il_modal_dialog_content form .drop_here');
			var events = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'];
			angular.forEach(events, function(event) {
				editModel.import.dropElement.bind(event, function(e) {
					e.preventDefault();
					e.stopPropagation();
				});
			});
			editModel.import.dropElement
				.bind('dragenter', editModel.import.importDragEnterHandler)
				.bind('dragleave', editModel.import.importDragLeaveHandler)
				.bind('drop', editModel.import.importDropHandler);
		});

		function initialize() {
			clearModel();
			if ($scope.params && $scope.params.id && $scope.params.id.length > 0) {
				editModel.createMode = false;
				editModel.getEntity($scope.params.id);
			} else {
				editModel.createMode = true;

				editModel.entity.lexileSettings = {
					optIn: true
					// optIn: false,
					// changeOrganizationId: editModel.id,
					// changeUserId: ApplicationUserService.getUser().id
				};

				editModel.entity.growthSettings = {
					optIn: false,
					changeOrganizationId: editModel.id,
					changeUserId: ApplicationUserService.getUser().id
				};

				editModel.entity.schoolYearSessions = undefined;
				editModel.modal.usageEnabled = false;
				editModel.restrictStudentAccessTime = {};
				editModel.restrictStudentAccessTime.start = getTimeInDate('8:00');
				editModel.restrictStudentAccessTime.end = getTimeInDate('15:00');
				editModel.restrictStudentAccessTime.timezone = DateUtils.getLocalTimeZone() || 'US/Pacific';
				editModel.restrictStudentAccessTime.allowedDays = editModel.daysArray.slice(1, 6);
				editModel.backupRestrictStudentAccessTime = angular.copy(editModel.restrictStudentAccessTime);

				if ($scope.params && $scope.params.parentId) {
					editModel.organizationField.defaultIds = [$scope.params.parentId];
					editModel.organizationField.parentIds = [$scope.params.parentId];
					editModel.importOrgField.defaultIds = [$scope.params.parentId];
					editModel.importOrgField.parentIds = [$scope.params.parentId];
					editModel.organizationField.modelIsReady = true;

					editModel.getParent();
				} else {
					var ids = _.map(ApplicationUserService.getOrganizations(), 'id');
					editModel.organizationField.defaultIds = [ids[0]];
					editModel.organizationField.parentIds = ids;
					editModel.importOrgField.defaultIds = [ids[0]];
					editModel.importOrgField.parentIds = ids;
					editModel.organizationField.modelIsReady = true;
					setFocus();
				}
			}
		}

		$scope.$watch('params', function(value) {
			if (value) {
				initialize();
			}
		});

		function importDragEnterHandler(e) {
			editModel.import.dropElement.addClass('active');
		}

		function importDragLeaveHandler(e) {
			editModel.import.dropElement.removeClass('active');
		}

		function importDropHandler(e) {
			editModel.import.dropElement.removeClass('active').addClass('thinking');
			editModel.import.importFileChanged(e);
		}

		function importFileChanged(e) {
			var file =
				e.originalEvent.currentTarget && e.originalEvent.currentTarget.files && e.originalEvent.currentTarget.files.length
					? e.originalEvent.currentTarget.files[0]
					: e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files && e.originalEvent.dataTransfer.files.length
					? e.originalEvent.dataTransfer.files[0]
					: false;
			if (file) {
				editModel.import.fileName = file.name;
				editModel.import.fileType = file.name.split('.').slice(-1)[0];
				IlFileParser.parseFile(file, editModel.import.fileType, onImportFileParseSuccess, onImportFileParseError);
				editModel.import.state = editModel.import.states.validateFile;
			}
		}

		function onImportFileParseSuccess(results) {
			var targetOrg = editModel.import.organizations[0];
			IlImport.validate(results.data, targetOrg, onImportValidateSuccess, onImportValidateError);
		}

		function onImportValidateSuccess(report) {
			editModel.import.dropElement.removeClass('thinking');
			editModel.import.state = editModel.import.states.validateFileSuccess;
			editModel.import.report = report;
		}

		function onImportValidateError(report) {
			editModel.import.dropElement.removeClass('thinking');
			editModel.import.state = editModel.import.states.validateFileFailed;
			editModel.import.report = report;
		}

		function onImportFileParseError(errors) {
			editModel.import.dropElement.removeClass('thinking');
		}

		function uploadFileData() {
			var now = $filter('date')(new Date(), 'MMM d, yyyy h:mm a');
			editModel.import.state = editModel.import.states.uploadData;
			editModel.import.bulk.status = editModel.import.bulk.statuses.processing;
			editModel.import.bulk.message = 'Processing file submitted by ' + editModel.currentUser.displayName + ' on ' + now;
			IlImport.import(uploadFileDataProgress, uploadFileDataSuccess, uploadFileDataError, uploadFileDataPartialSuccess);
		}

		function reset() {
			editModel.import.fileName = null;
			editModel.import.fileType = null;
			editModel.import.state = editModel.import.states.getFile;
			$('#importFile').val(null); // jQuery :) I know !!!
			IlImport.reset();
		}

		function uploadFileDataSuccess(response) {
			var now = $filter('date')(new Date(), 'MMM d, yyyy h:mm a');
			editModel.import.bulk.status = editModel.import.bulk.statuses.success;
			editModel.import.bulk.message = 'Success import by ' + editModel.currentUser.displayName + ' on ' + now;
		}

		function uploadFileDataError(response) {
			var now = $filter('date')(new Date(), 'MMM d, yyyy h:mm a');
			editModel.import.bulk.status = editModel.import.bulk.statuses.failed;
			editModel.import.bulk.message = 'Failure import by ' + editModel.currentUser.displayName + ' on ' + now;
		}

		function uploadFileDataPartialSuccess(response) {
			var now = $filter('date')(new Date(), 'MMM d, yyyy h:mm a');
			editModel.import.bulk.status = editModel.import.bulk.statuses.partialSuccess;
			editModel.import.bulk.message = 'Partially Successful import by ' + editModel.currentUser.displayName + ' on ' + now;
		}

		function uploadFileDataProgress(response) {
			editModel.import.progress.push(response);
		}

		function isValid() {
			var valid = !!(
				$scope.organizationModalForm &&
				$scope.organizationModalForm.organizationCreateForm &&
				$scope.organizationModalForm.organizationCreateForm.$valid
			);
			valid =
				editModel.modal.usageEnabled === true
					? !!(
							valid &&
							$scope.organizationModalForm.requiredUsageForm &&
							$scope.organizationModalForm.requiredUsageForm.$valid &&
							editModel.restrictStudentAccessTime.allowedDays.length
					  )
					: valid;
			valid =
				editModel.parentOrganizationVisible === true
					? !!(valid && editModel.organizations && editModel.organizations.length)
					: valid;
			return valid;
		}

		function setFocus() {
			$timeout(function() {
				var defaultField = angular.element('#defaultField');
				defaultField.focus();
			}, 0);
		}

		function toggleDay(day) {
			var selectedIndex = _.indexOf(editModel.restrictStudentAccessTime.allowedDays, day);
			if (selectedIndex !== -1) {
				editModel.restrictStudentAccessTime.allowedDays.splice(selectedIndex, 1);
			} else {
				editModel.restrictStudentAccessTime.allowedDays.push(day);
			}
			editModel.daysDirty = _.xor(
				editModel.restrictStudentAccessTime.allowedDays,
				editModel.backupRestrictStudentAccessTime.allowedDays
			).length
				? true
				: false;
		}

		function dayAbbr(day) {
			return day.substring(0, 3);
		}

		function daySelected(day) {
			return _.indexOf(editModel.restrictStudentAccessTime.allowedDays, day) !== -1;
		}

		function getSmallTime(date) {
			return $filter('date')(date, 'HH:mm');
		}

		function getTimeInDate(time) {
			var date = CurrentDate.get();
			var split = time.split(':');
			date.setHours(split[0]);
			date.setMinutes(split[1]);
			date.setSeconds(null);
			date.setMilliseconds(null);
			return date;
		}

		function getMediaServers(id) {
			if (!id || !id.length) {
				return;
			}

			Organization.siteCode(id)
				.then(function(result) {
					editModel.siteCode = result;
					return Organization.getSettings(id);
				})
				.then(function(result) {
					editModel.cachedSettings = result;
					if (editModel.siteCode) {
						return Organization.mediaServers(editModel.siteCode);
					}
					return $q.when();
				})
				.then(function(result) {
					angular.forEach(result, function(item) {
						item.displayText = item.machineName;
					});
					editModel.mediaServers = $filter('orderBy')(result, 'machineName');
					if (
						editModel.mediaServers &&
						editModel.mediaServers.length > 0 &&
						editModel.cachedSettings &&
						editModel.cachedSettings.mediaservers
					) {
						if (
							editModel.cachedSettings.mediaservers.length &&
							editModel.cachedSettings.mediaservers[0].ids &&
							editModel.cachedSettings.mediaservers[0].ids.length
						) {
							angular.forEach(editModel.mediaServers, function(server) {
								server.selected = _.indexOf(editModel.cachedSettings.mediaservers[0].ids, server.id) !== -1;
							});
							editModel.originalMediaServerIds = angular.copy(editModel.cachedSettings.mediaservers[0].ids);
						}
					}
				})
				['catch'](function(error) {
					if (error && error.status && error.status === 500) {
						//eat the forbidden error
						editModel.mediaServers = [];
					} else {
						NotificationFactory.error(error);
					}
				});
		}

		function editComplete() {
			if ($scope.params.callback) {
				$scope.params.callback();
			}

			if (editModel.saveAndCreate === false) {
				CommonModal.close();
			} else {
				editModel.entity.name = '';
				editModel.modal.tab = 'details';
				if ($scope.organizationModalForm && $scope.organizationModalForm.organizationCreateForm) {
					$scope.organizationModalForm.organizationCreateForm.$setPristine();
				}
				setFocus();
			}
		}

		function getEntity(entityId) {
			editModel.busy = true;
			Organization.get(entityId, { additionalFields: additionalFields })
				.then(function(result) {
					editModel.entity = result;
					editModel.originalEntity = { ...result };

					editModel.originalLexileSetting = result?.lexileSettings?.optIn;
					editModel.synced = isEntitySynced(editModel.entity);

					editModel.organizationField.defaultIds = [result.parentId];
					editModel.organizationField.parentIds = [result.parentId];
					editModel.importOrgField.parentIds = [result.parentId];
					editModel.importOrgField.defaultIds = [$scope.params.id];

					editModel.organizationField.modelIsReady = true;
					editModel.inheritSchoolYear = result.isSchoolYearInherited;

					//restrict usage
					if (editModel.entity.restrictStudentAccessTime) {
						editModel.modal.usageEnabled = true;

						editModel.restrictStudentAccessTime = editModel.entity.restrictStudentAccessTime;
						editModel.restrictStudentAccessTime.start = getTimeInDate(editModel.entity.restrictStudentAccessTime.start);
						editModel.restrictStudentAccessTime.end = getTimeInDate(editModel.entity.restrictStudentAccessTime.end);
						editModel.restrictStudentAccessTime.allowedDays = editModel.restrictStudentAccessTime.allowedDays || [];
					} else {
						editModel.modal.usageEnabled = false;
						editModel.restrictStudentAccessTime = {};
						editModel.restrictStudentAccessTime.start = getTimeInDate('8:00');
						editModel.restrictStudentAccessTime.end = getTimeInDate('15:00');
						editModel.restrictStudentAccessTime.timezone = DateUtils.getLocalTimeZone() || 'US/Pacific';
						editModel.restrictStudentAccessTime.allowedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
					}

					if (editModel.entity.siteCode && editModel.entity.siteCode.length) {
						editModel.entity.originalSiteCode = editModel.entity.siteCode;
					}

					//cache a copy for validation
					editModel.backupRestrictStudentAccessTime = angular.copy(editModel.restrictStudentAccessTime);
					editModel.parentOrganizationId = [editModel.entity.parentId];
					orgParentIds = [editModel.entity.parentId];
					//editModel.rootSiteCode = editModel.entity.siteCode || undefined;

					return DemographicsSettings.get(editModel.entity.id);
				})
				.then(function(settings) {
					editModel.showDemographics = settings.showDemographics;

					if (editModel.entity.siteCode && editModel.entity.siteCode.length) {
						return $q.when();
					} else {
						return Organization.siteCode(editModel.entity.id);
					}
				})
				.then(function(value) {
					if (value && value.length) {
						editModel.parentOrganizationSiteCode = value;
						editModel.entity.siteCode = value;
						editModel.entity.originalSiteCode = value;
					}
					if (editModel.entity.parentId && editModel.createMode) {
						return Organization.get(editModel.entity.parentId, { additionalFields: additionalFields });
					}
					return $q.when();
				})
				.then(function(result) {
					if (result) {
						editModel.parentOrganizationId = [result.id];
						orgParentIds = [result.id];
						editModel.parentOrganization = result;
						editModel.parentOrganizationName = result.name;
						editModel.entity.schoolYearSessions = !editModel.entity.schoolYearSessions
							? result.schoolYearSessions
							: editModel.entity.schoolYearSessions;
					} else {
						editModel.parentOrganizationVisible = false;
					}
				})
				.catch(function(error) {
					editModel.parentOrganizationVisible = false;
					if (error.status === 403 || error.status === 404) {
						//forbidden === the user's root org
						editModel.parentOrganizationEditable = false;
					} else {
						editModel.errorOverlay = NotificationFactory.error(error);
					}
				})
				.finally(function() {
					editModel.getMediaServers(editModel.entity.id);
					editModel.busy = false;
				});
		}

		function getParent() {
			editModel.busy = true;
			Organization.get($scope.params.parentId, { additionalFields: additionalFields })
				.then(function(result) {
					editModel.parentOrganizationName = result.name;

					// this should actually be done by api, if the user does not save these settings will never be propagated
					// editModel.lexileOption = result.lexileSettings.optIn === true ? lexileOptions.enabled : lexileOptions.disabled;
					// editModel.growthAssessmentOption = result.growthSettings !== undefined && result.growthSettings.optIn !== undefined && result.growthSettings.optIn === true ? lexileOptions.enabled : lexileOptions.disabled;
					editModel.entity.schoolYearSessions = !editModel.entity.schoolYearSessions
						? result.schoolYearSessions
						: editModel.entity.schoolYearSessions;
					editModel.inheritSchoolYear = true; // default inherit from parent
					editModel.parentOrganizationId = [result.id];
					orgParentIds = [result.id];
					editModel.parentOrganization = result;
					editModel.parentOrganizationEditable = false;
					editModel.getMediaServers(result.id);
					return Organization.siteCode(result.id);
				})
				.then(function(value) {
					editModel.parentOrganizationSiteCode = value;
					editModel.entity.siteCode = value;
					editModel.entity.originalSiteCode = value;
				})
				['catch'](function(error) {
					if (error && (error.status === 403 || error.status === 404)) {
						//forbidden === the user's root org
						editModel.parentOrganizationEditable = false;
						editModel.parentOrganizationVisible = false;
					} else {
						NotificationFactory.error(error);
					}
				})
				['finally'](function() {
					editModel.busy = false;
					setFocus();
				});
		}

		function saveEntity() {
			editModel.busy = true;
			editModel.entity.isSchoolYearInherited = editModel.inheritSchoolYear;
			editModel.entity.schoolYearSessions = editModel.inheritSchoolYear ? null : editModel.entity.schoolYearSessions;
			//if the user has enabled restricted usage we need to save that with the org
			if (editModel.modal.usageEnabled) {
				editModel.entity.restrictStudentAccessTime = {
					start: editModel.getSmallTime(editModel.restrictStudentAccessTime.start),
					end: editModel.getSmallTime(editModel.restrictStudentAccessTime.end),
					timezone: editModel.restrictStudentAccessTime.timezone,
					allowedDays: editModel.restrictStudentAccessTime.allowedDays
				};
			} else {
				//else we clear it out
				editModel.entity.restrictStudentAccessTime = null;
			}

			Organization.save(editModel.entity)
				.then(function(result) {
					NotificationFactory.success({
						heading: 'Organization' + $filter('translate')('management.edit.messages.createdHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.created'),
						closeAfter: 5,
						persist: true
					});

					//update selected media servers
					var mediaServerIds = _.map(
						_.reject(editModel.mediaServers, function(server) {
							return server.selected !== true;
						}),
						'id'
					);
					var payload = {
						mediaservers: [{ ids: mediaServerIds }]
					};
					return Organization.updateSettings(result.id, payload);
				})
				.then(function(result) {
					editModel.editComplete();
				})
				['catch'](function(error) {
					//duplicate site code error
					if (error.status === 409) {
						editModel.error = 'duplicate_site_code';
					}
					//invalid parent org error
					else if (error.status === 400) {
						if (
							(error.data && error.data.message && error.data.message.match(/Parent references self/g)) ||
							error.data.message.match(/Parent references a descendant/g)
						) {
							editModel.badParentOrgMsg = true;
							if ($scope.organizationModalForm && $scope.organizationModalForm.organizationEditForm) {
								$scope.organizationModalForm.organizationEditForm.parentOrganization.$setValidity('badParentOrg', true);
							}
						}
					}
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
				});
		}

		function updateEntity() {
			editModel.busy = true;

			//remove additional fields from submitted organization
			delete editModel.entity.organizations;
			delete editModel.entity.groups;
			delete editModel.entity.users;
			delete editModel.entity.students;
			delete editModel.entity.originalSiteCode;
			editModel.entity.isSchoolYearInherited = editModel.inheritSchoolYear;
			editModel.entity.schoolYearSessions = editModel.inheritSchoolYear ? null : editModel.entity.schoolYearSessions;
			//if the user has enabled restricted usage we need to save that with the org
			if (editModel.modal.usageEnabled) {
				editModel.entity.restrictStudentAccessTime = {
					start: editModel.getSmallTime(editModel.restrictStudentAccessTime.start),
					end: editModel.getSmallTime(editModel.restrictStudentAccessTime.end),
					timezone: editModel.restrictStudentAccessTime.timezone,
					allowedDays: editModel.restrictStudentAccessTime.allowedDays
				};
			} else {
				//else we clear it out
				editModel.entity.restrictStudentAccessTime = null;
			}

			Organization.update(editModel.entity)
				.then(function(result) {
					NotificationFactory.success({
						heading: 'Organization' + $filter('translate')('management.edit.messages.updatedHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.updated'),
						closeAfter: 5,
						persist: true
					});

					//update selected media servers
					var mediaServerIds = _.map(
						_.reject(editModel.mediaServers, function(server) {
							return server.selected !== true;
						}),
						'id'
					);
					var payload = {
						mediaservers: [{ ids: mediaServerIds }]
					};
					return Organization.updateSettings(editModel.entity.id, payload);
				})
				.then(function(result) {
					editModel.editComplete();
				})
				['catch'](function(error) {
					//duplicate site code error
					if (error.status === 409) {
						editModel.error = 'duplicate_site_code';
					}
					//invalid parent org error
					else if (error.status === 400) {
						if (
							(error.data && error.data.message && error.data.message.match(/Parent references self/g)) ||
							error.data.message.match(/Parent references a descendant/g)
						) {
							editModel.badParentOrgMsg = true;
							if ($scope.organizationModalForm && $scope.organizationModalForm.organizationEditForm) {
								$scope.organizationModalForm.organizationEditForm.parentOrganization.$setValidity('badParentOrg', true);
							}
						}
					}
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
				});
		}

		function save() {
			if (editModel.entity.siteCode === editModel.parentOrganizationSiteCode) {
				delete editModel.entity.siteCode;
			}

			editModel.entity.parentId =
				editModel.parentOrganizationId &&
				editModel.parentOrganizationId.length > 0 &&
				editModel.parentOrganizationId[0] !== editModel.entity.id
					? editModel.parentOrganizationId[0]
					: editModel.entity.parentId;

			if (angular.isUndefined(editModel.entity.parentId)) {
				editModel.entity.parentId = editModel.organizations[0].id;
			}

			if (editModel.createMode === true) {
				editModel.saveEntity();
			} else {
				editModel.updateEntity();
			}
		}

		function clearError() {
			if (
				$scope.organizationModalForm &&
				$scope.organizationModalForm.organizationEditForm &&
				$scope.organizationModalForm.organizationEditForm.parentOrganization
			) {
				$scope.organizationModalForm.organizationEditForm.parentOrganization.$setValidity('badParentOrg', true);
			}
		}

		$scope.$on('OrganizationAssessmentStatusRefresh', function() {
			if (editModel.entity && editModel.entity.id && editModel.entity.id.length) {
				editModel.getEntity(editModel.entity.id);
			}
		});

		$scope.$on('OrganizationSchoolYearRefresh', function() {
			if (editModel.entity && editModel.entity.id && editModel.entity.id.length) {
				editModel.getEntity(editModel.entity.id);
			}
		});

		function validateOrganizationName() {
			var nameIsValid = true;
			if (
				editModel.entity.name &&
				editModel.entity.name !== editModel.originalEntity.name &&
				editModel.parentOrganizationId &&
				editModel.parentOrganizationId.length > 0
			) {
				editModel.busy = true;
				Organization.children(editModel.parentOrganizationId[0])
					.then(function(result) {
						if (result && result.items && result.items.length) {
							nameIsValid = !result.items.some(function(item) {
								return item.name === editModel.entity.name;
							});
						}
					})
					.catch(function(error) {
						NotificationFactory.error(error);
					})
					.finally(function() {
						$scope.organizationModalForm.organizationCreateForm.name.$setValidity('duplicatedName', nameIsValid);
						editModel.busy = false;
					});
			} else {
				$scope.organizationModalForm.organizationCreateForm.name.$setValidity('duplicatedName', nameIsValid);
			}
		}
	}
})();
