(function() {
	angular.module('ildashboard.iladmins', [
		'jsonFormatter',
		'ui.router',
		'api.application.user',
		'ui.notifications',
		'api.manager',
		'api.reporting',
		'paged.list'
	]);
})();
