import { DefaultAssignedProducts } from '@imaginelearning/web-api-core';

(function() {
	angular
		.module('portal.manager.actions.create.group', [
			'api.manager',
			'api.application.user',
			'ui.notifications',
			'ui.modal.common',
			'ui.manager.dropdown',
			'ui.router',
			'utils.localization'
		])
		.controller('CreateGroupController', controller);

	controller.$inject = [
		'$scope',
		'$state',
		'NotificationFactory',
		'CommonModal',
		'Class',
		'Organization',
		'User',
		'ApplicationUserService',
		'UserRole',
		'getDisplayText',
		'isEntitySynced',
		'Types',
		'$q',
		'$filter',
		'$timeout'
	];

	function controller(
		$scope,
		$state,
		NotificationFactory,
		CommonModal,
		Class,
		Organization,
		User,
		ApplicationUserService,
		UserRole,
		getDisplayText,
		isEntitySynced,
		Types,
		$q,
		$filter,
		$timeout
	) {
		var editModel = this;

		editModel.editComplete = editComplete;
		editModel.getEntity = getEntity;
		editModel.saveEntity = saveEntity;
		editModel.updateEntity = updateEntity;
		editModel.save = save;
		editModel.getParent = getParent;
		editModel.isValid = isValid;
		editModel.printStudentLoginCard = printStudentLoginCard;

		editModel.busy = false;
		editModel.initialized = false;
		editModel.createMode = false;

		var userRootOrganization = ApplicationUserService.getRootOrganization(),
			orgParendIds = [
				angular.isDefined(userRootOrganization) && angular.isDefined(userRootOrganization.id) ? userRootOrganization.id : ''
			],
			clearModel = function() {
				editModel.synced = false;
				editModel.initialized = false;
				editModel.saveAndCreate = false;
				editModel.entity = {};
				editModel.entity.name = '';
				editModel.instructors = [];
				editModel.instructorsIds = [];
				editModel.instructorsEditable = true;

				editModel.userIsAnInstructor = false;
				editModel.userIsNotSelected = false;

				editModel.parentOrganization = [];
				editModel.parentOrganizationId = [];
				editModel.parentOrganizationName = '';

				editModel.parentOrganizationEditable = true;

				if ($scope.groupCreateForm) {
					$scope.groupCreateForm.$setPristine();
				}
			};
		clearModel();

		editModel.orgField = {
			required: true,
			disabled: false,
			default: {
				ids: editModel.parentOrganizationId
			},
			parent: {
				ids: [],
				list: []
			}
		};

		editModel.staffField = {
			required: false,
			disabled: true,
			default: {
				ids: editModel.instructorsIds
			},
			parent: {
				ids: editModel.parentOrganizationId,
				list: []
			}
		};

		$scope.$watch(
			function() {
				return editModel.parentOrganizationId;
			},
			function(n, o) {
				if (o !== n) {
					editModel.orgField.default.ids = n;
					editModel.staffField.parent.ids = n;
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return editModel.parentOrganization;
			},
			function(n, o) {
				if (n && n.length && o !== n) {
					editModel.staffField.disabled = false;
					editModel.staffField.default.ids = [];
					editModel.staffField.parent.list = [n[0]];
					editModel.staffField.parent.ids = [n[0].id];
					editModel.parentOrganizationId = [n[0].id];

					if ($scope.groupCreateForm) {
						$scope.groupCreateForm.$pristine = false;
					}
				}
			},
			true
		);

		$scope.$watch(
			function() {
				return editModel.instructorsIds;
			},
			function(n, o) {
				if (o !== n) {
					editModel.staffField.default.ids = n;
				}
			},
			true
		);

		$scope.$watchCollection(
			'editModel.instructors',
			function(value, oldValue) {
				if (!angular.equals(value, oldValue)) {
					editModel.userIsNotSelected = _.indexOf(_.map(value, 'id'), ApplicationUserService.getUser().id) === -1;
				}
			},
			true
		);

		function initialize() {
			clearModel();

			if ($scope.params.id && $scope.params.id.length) {
				editModel.createMode = false;
				editModel.getEntity($scope.params.id);
			} else {
				// if ($state.current.name === 'home.groups') {
				// 	editModel.parentOrganization = [userRootOrganization];
				// 	editModel.orgField.parent.list = [userRootOrganization];
				// }

				editModel.createMode = true;

				//user is a teacher and creating a group
				if (ApplicationUserService.getUserRole() === UserRole.teacher) {
					editModel.instructorsIds = [ApplicationUserService.getUser().id];
					editModel.staffField.required = true;
					// editModel.userIsAnInstructor = true;
				}

				if ($scope.params.parentId) {
					editModel.getParent();
				} else {
					setFocus();
					editModel.initialized = true;
					editModel.parentOrganization = [userRootOrganization];
					editModel.orgField.parent.list = [userRootOrganization];
				}
			}
		}

		function setFocus() {
			$timeout(function() {
				var defaultField = angular.element('#defaultField');
				defaultField.focus();
			}, 0);
		}

		$scope.$watchCollection(
			'params',
			function(value) {
				if (value) {
					initialize();
				}
			},
			true
		);

		function editComplete() {
			if ($scope.params.callback) {
				$scope.params.callback();
			}

			if (editModel.saveAndCreate === false) {
				CommonModal.close();
			} else {
				editModel.entity = {};
				if ($scope.groupCreateForm) {
					$scope.groupCreateForm.$setPristine();
				}
				setFocus();
			}
		}

		function getEntity(entityId) {
			editModel.busy = true;
			Class.get(entityId)
				.then(function(entity) {
					//"message": "Teacher does not have rights to modify Group:OrganizationId field"
					editModel.parentOrganizationEditable = ApplicationUserService.getUserRole() !== UserRole.teacher;

					editModel.entity = entity;
					editModel.synced = isEntitySynced(editModel.entity);

					if (editModel.entity.organizationId) {
						editModel.parentOrganizationId = [editModel.entity.organizationId];
						orgParendIds = [editModel.entity.organizationId];
						return Organization.get(editModel.entity.organizationId);
					}
					return $q.when();
				})
				.then(
					function(organization) {
						if (organization && organization.name) {
							editModel.parentOrganization = [organization];
							editModel.parentOrganizationName = organization.name;
							editModel.parentOrganizationEditable = false; //we have a parent - API does not allow changing parent
						}
						return Class.users(editModel.entity.id);
					},
					function(error) {
						if (error.status === 403) {
							//forbidden === the user's root org
							editModel.parentOrganizationEditable = false;
						} else if (error.status === 404) {
							//not found === the user has affected their permissions to this group during edit

							//let's tell them about it...
							NotificationFactory.success({
								heading: 'Student Group ' + $filter('translate')('management.edit.messages.removedHeader'),
								content:
									getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.removedFromGroup'),
								closeAfter: 5,
								persist: true
							});
						} else {
							NotificationFactory.error(error);
						}
					}
				)
				.then(function(users) {
					if (users && users.length) {
						editModel.instructorsIds = _.map(users, 'id');

						//user is a teacher and in the group being edited
						if (ApplicationUserService.getUserRole() === UserRole.teacher) {
							editModel.staffField.required = true;
							editModel.userIsAnInstructor = _.indexOf(editModel.instructorsIds, ApplicationUserService.getUser().id) !== -1;
						}
					}
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
					editModel.initialized = true;
				});
		}

		function saveEntity() {
			editModel.busy = true;

			var submittedGroup = angular.copy(editModel.entity);
			submittedGroup.userIds = editModel.instructorsIds;
			submittedGroup.assignedProducts = DefaultAssignedProducts;

			Class.save(submittedGroup)
				.then(function(result) {
					editModel.entity = result;
					editModel.entity.entityType = Types.group;
					return User.usersById(editModel.instructorsIds);
				})
				.then(function(result) {
					NotificationFactory.success({
						heading: 'Student Group ' + $filter('translate')('management.edit.messages.createdHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.created'),
						closeAfter: 5,
						persist: true
					});

					editModel.editComplete();
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
				});
		}

		function updateEntity() {
			editModel.busy = true;

			var submittedGroup = angular.copy(editModel.entity);
			submittedGroup.userIds = editModel.instructorsIds;

			Class.update(submittedGroup)
				.then(function(result) {
					editModel.entity = result;
					editModel.entity.entityType = Types.group;
					return User.usersById(editModel.instructorsIds);
				})
				.then(function(result) {
					NotificationFactory.success({
						heading: 'Student Group ' + $filter('translate')('management.edit.messages.updatedHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.updated'),
						closeAfter: 5,
						persist: true
					});

					editModel.editComplete();
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
				});
		}

		function isValid() {
			var valid = !!(
				editModel.entity &&
				editModel.entity.name &&
				editModel.entity.name.length > 0 &&
				editModel.parentOrganization &&
				editModel.parentOrganization.length > 0 &&
				(editModel.staffField.required ? editModel.instructors && editModel.instructors.length : true)
			);
			return valid;
		}

		function save() {
			if (editModel.isValid() === false) {
				return;
			}

			editModel.entity.organizationId =
				editModel.parentOrganizationId && editModel.parentOrganizationId.length > 0 ? editModel.parentOrganizationId[0] : undefined;
			editModel.instructorsIds = _.map(editModel.instructors, 'id');

			if (editModel.createMode === true) {
				editModel.saveEntity();
			} else {
				editModel.updateEntity();
			}
		}

		function getParent() {
			editModel.busy = true;
			Organization.get($scope.params.parentId)
				.then(function(result) {
					editModel.parentOrganization = [result];
					editModel.parentOrganizationName = result.name;
					editModel.parentOrganizationId = [result.id];
					orgParendIds = [result.id];
					editModel.parentOrganizationEditable = false; //we have a parent - API does not allow changing parent
					// editModel.orgField.default.ids = [];
					editModel.orgField.parent.ids = orgParendIds;
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
					editModel.initialized = true;
					setFocus();
				});
		}

		function printStudentLoginCard() {
			CommonModal.close();
			$state.go('manager.studentcard.group', { groupId: editModel.entity.id }, { notify: true });
		}
	}
})();
