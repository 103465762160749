(function() {
	angular.module('portal.manager.utils.demographics').service('DemographicsSettings', service);

	service.$inject = ['$q', 'Settings'];

	function service($q, Settings) {
		var service = this,
			response = {},
			orgId;

		service.get = get;

		function get(organizationId) {
			var defer = $q.defer();
			if (orgId === organizationId) {
				defer.resolve(response);
				return defer.promise;
			} else {
				orgId = organizationId;
				Settings.getPortalSettings(organizationId)
					.then(function(settings) {
						response.showDemographics = !!settings.showDemographics;
						return Settings.getSettings(organizationId);
					})
					.then(function(settings) {
						response.allowedDemographics = settings.allowedDemographics;
						defer.resolve(response);
					})
					.catch(function(error) {
						defer.reject(error);
					});
				return defer.promise;
			}
		}
	}
})();
