(function() {
	'use strict';

	angular.module('portal.legacyRouting', ['ui.router']).config(configure);

	configure.$inject = ['$stateProvider', '$windowProvider'];

	function configure($stateProvider, $windowProvider) {
		$stateProvider
			.state('actionAreas', {
				url: '/action-areas',
				onEnter: onEnter
			})
			.state('activities', {
				url: '/activities',
				onEnter: onEnter
			})
			.state('activities.playlists', {
				url: '/playlists',
				onEnter: onEnter
			})
			.state('activities.home', {
				url: '/home',
				onEnter: onEnter
			})
			.state('activities.search', {
				url: '/search?query',
				onEnter: onEnter
			})
			.state('import', {
				url: '/import?wizardReturnUrl',
				onEnter: onEnter
			})
			.state('espanol', {
				url: '/espanol',
				onEnter: onEnter
			})
			.state('login', {
				url: '/login',
				onEnter: onEnter
			});

		onEnter.$inject = ['$state', '$stateParams', '$window'];
		function onEnter($state, $stateParams, $window) {
			// jshint validthis: true
			var name = this.name, // `this` gives access to the state we're transitioning to
				url = $state.href(this.name, $stateParams, { absolute: true });
			url = url.replace(/~2F/g, '%2F'); //forward slashes get turned into weird ~2F's
			$window.location.replace(url);
		}
	}
})();
