(function() {
	angular.module('ildashboard.iladmins').config(config);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider.state('ildashboard.iladmins', {
			url: '/iladmins',
			controller: 'ILAdminsController',
			controllerAs: 'ctrl',
			templateUrl: 'ildashboard/iladmins/template.html'
		});
	}
})();
