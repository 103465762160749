(function() {
	angular
		.module('portal.manager.components.dropdown')
		.constant('ComponentsDropdownModes', {
			multiselect: 'multiselect',
			singleselect: 'singleselect'
		})
		.constant('ComponentsDropdownDataTypes', {
			group: 'group',
			organization: 'organization',
			user: 'user'
		});
})();
