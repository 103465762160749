module.exports = function(ngModule) {
    (function(window, document, angular, undefined) {
        if (window.location.search) {
            var match = window.location.search.match(/jsconsole=([0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9a-fA-F]{12})/i);
            if (match && match.length > 1) {
                var script = document.createElement('script');
                script.setAttribute('src', window.location.protocol + '//jsconsole.com/remote.js?' + match[1]);

                var body = document.getElementsByTagName('body')[0];
                body.appendChild(script);

                angular.module(ngModule).config([
                    'LogConfigProvider',
                    function(LogConfigProvider) {
                        LogConfigProvider.jsConsoleEnabled = true;
                    }
                ])
            }
        }
    })(window, window.document, window.angular);
};
