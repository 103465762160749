(function() {
	angular
		.module('portal.manager.actions.create.staff', [
			'api.manager',
			'api.application.user',
			'api.featureFlags',
			'ui.modal.common',
			'utils.localization'
		])
		.controller('CreateStaffController', controller);

	controller.$inject = [
		'$scope',
		'ApplicationUserService',
		'Organization',
		'User',
		'NotificationFactory',
		'getDisplayText',
		'isEntitySynced',
		'$filter',
		'$timeout',
		'CommonModal',
		'ComponentsDropdownDataTypes',
		'ComponentsDropdownModes',
		'FeatureFlagsService',
		'UsernameService'
	];

	function controller(
		$scope,
		ApplicationUserService,
		Organization,
		User,
		NotificationFactory,
		getDisplayText,
		isEntitySynced,
		$filter,
		$timeout,
		CommonModal,
		ComponentsDropdownDataTypes,
		ComponentsDropdownModes,
		FeatureFlagsService,
		UsernameService
	) {
		var editModel = this,
			userRootOrganization = ApplicationUserService.getRootOrganization(),
			orgParentIds = [
				angular.isDefined(userRootOrganization) && angular.isDefined(userRootOrganization.id) ? userRootOrganization.id : ''
			];

		editModel.userTypes = [
			{ name: 'Administrator', value: 'Admin' },
			{ name: 'Teacher', value: 'Teacher' }
		];

		editModel.showParentRole = false;
		FeatureFlagsService.evaluate('il-parent-role-internal-team').then(function(result) {
			editModel.showParentRole = !!result && result === 'show';
			if (!!editModel.showParentRole) {
				editModel.userTypes = [
					{ name: 'Administrator', value: 'Admin' },
					{ name: 'Teacher', value: 'Teacher' },
					{ name: 'Parent', value: 'Parent' }
				];
			}
		});

		editModel.languages = ['English', 'Spanish'];

		editModel.refreshUserName = refreshUserName;
		editModel.editComplete = editComplete;
		editModel.getEntity = getEntity;
		editModel.saveEntity = saveEntity;
		editModel.updateEntity = updateEntity;
		editModel.save = save;
		editModel.getParent = getParent;
		editModel.isValid = isValid;

		editModel.orgField = {
			parentIds: undefined, // tobe defined in init
			defaultIds: [],
			dataType: ComponentsDropdownDataTypes.organization,
			subType: undefined,
			includeParent: true,
			fieldIsReady: false,
			label: 'Organization',
			mode: ComponentsDropdownModes.multiselect,
			modelIsReady: false,
			disabled: false,
			required: true
		};

		editModel.grpField = {
			parentIds: undefined,
			defaultIds: undefined,
			dataType: ComponentsDropdownDataTypes.group,
			subType: undefined,
			includeParent: false,
			fieldIsReady: false,
			label: 'Group',
			mode: ComponentsDropdownModes.multiselect,
			modelIsReady: false,
			parentFieldIsReady: editModel.orgField.fieldIsReady,
			disabled: true,
			required: false
		};

		var clearModel = function() {
			editModel.busy = false;
			editModel.synced = false;
			editModel.createMode = false;
			editModel.saveAndCreate = false;
			editModel.selfEdit = false;
			editModel.originalGroupIds = [];

			editModel.entity = {};
			editModel.entity.username = '';
			editModel.organizations = [];
			editModel.organizationIds = [];
			editModel.groups = [];
			editModel.groupIds = [];
			editModel.parentOrganizationEditable = true;
		};
		clearModel();

		function initialize() {
			clearModel();

			editModel.orgField.parentIds = angular.isUndefined($scope.params.parentId) ? orgParentIds : [$scope.params.parentId];
			editModel.orgField.defaultIds = angular.isUndefined($scope.params.parentId) ? orgParentIds : [$scope.params.parentId];

			if ($scope.params.id && $scope.params.id.length > 0) {
				editModel.createMode = false;
				editModel.getEntity($scope.params.id);
			} else {
				editModel.createMode = true;
				editModel.orgField.modelIsReady = true;
				editModel.orgField.fieldIsReady = true;

				editModel.grpField.modelIsReady = true;
				editModel.grpField.fieldIsReady = true;

				if ($scope.params.parentId) {
					editModel.getParent();
				} else {
					editModel.organizations = [userRootOrganization];
					setFocus();
				}
			}
		}

		startWatchers();

		function startWatchers() {
			$scope.$watch(
				function() {
					return editModel.orgField.fieldIsReady;
				},
				_onOrganizationFieldReadyChange,
				true
			);
			$scope.$watch(
				function() {
					return editModel.organizations;
				},
				_onOrganizationsChange,
				true
			);
			$scope.$watchCollection('params', _onParamsChange, true);
		}

		function _onOrganizationFieldReadyChange(n, o) {
			if (o !== n) {
				if (n === true) {
					editModel.grpField.parentIds = _.map(editModel.organizations, 'id');
					editModel.grpField.disabled = false;
				} else {
					editModel.grpField.parentIds = undefined;
					editModel.grpField.disabled = true;
				}
				editModel.grpField.parentFieldIsReady = n;
			}
		}

		function _onOrganizationsChange(n, o) {
			if (o !== n) {
				editModel.organizationIds = _.map(editModel.organizations, 'id');
				editModel.entity.organizationIds = _.map(editModel.organizations, 'id');
				editModel.isValid();
			}
		}

		function _onParamsChange(n) {
			if (n) {
				initialize();
			}
		}

		function setFocus() {
			$timeout(function() {
				var defaultField = angular.element('#defaultField');
				defaultField.focus();
			}, 0);
		}

		function refreshUserName() {
			$scope.staffCreateForm.userName.$setValidity('unique', true);
			if (
				$scope.staffCreateForm &&
				$scope.staffCreateForm.userName.$viewValue &&
				$scope.staffCreateForm.userName.$viewValue !== editModel.originalUsername
			) {
				editModel.busy = true;
				UsernameService.checkUserAvailability($scope.staffCreateForm.userName.$viewValue)
					.then(function(result) {
						$scope.staffCreateForm.userName.$setValidity(
							'unique',
							result.usernames.indexOf($scope.staffCreateForm.userName.$viewValue.toLowerCase()) < 0
						);
					})
					.catch(function(error) {
						NotificationFactory.error(error);
					})
					.finally(function() {
						editModel.busy = false;
					});
			}
		}

		function editComplete() {
			if ($scope.params.callback) {
				$scope.params.callback();
			}

			if (editModel.saveAndCreate === false) {
				CommonModal.close();
			} else {
				editModel.entity.username = undefined;
				editModel.entity.firstName = undefined;
				editModel.entity.lastName = undefined;
				if ($scope.staffCreateForm) {
					$scope.staffCreateForm.$setPristine();
				}
				setFocus();
			}
		}

		function getEntity(entityId) {
			User.get(entityId)
				.then(function(result) {
					editModel.entity = result;
					editModel.synced = isEntitySynced(editModel.entity);
					editModel.selectedUserType = editModel.entity.userType === 'Admin' ? editModel.userTypes[0] : editModel.userTypes[1];

					editModel.orgField.defaultIds = result.organizationIds;
					editModel.grpField.defaultIds = result.groupIds;

					editModel.orgField.modelIsReady = true;
					editModel.grpField.modelIsReady = true;

					var orgNames = _.map(editModel.entity.organizations, 'name');
					editModel.organizationIds = _.map(editModel.entity.organizations, 'id');
					orgParentIds = _.map(editModel.entity.organizations, 'id');
					editModel.organizationNames = orgNames.join(', ');
					editModel.originalOrganizations = editModel.organizationIds;

					editModel.groupIds = _.map(editModel.entity.groups, 'id');
					editModel.originalGroupIds = editModel.groupIds;
					var groupNames = _.map(editModel.entity.groups, 'name');
					editModel.groupNames = groupNames.join(', ');

					editModel.originalUsername = editModel.entity.username;

					if (ApplicationUserService.getUser().id === editModel.entity.id) {
						editModel.selfEdit = true;
					}
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
				});
		}

		function saveEntity() {
			editModel.busy = true;
			var submittedUser = angular.copy(editModel.entity);
			delete submittedUser.groups;
			delete submittedUser.organizations;
			delete submittedUser.studentCount;

			User.save(submittedUser)
				.then(function(user) {
					NotificationFactory.success({
						heading:
							$filter('translate')('management.edit.messages.staffAccountHeader') +
							$filter('translate')('management.edit.messages.createdHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.created'),
						closeAfter: 5,
						persist: true
					});

					editModel.editComplete();
				})
				['catch'](function(error) {
					//a bad email made it past email validation and was caught by the api
					if (error.status === 400 && error.data.modelState['userDto.Username']) {
						if ($scope.staffCreateForm && $scope.staffCreateForm.userName) {
							$scope.staffCreateForm.userName.$setValidity('email', false);
						}
					}
					//username is already in use
					else if (error.status === 409 && error.data.message.indexOf('DuplicateKey') >= 0) {
						if ($scope.staffCreateForm && $scope.staffCreateForm.userName) {
							$scope.staffCreateForm.userName.$setValidity('unique', false);
						}
					} else {
						NotificationFactory.error(error);
					}
				})
				['finally'](function() {
					editModel.busy = false;
				});
		}

		function updateEntity() {
			editModel.busy = true;
			var submittedUser = angular.copy(editModel.entity);
			delete submittedUser.groups;
			delete submittedUser.organizations;
			delete submittedUser.studentCount;
			// delete submittedUser.password;

			User.update(submittedUser)
				.then(function() {
					NotificationFactory.success({
						heading:
							$filter('translate')('management.edit.messages.staffAccountHeader') +
							$filter('translate')('management.edit.messages.updatedHeader'),
						content: getDisplayText(editModel.entity) + $filter('translate')('management.edit.messages.updated'),
						closeAfter: 5,
						persist: true
					});

					editModel.editComplete();
				})
				['catch'](function(error) {
					//a bad email made it past email validation and was caught by the api
					if (error.status === 400 && error.data.modelState && error.data.modelState['userDto.Username']) {
						if ($scope.staffCreateForm && $scope.staffCreateForm.userName) {
							$scope.staffCreateForm.userName.$setValidity('email', false);
						}
					} else if (error.status === 409 && error.data.message.indexOf('DuplicateKey') >= 0) {
						//username is already in use
						if ($scope.staffCreateForm && $scope.staffCreateForm.userName) {
							$scope.staffCreateForm.userName.$setValidity('unique', false);
						}
					} else {
						NotificationFactory.error(error);
					}
				})
				['finally'](function() {
					editModel.busy = false;
				});
		}

		function isValid() {
			var valid = !!(
				editModel.entity &&
				editModel.entity.userType &&
				editModel.entity.userType.length > 0 &&
				editModel.entity &&
				editModel.entity.firstName &&
				editModel.entity.firstName.length > 0 &&
				editModel.entity &&
				editModel.entity.lastName &&
				editModel.entity.lastName.length > 0 &&
				editModel.entity &&
				editModel.entity.username &&
				editModel.entity.username.length > 0 &&
				editModel.entity &&
				editModel.entity.userType &&
				editModel.entity.userType.length > 0 &&
				editModel.organizationIds &&
				editModel.organizationIds.length > 0
			);

			if (editModel.createMode === true) {
				valid = valid && !!(editModel.entity.password && editModel.entity.password.length > 0);
				return valid;
			} else {
				return valid;
			}
		}

		function save() {
			if (editModel.isValid() === false) {
				return;
			}

			editModel.groupIds = _.map(editModel.groups, 'id');
			editModel.entity.groupIds = _.uniq(editModel.groupIds);
			editModel.entity.organizationIds = _.uniq(editModel.organizationIds);

			if (editModel.createMode === true) {
				editModel.saveEntity();
			} else {
				editModel.updateEntity();
			}
		}

		function getParent() {
			editModel.busy = true;
			Organization.get($scope.params.parentId)
				.then(function(result) {
					editModel.organizationIds.push(result.id);
					editModel.organizationNames = result.name;
					editModel.parentOrganizationEditable = false;
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				})
				['finally'](function() {
					editModel.busy = false;
					setFocus();
				});
		}
	}
})();
