(function() {
	angular.module('ildashboard', [
		'ui.router',
		'ui.notifications',
		'ui.directives.selectBlur',
		'utils.date',
		'utils.localization',
		'utils.gradeLevel',
		'api.application.user',
		'api.manager',
		'common.localization',
		'ildashboard.audit.history',
		'ildashboard.iladmins',
		'reporting.partnership'
	]);
})();
