(function() {
	angular.module('portal.manager.components.dropdown').config(config);

	config.$inject = ['LocalizeProvider'];

	function config(LocalizeProvider) {
		LocalizeProvider.translations({
			en: {
				dropdown: {
					organization: {
						label: {
							multiselect: 'Parent Organization',
							singleselect: 'Parent Organization'
						},
						helpertext: ''
					},
					user: {
						label: {
							multiselect: 'Staff',
							singleselect: 'Staff'
						},
						helpertext: 'Please select an organization'
					},
					group: {
						label: {
							multiselect: 'Groups',
							singleselect: 'Group'
						},
						helpertext: 'Please select an organization'
					},
					copy: {
						load: {
							loadmore: 'load more',
							loading: 'loading ...'
						}
					}
				}
			}
		});
	}
})();
