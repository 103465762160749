(function() {
	angular
		.module('portal.manager.staff', [
			'ui.router',
			'portal.manager.navigation',
			'portal.manager.paginator',
			'angular.filter',
			'api.manager',
			'api.application.user',
			'ui.notifications'
		])
		.config(config)
		.controller('ManagerStaffController', controller);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('manager.staff', {
				controller: controller,
				controllerAs: 'model',
				url: '/staff',
				abstract: true,
				templateUrl: 'management/staff/staff.layout.tpl.html'
			})
			.state('manager.staff.organizations', {
				url: '/:id/organizations',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_organizations" type="organization"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_organizations" type="organization"></section>'
					}
				},
				data: { displayName: 'Management/Staff/Organizations' }
			})
			.state('manager.staff.groups', {
				url: '/:id/groups',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_groups" type="group"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_groups" type="group"></section>'
					}
				},
				data: { displayName: 'Management/Staff/Groups' }
			})
			.state('manager.staff.students', {
				url: '/:id/students',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_students" type="student"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_students" type="student"></section>'
					}
				},
				data: { displayName: 'Management/Staff/Students' }
			});
	}

	controller.$inject = [
		'$scope',
		'$state',
		'Paginator',
		'NotificationFactory',
		'ApplicationUserService',
		'UserRole',
		'Navigation',
		'Organization',
		'Class',
		'User',
		'Student',
		'$filter',
		'$q',
		'$timeout',
		'Types'
	];

	function controller(
		$scope,
		$state,
		Paginator,
		NotificationFactory,
		ApplicationUserService,
		UserRole,
		Navigation,
		Organization,
		Class,
		User,
		Student,
		$filter,
		$q,
		$timeout,
		Types
	) {
		var model = this;
		model.staff = undefined;
		model.id = undefined;
		model.state = undefined;
		model.stateChanged = stateChanged;

		var organizations = new Paginator(
			$scope,
			'paginator_organizations',
			function() {
				return User.organizations(model.id, organizations.params);
			},
			model.id
		);
		organizations.params = {
			additionalFields: ['lexileSettings', 'fullOrganizationCount', 'fullGroupCount', 'fullUserCount', 'fullStudentCount'],
			sortby: 'name'
		};

		var groups = new Paginator(
			$scope,
			'paginator_groups',
			function() {
				return User.groups(model.id, groups.params);
			},
			model.id
		);
		groups.params = {
			additionalFields: ['studentCount', 'users'],
			sortby: 'name'
		};

		var students = new Paginator(
			$scope,
			'paginator_students',
			function() {
				return User.students(model.id, students.params);
			},
			model.id
		);
		students.params = {
			sortby: 'lastName,firstName',
			additionalFields: ['lexileSettings']
		};

		function getStaff(id) {
			if (!id) {
				NotificationFactory.error({ message: 'Invalid id' });
			}

			model.id = id;
			if (model.staff && model.staff.id === id) {
				return;
			}

			User.get(id)
				.then(function(result) {
					model.staff = result;
					model.staff.displayText = result.lastName + ', ' + result.firstName;
					model.staff.entityType = Types.staff;

					Navigation.fetchBreadcrumbs(model.staff);
				})
				['catch'](function(error) {
					NotificationFactory.error(error);
				});
		}

		function stateChanged(event, toState, toParams, fromState, fromParams) {
			if (toState.name.indexOf('manager.staff') !== -1) {
				model.state = toState.name;
				getStaff(toParams.id);

				switch (model.state) {
					case 'manager.staff.organizations':
						$timeout(function() {
							$scope.paginator_organizations.doInitialLoad();
						});

						break;

					case 'manager.staff.groups':
						$timeout(function() {
							$scope.paginator_groups.doInitialLoad();
						});
						break;

					case 'manager.staff.students':
						$timeout(function() {
							$scope.paginator_students.doInitialLoad();
						});
						break;
				}
			}
		}

		$scope.$on('$stateChangeSuccess', stateChanged);
	}
})();
