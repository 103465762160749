(function() {
	angular.module('portal.manager.components.dropdown').factory('DropdownFactory', factory);

	factory.$inject = ['Organization', 'User', 'Class'];

	function factory(Organization, User, Class) {
		var factory = this;

		factory.organizations = {};
		factory.user = {};
		factory.group = {};

		factory.organizations.all = getOrganizations;
		factory.organizations.get = getOrganization;
		factory.organizations.children = getOrganizationChildren;
		factory.organizations.schools = getOrganizationSchools;
		factory.organizations.groups = getOrganizationGroups;
		factory.organizations.user = getOrganizationUser;

		factory.user.get = getUser;

		factory.group.get = getGroup;
		factory.group.add = addGroup;

		function getOrganizations(passedParams) {
			var params = { ...passedParams, includeDescendants: true, limit: passedParams?.limit > 100 ? passedParams?.limit : 100 };
			return Organization.query(params);
		}

		function getOrganization(organizationId, passedParams) {
			return Organization.get(organizationId, passedParams);
		}

		function getOrganizationChildren(parentId, passedParams) {
			var params = passedParams;
			params.includeDescendants = true;
			return Organization.children(parentId, params);
		}

		function getOrganizationSchools(organizationId, passedParams) {
			var params = passedParams;
			params.includeDescendants = false;
			// params.organizationType = vm.config.subDataType;
			return Organization.organizationList(organizationId, params);
		}

		function getOrganizationGroups(organizationId, passedParams) {
			var params = passedParams;
			params.includeDescendants = false;
			return Organization.classes(organizationId, params);
		}

		function getOrganizationUser(organizationId, passedParams) {
			var params = passedParams;
			params.includeDescendants = false;
			return Organization.userList(organizationId, params);
		}

		function getUser(userId) {
			return User.get(userId);
		}

		function getGroup(groupId) {
			return Class.get(groupId);
		}

		function addGroup(newGroup) {
			return Class.save(newGroup);
		}

		return factory;
	}
})();
