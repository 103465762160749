(function() {
	angular.module('portal.manager.navigation.directive', ['portal.manager.navigation']).directive('ilManagerNavigation', directive);

	function directive() {
		return {
			restrict: 'EA',
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'management/navigation/navigation.tpl.html',
			replace: true,
			link: function(scope, element, attrs) {}
		};
	}

	controller.$inject = ['Navigation'];

	function controller(Navigation) {
		var model = this;
		model.navigation = Navigation;
	}
})();
