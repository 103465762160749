(function() {
	angular.module('portal.manager.create.entity.factory', ['ui.modal.custom']).factory('ManagerCreateEntityFactory', factory);

	factory.$inject = ['CustomModal'];

	function factory(CustomModal) {
		var createFactory = {};
		createFactory.create = create;
		createFactory.edit = edit;

		function create(itemType, parentId, callback) {
			var templateUrl = 'management/modals/create/create.' + itemType + '.tpl.html';
			CustomModal.show(templateUrl, { mode: 'create', parentId: parentId, callback: callback });
		}

		function edit(itemType, id, callback) {
			var templateUrl = 'management/modals/create/create.' + itemType + '.tpl.html';
			CustomModal.show(templateUrl, { mode: 'edit', id: id, callback: callback });
		}

		return createFactory;
	}
})();
