(function() {
	angular
		.module('portal.manager.student', [
			'ui.router',
			'portal.manager.navigation',
			'portal.manager.paginator',
			'angular.filter',
			'api.manager',
			'api.application.user',
			'ui.notifications'
		])
		.config(config)
		.controller('ManagerStudentController', controller);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('manager.student', {
				controller: controller,
				controllerAs: 'model',
				url: '/student',
				abstract: true,
				templateUrl: 'management/student/student.layout.tpl.html'
			})
			.state('manager.student.organizations', {
				url: '/:id/organizations',
				views: {
					actions: {
						template:
							'<section il-manager-actions="paginator_organizations" type="organization" data-organization-id="model.student.organizationId"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_organizations" type="organization"></section>'
					}
				},
				data: { displayName: 'Management/Student/Organizations' }
			})
			.state('manager.student.groups', {
				url: '/:id/groups',
				views: {
					actions: {
						template:
							'<section il-manager-actions="paginator_groups" type="group" data-organization-id="model.student.organizationId"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_groups" type="group"></section>'
					}
				},
				data: { displayName: 'Management/Student/Groups' }
			});
	}

	controller.$inject = ['$scope', '$state', 'Paginator', 'NotificationFactory', 'Navigation', 'Student', '$timeout', 'Types'];

	function controller($scope, $state, Paginator, NotificationFactory, Navigation, Student, $timeout, Types) {
		var model = this;
		model.student = undefined;
		model.id = undefined;
		model.state = undefined;
		model.stateChanged = stateChanged;

		var organizations = new Paginator(
			$scope,
			'paginator_organizations',
			function() {
				return Student.organizations(model.id, organizations.params);
			},
			model.id
		);
		organizations.params = {
			additionalFields: ['lexileSettings', 'fullOrganizationCount', 'fullGroupCount', 'fullUserCount', 'fullStudentCount'],
			sortby: 'name'
		};

		var groups = new Paginator(
			$scope,
			'paginator_groups',
			function() {
				return Student.groups(model.id, groups.params);
			},
			model.id
		);
		groups.params = {
			additionalFields: ['studentCount', 'users'],
			sortby: 'name'
		};

		function getStudent(id) {
			if (!id) {
				NotificationFactory.error({ message: 'Invalid id' });
			}

			model.id = id;
			if (model.student && model.student.id === id) {
				return;
			}

			Student.get(id)
				.then(function(result) {
					model.student = result;
					model.student.organizationId = result.organizationIds[0];
					model.student.displayText = result.lastName + ', ' + result.firstName;
					model.student.entityType = Types.student;

					Navigation.fetchBreadcrumbs(model.student);
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		function stateChanged(event, toState, toParams, fromState, fromParams) {
			if (toState.name.indexOf('manager.student') !== -1) {
				model.state = toState.name;
				getStudent(toParams.id);

				switch (model.state) {
					case 'manager.student.organizations':
						$timeout(function() {
							$scope.paginator_organizations.doInitialLoad();
						});

						break;

					case 'manager.student.groups':
						$timeout(function() {
							$scope.paginator_groups.doInitialLoad();
						});
						break;
				}
			}
		}

		$scope.$on('$stateChangeSuccess', stateChanged);
	}
})();
