(function() {
	var root = this;
	var translations = {
		en: {
			management: {
				restrictusage: {
					enableText: 'Enable Custom Usage Rules',
					studentUsage: 'Student Usage',
					startTime: 'Start Time',
					endTime: 'End Time',
					timeZone: 'Time zone',
					selectTimeZone: 'Select a time zone',
					days: 'Days',
					edit: 'Edit',
					remove: 'Remove',
					save: 'Save',
					cancel: 'Cancel',
					requiredField: 'Required field',
					daysRequired: 'At least one day must be selected'
				},
				terms: {
					enabled: 'Enabled',
					disabled: 'Disabled',
					unchanged: 'Unchanged',
					teacher: 'Teacher',
					teachers: 'Teachers',
					administrator: 'Administrator',
					administrators: 'Administrators',
					staff: 'Staff',
					staffMember: 'Staff Member',
					student: 'Student',
					students: 'Students',
					parentOrganization: 'Parent Organization',
					organization: 'Organization',
					organizations: 'Organizations',
					school: 'School',
					schools: 'Schools',
					group: 'Group',
					groups: 'Groups'
				},
				tips: {
					impersonate: 'Assist Mode',
					details: 'Details',
					settings: 'Settings',
					delete: 'Delete',
					search: 'Use this to search through all organizations, groups, students and staff.'
				},
				fields: {
					name: 'Name',
					firstName: 'First Name',
					lastName: 'Last Name',
					userName: 'Username',
					email: 'Email',
					userType: 'User Type',
					password: 'Password',
					passwordConfirm: 'Confirm Password',
					parentOrganization: 'Parent Organization',
					organizationType: 'Organization Type',
					type: 'Type',
					gradeLevel: 'Grade Level'
				},
				tabs: {
					organizations: 'Organizations',
					org: 'Org',
					schools: 'Schools',
					groups: 'Groups',
					staff: 'Staff',
					students: 'Students'
				},
				reports: {
					generatePartnership: 'Generate a Partnership Report for %s',
					generateActivityBrief: 'Generate a School Gains Summary Report for %s'
				},
				actions: {
					reports: 'Reports',
					bulkEdit: 'Bulk Edit',
					createNew: 'Create a new ',
					addNew: 'Add a new ',
					editSelected: 'Editing the selected ',
					deleteSelected: 'Delete the selected ',
					addToGroups: 'Add the selected',
					edit: 'Edit',
					delete: 'Delete',
					remove: 'Remove from group'
				},
				table: {
					noArtifacts: 'No artifacts have been associated.',
					noOrganizations: 'No organizations have been associated.',
					noGroups: 'No groups have been associated.',
					noResults: 'No results found.',
					noStaff: 'No staff members have been associated.',
					noStudents: 'No students have been associated.',
					selectedOrganizations: 'Selected Organizations',
					selectedGroups: 'Selected Groups',
					selectedStaff: 'Selected Staff',
					selectedStudents: 'Selected Students'
				},
				placeholders: {
					artifact: 'Find an artifact',
					organization: 'Find an organization',
					group: 'Find a group',
					staff: 'Find a staff member',
					student: 'Find a student',
					search: 'Find in Results'
				},
				messages: {
					warning: 'Warning',
					syncedRecordHeader: 'Synced Record',
					syncedRecord: 'This record is synced with scheduled importing. Restricted editing available.',
					syncedFieldHeader: 'Synced Property',
					syncedField: 'This property is synced with a scheduled import and cannot be modified.',
					deleteSyncedEntity: 'This record is synced with a scheduled import and cannot be deleted.',
					noMediaServers: 'No Media Servers have been configured for this organization.',
					deleteOrganization:
						'This organization cannot be deleted because it is associated with one or more organizations, groups, users, or students. Remove these associations first before deleting the organization.',
					deleteUser:
						'This staff account cannot be deleted because it is associated with one or more student groups. Remove this staff account from their assigned group(s), then delete the staff account.',
					deleteGroup: 'Groups cannot be deleted until all Students have been removed.'
				},

				deletemodal: {
					deleted: ' has been deleted.',
					organization: {
						header: 'WARNING - Delete Organization: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete this organization?',
						deleted: 'Organization has been Deleted.'
					},
					group: {
						header: 'WARNING - Delete Group: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete this student group?',
						deleted: 'Student Group has been Deleted.'
					},
					student: {
						header: 'WARNING - Delete Student Account: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete this student account?',
						deleted: 'Student Account has been Deleted.'
					},
					staff: {
						header: 'WARNING - Delete Staff Account: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete this staff account?',
						deleted: 'Staff Account has been Deleted.'
					}
				},

				removefromgroup: {
					removed: ' has been removed from the group.',
					student: {
						header: 'Remove student: ',
						content: 'Are you sure you want to remove this student from the group?',
						deleted: 'Student removed'
					},
					staff: {
						header: 'Remove staff member: ',
						content: 'Are you sure you want to remove this staff member from the group?',
						deleted: 'Staff member removed'
					}
				},

				edit: {
					disabledTest: 'Editing this feature is not available while creating an organization.',
					error: {
						min: 'May not be less than %s.',
						max: 'May not be greater than %s.',
						minLength: 'Must be at least %s characters.',
						maxLength: 'Max Limit Reached (%s)',
						required: 'Required field.',
						email: 'Please enter a valid email address.',
						emailConflict: 'This email already exists.',
						studentIdConflict: 'This student id is already in use.',
						userNameConflict: 'This username already exists.',
						passwordMismatch: 'Password does not match.',
						siteCode: 'Site code entered is already in use.',
						teacherGroupNoTeacherWarning: 'Please select a staff member to associate with this group.',
						teacherRemoveFromGroupWarning:
							'Removing yourself as an associated staff member of this group will remove it from your list of groups as well.',
						teacherRemoveStudentFromGroupsWarning:
							'Removing this student from all groups will remove them from your list of students as well.',
						wholeNumber: 'Must be a whole number.',
						usernameSiteCodes: 'This username exists in the sitecodes: %s',
						studentIdSiteCodes: 'This student id exists in the sitecodes: %s',
						duplicatedName: 'Organization name already in use'
					},
					labels: {
						startDate: 'Start Date',
						endDate: 'End Date',
						schoolYear: 'School Year',
						printLoginCards: 'Print Login Cards',
						details: 'Details',
						demographics: 'Demographics',
						usage: 'Usage Rules',
						schedule: 'Import Schedule',
						assessment: 'Assessments',
						test: 'Student Testing',
						bulkCreateStudents: 'Bulk Create Students',
						selectASchool: 'Select a School',
						exportStudentData: 'Export Student Data',
						media: 'Media Servers',
						organizationDetails: 'Organization Details',
						organizationDetailsSettings: 'Organization Details and Settings',
						organizationSettings: 'Organization Settings',
						groupDetails: 'Group Details',
						staffDetails: 'Staff Details',
						studentDetails: 'Student Details',
						create: 'Create',
						createOrganization: 'Create Organization',
						createSchool: 'Create School',
						createGroup: 'Create Group',
						createStaff: 'Create Staff',
						createStudent: 'Create Student',
						addStaff: 'Add Staff',
						addStudent: 'Add Student',
						features: 'Features',
						sessions: 'Sessions',
						editOrganization: 'Edit organization'
					},
					messages: {
						enableLexile: 'Enable Reading Level Assessment',
						lexileCascadeWarning: 'Changing this setting will affect all students in the organization.',
						staffAccountHeader: 'Staff Account',
						studentAccountHeader: 'Student Account',
						createdHeader: ' Created.',
						updatedHeader: ' Updated.',
						deletedHeader: ' Deleted.',
						removedHeader: ' Removed.',
						created: ' has been created.',
						updated: ' has been updated.',
						updatedPlural: ' have been updated.',
						deleted: ' has been deleted.',
						removedFromGroup: ' has been removed from your group list.'
					},
					fieldNames: {
						enableDemographics: 'Enable Demographics',
						firstName: 'First Name',
						lastName: 'Last Name',
						email: 'Email',
						userName: 'Username',
						userType: 'User Type',
						password: 'Password',
						confirmPassword: 'Confirm Password',
						firstLanguage: 'First Language',
						sessionTime: 'Session Time',
						gradeLevel: 'Grade Level',
						studentId: 'Student ID',
						alternateStudentNumber: 'Alternate ID',
						school: 'School',
						schools: 'Schools',
						parentOrganization: 'Parent Organization',
						organization: 'Organization',
						organizations: 'Organizations',
						student: 'Student',
						students: 'Students',
						group: 'Group',
						groups: 'Groups',
						staff: 'Staff',
						language: 'Language',
						siteCode: 'Site Code',
						mediaServer: 'Media Server',
						name: 'Name',
						type: 'Type'
					}
				},

				bulkDelete: {
					organization: {
						header: 'WARNING - Delete Organizations: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete the %s selected organizations?',
						deleted: '%s organizations have been deleted'
					},
					group: {
						header: 'WARNING - Delete Groups: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete the %s selected student groups?',
						deleted: '%s groups have been deleted'
					},
					student: {
						header: 'WARNING - Delete Student Accounts: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete the %s selected student accounts?',
						deleted: '%s student accounts have been deleted'
					},
					staff: {
						header: 'WARNING - Delete Staff Account: ',
						content: 'You CANNOT undo this action. Are you sure you want to delete the %s selected staff accounts?',
						deleted: '%s staff accounts have been deleted'
					}
				},

				bulkEdit: {
					student: {
						header: 'Student Accounts Updated: ',
						content: '%s student accounts have been updated'
					},
					staff: {
						header: 'Staff Accounts Updated: ',
						content: '%s staff accounts have been updated'
					}
				},

				bulkRemoveFromGroup: {
					student: {
						header: 'Remove Student Accounts from Group',
						content: 'Are you sure you want to remove the %s selected student accounts from this group?'
					}
				},

				manualPlacement: {
					labels: {
						BeginningReader: 'Beginning Reader',
						BelowGrade: 'Below Grade Level',
						OnGrade: 'On Grade Level',
						AboveGrade: 'Above Grade Level'
					},
					success: {
						header: 'Student placed succesfully',
						message: 'Student has been placed at: '
					},
					error: {
						message: 'There was an error while placing the student: '
					},
					header: 'Manual Student Placement:',
					studentPlacement: 'Placement Levels',
					warning:
						'<i class="icon icon-warning"></i> Warning! Manual placement bypasses the testing and automatic placement for this student. <strong>This action cannot be undone.</strong>'
				}
			}
		}
	};

	if (root.angular) {
		angular.module('portal.manager').config([
			'LocalizeProvider',
			function(LocalizeProvider) {
				LocalizeProvider.translations(translations);
			}
		]);
	} else if (typeof module !== 'undefined' && module.exports) {
		// Node.js
		module.exports = translations;
	}
})();
