(function() {
	angular.module('portal.manager.paginator.controls', []).directive('ilPaginatorControls', directive);

	function directive() {
		return {
			restrict: 'EA',
			templateUrl: 'management/pagination/paginator.controls.tpl.html',
			replace: true,
			scope: {
				paginator: '=ilPaginatorControls'
			}
		};
	}
})();
