(function() {
	angular.module('dashboards', [
		'dashboard.download',
		'dashboard.search',
		'dashboard.organization',
		'dashboard.group',
		'dashboard.user',
		'dashboard.student',
		'ui.show.hide',
		'il.web',
		// new growth report
		'dashboard.growth'
	]);
})();
