(function() {
	angular.module('ildashboard.audit.history').config(config);

	config.$inject = ['$stateProvider'];

	function config($stateProvider) {
		$stateProvider.state('ildashboard.auditHistory', {
			url: '/audit_history',
			controller: 'AuditHistoryController',
			controllerAs: 'ctrl',
			templateUrl: 'ildashboard/audit_history/template.html'
		});
	}
})();
