(function() {
	angular
		.module('dashboard', [
			'ngEqualizer',
			'ui.router',
			'ui.popup',
			'ui.directives.selectBlur',
			'utils.date',
			'utils.localization',
			'utils.gradeLevel',
			'api.application.user',
			'api.analytics',
			'common.localization',
			'dashboards',
			'il.web.features',
			'il.web.components',
			'dashboard.filter.stateDisplayName'
		])
		.controller('DashboardController', controller)
		.config(config);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('dashboardRoot', {
				controller: controller,
				controllerAs: 'model',
				parent: 'root.layout',
				templateUrl: 'dashboards/dashboard.html',
				abstract: true
			})
			.state('dashboard', {
				parent: 'dashboardRoot',
				url: 'dashboard',
				abstract: true,
				views: {
					'': {
						template: '<div ui-view></div>'
					}
				}
			});

		// $urlRouterProvider.when('/dashboard', ['$state', 'ApplicationUserService', 'UserRole', function ($state, ApplicationUserService, UserRole) {
		// 	var route = ApplicationUserService.getUserRole() === UserRole.teacher ? 'dashboard.user' : 'dashboard.organization';
		// 	$state.go(route, {id: $state.params && $state.params.id ? $state.params.id : undefined}, {notify: true, location: 'replace'});
		// }]);

		$urlRouterProvider.when('/dashboard', [
			'$state',
			function($state) {
				$state.go('dashboard.user', {}, { notify: true, location: 'replace' });
			}
		]);
	}

	controller.$inject = ['$scope', 'EqualizerState'];

	function controller($scope, EqualizerState) {
		// $scope.$emit('equalize');
		// emit this event from a KPI when loaded to trigger its group/row equalization.
		// band-aid fix: we will need to roll our own or use Foundation, but for now we don't look 'disheveled' ;)
		$scope.$on('equalize', function(event, params) {
			EqualizerState.request();
		});
	}
})();
