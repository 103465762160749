(function(undefined) {
	'use strict';

	angular.module('dashboard.filter.stateDisplayName', ['utils.localization']).filter('stateDisplayName', stateDisplayName);

	stateDisplayName.$inject = ['Localize'];
	function stateDisplayName(Localize) {
		return stateDisplayNameFilter;

		////////////////

		function getBaseState(stateName) {
			var match = stateName.match(
				/dashboard\.(?:organization|group|grade|student|user)\.(unassigned|overview|usage|progress|growth|assessments|portfolio|studentTesting).*/i
			);
			if (match) {
				return match.slice(1, 2).shift(); // Take second item in array
			} else {
				match = stateName.match(
					/espanol\.(?:organization|group|grade|student|user)\.(unassigned|overview|usage|progress|growth|assessments|portfolio|studentTesting).*/i
				);
				if (match) {
					return match.slice(1, 2).shift(); // Take second item in array
				}
			}
			return undefined;
		}

		function stateDisplayNameFilter(input) {
			var base = getBaseState(input);
			if (base) {
				// Special case for 'assessments' and 'overview'
				if (base === 'assessments') {
					base = 'growth';
				} else if (base === 'overview') {
					base = 'dashboard';
				}
				return Localize.translateInstant('terms_common.' + base);
			}
			return input;
		}
	}
})();
