(function() {
	angular.module('portal.manager.paginator.clear.directive', []).directive('ilManagerPaginatorClear', directive);

	directive.$inject = ['$rootScope'];
	function directive($rootScope) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				function clearPaginator() {
					$rootScope.$broadcast('paginator.clear');
				}

				element.bind('click', clearPaginator);

				element.on('$destroy', function() {
					element.off('click', clearPaginator);
				});
			}
		};
	}
})();
