(function() {
	angular.module('portal.manager.components.dropdown').controller('ManagementDropdownController', controller);

	controller.$inject = [
		'$scope',
		'$q',
		'$timeout',
		'NotificationFactory',
		'OrganizationType',
		'ApplicationUserService',
		'UserRole',
		'Types',
		'ComponentsDropdownModes',
		'ComponentsDropdownDataTypes',
		'DropdownFactory'
	];

	function controller(
		$scope,
		$q,
		$timeout,
		NotificationFactory,
		OrganizationType,
		ApplicationUserService,
		UserRole,
		Types,
		ComponentsDropdownModes,
		ComponentsDropdownDataTypes,
		DropdownFactory
	) {
		var vm = this,
			initTimer,
			initTimerCount = 0,
			dropActions = {
				show: 'show',
				hide: 'hide'
			},
			defaultLimit = 100, // 25,
			offsetIncrement = 100; //25;

		vm.searching = false;
		vm.chosenItems = [];
		vm.showDrop = false;
		vm.showLoadMore = false;
		vm.showLoadMoreCollection = [];
		vm.loadingMore = false;
		vm.element = undefined;
		vm.searchTerm = undefined;
		vm.newGroupName = '';
		vm.foundOrgs = [];
		vm.parents = [];
		vm.showCreateGroupForm = false;

		vm.triggerSearch = triggerSearch;
		vm.addItem = addItem;
		vm.addNewGroup = addNewGroup;
		vm.removeItem = removeItem;
		vm.handleResponse = handleResponse;
		vm.keypressHandler = keypressHandler;
		vm.toggleDropVisibility = toggleDropVisibility;
		vm.showPlus = showPlus;
		vm.initVars = initVars;
		vm.focus = focus;
		vm.setFieldReadyState = setFieldReadyState;
		vm.setFieldValue = setFieldValue;
		vm.initCreateGroup = initCreateGroup;
		vm.onParentFieldModelChange = onParentFieldModelChange;
		vm.init = init;

		vm.initConfig = initConfig;
		vm.initFieldValues = initFieldValues;
		vm.itemIsRelatedToParents = itemIsRelatedToParents;
		vm.itemIsRelatedToParents = itemIsRelatedToParents;
		vm.setupQueue = setupQueue;
		vm.prepareItemData = prepareItemData;
		vm.itemsHasItem = itemsHasItem;

		vm.init();

		$scope.$watchCollection(function() {
			return vm.parentFieldModel;
		}, vm.onParentFieldModelChange);

		function onParentFieldModelChange(n, o) {
			if (o !== n) {
				vm.init();
			}
		}

		function init() {
			vm.initVars();
			vm.showCreateGroupForm = false;
			vm.setFieldReadyState(false);
			var configProps = [
				'dataType',
				'defaultIds',
				'fieldIsReady',
				'includeParent',
				'modelIsReady',
				'label',
				'mode',
				'parentIds',
				'parentFieldIsReady',
				'parentFieldModel',
				'subDataType'
			];

			initTimer = $timeout(function() {
				angular.forEach(configProps, function(prop) {
					vm[prop] = angular.isDefined(vm[prop]) ? vm[prop] : undefined;
				});
				// init field with no parentField dependency
				if (vm.modelIsReady && vm.parentFieldIsReady !== false) {
					$timeout.cancel(initTimer);
					vm.initConfig();
				} else {
					vm.init();
					initTimerCount++;
					if (initTimerCount > 15) {
						$timeout.cancel(initTimer);
						initTimerCount = 0;
					}
				}
			}, 500);
		}

		function setFieldReadyState(state) {
			vm.fieldIsReady = state;
			if (state && vm.directiveInit && typeof vm.directiveInit === 'function') {
				vm.directiveInit();
			}
		}

		function initConfig() {
			vm.disabled = vm.disabled ? vm.disabled : undefined;
			vm.required = vm.required ? vm.required : undefined;
			vm.readOnly = vm.readOnly ? vm.readOnly : false;

			var dependsOnParent = angular.isDefined(vm.parentFieldIsReady) && vm.parentFieldIsReady;

			// set parent ids as parent value
			if (angular.isDefined(vm.parentFieldModel)) {
				vm.parentIds = _.map(vm.parentFieldModel, 'id');
			}

			vm.initFieldValues();

			if (dependsOnParent && angular.isDefined(vm.parentFieldModel) && vm.parentFieldModel.length < 1) {
				vm.disabled = true;
			} else {
				if (!vm.readOnly) {
					vm.disabled = false;
				}
			}

			if (vm.defaultIds) {
				vm.setFieldValue(vm.defaultIds);
			} else {
				vm.setFieldReadyState(true);
			}
		}

		function initFieldValues() {
			var itemProperty =
					vm.dataType === ComponentsDropdownDataTypes.group
						? 'organizationId'
						: vm.dataType === ComponentsDropdownDataTypes.organization
						? 'parentId'
						: 'unknown',
				dependsOnParent = angular.isDefined(vm.parentFieldIsReady) && vm.parentFieldIsReady,
				itemsToRemove = [];

			if (dependsOnParent) {
				if (vm.parentFieldModel.length) {
					// clear values if not related to parent
					angular.forEach(vm.chosenItems, function(item) {
						if (!vm.itemIsRelatedToParents(item, itemProperty, vm.parentFieldModel, 'id')) {
							itemsToRemove.push(item);
						}
					});
					angular.forEach(itemsToRemove, function(i) {
						vm.removeItem(i);
					});
				} else {
					vm.chosenItems = [];
					vm.defaultIds = [];
				}
			}
		}

		function itemIsRelatedToParents(item, itemProperty, parents, parentProperty) {
			_.forEach(parents, function(parent) {
				if (item[itemProperty] === parent[parentProperty]) {
					return true;
				}
			});
			return false;
		}

		function initVars() {
			vm.showLoadMoreCollection = [];
			vm.foundItems = [];
			vm.searchTerm = '';
			vm.queryParams = {
				limit: defaultLimit,
				offset: 0,
				sortby: 'name'
			};
		}

		function initCreateGroup() {
			if (angular.isDefined(vm.parentIds) && vm.parentIds.length && vm.dataType === ComponentsDropdownDataTypes.group) {
				var queue = [];
				angular.forEach(vm.parentIds, function(id) {
					queue.push(DropdownFactory.organizations.get(id));
				});

				if (queue.length > 0) {
					$q.all(queue)
						.then(function(response) {
							angular.forEach(response, function(item) {
								vm.parents.push(item);
							});
						})
						.finally(function() {
							vm.showCreateGroupForm = true;
						});
				}
			}
		}

		function setFieldValue(values) {
			var queue = [],
				proccessQueueCount = 0,
				onSuccess = function(response) {
					if (angular.isArray(response)) {
						angular.forEach(response, function(item) {
							vm.addItem(item);
						});
					} else {
						vm.addItem(response);
					}
				},
				onCatch = function(e) {
					var k = e.config.url.split('/'),
						id = k[k.length - 1] !== 'organizations' ? k[k.length - 1] : undefined,
						item = id ? { id: id, hidden: true, name: 'hidden' } : undefined;
					if (item) {
						vm.addItem(item);
					}
				},
				onFinally = function() {
					proccessQueueCount++;
					if (proccessQueueCount === queue.length) {
						vm.setFieldReadyState(true);
					}
				};

			if (angular.isDefined(values) && values.length > 0) {
				if (vm.dataType === ComponentsDropdownDataTypes.organization) {
					angular.forEach(values, function(id) {
						queue.push(DropdownFactory.organizations.get(id));
					});
				} else if (vm.dataType === ComponentsDropdownDataTypes.group) {
					angular.forEach(values, function(id) {
						queue.push(DropdownFactory.group.get(id));
					});
				} else if (vm.dataType === ComponentsDropdownDataTypes.user) {
					angular.forEach(values, function(id) {
						queue.push(DropdownFactory.user.get(id));
					});
				}

				if (queue.length > 0) {
					angular.forEach(queue, function(r) {
						r.then(onSuccess)
							.catch(onCatch)
							.finally(onFinally);
					});
				} else {
					vm.setFieldReadyState(true);
				}
			} else {
				vm.setFieldReadyState(true);
			}
		}

		function showPlus() {
			return vm.mode === ComponentsDropdownModes.multiselect && !vm.readOnly
				? true
				: !!(vm.mode === ComponentsDropdownModes.singleselect && vm.chosenItems.length <= 0);
		}

		function toggleDropVisibility(action) {
			if (vm.disabled) {
				return false;
			}

			if (angular.isUndefined(vm.parentIds) || vm.parentIds.length === 0) {
				return false;
			}

			if (action === dropActions.show) {
				vm.initVars();
				vm.focus();
				vm.triggerSearch(true, function() {
					vm.searching = false;
				});
				vm.showDrop = true;
			} else {
				vm.showDrop = false;
			}
		}

		function focus() {
			var searchBox = vm.element.find('input#components_dropdown_search_term');
			searchBox.trigger('focus');
		}

		function keypressHandler(event) {
			if (event.which === 13) {
				vm.triggerSearch(true, function() {
					vm.searching = false;
				});
			}
		}

		function triggerSearch(reset, callback) {
			vm.showLoadMoreCollection = [];
			vm.setFieldReadyState(false);
			if (angular.isDefined(vm.parentIds) && vm.parentIds.length > 0) {
				vm.queryParams = reset
					? {
							limit: defaultLimit,
							offset: 0,
							sortby: 'name'
					  }
					: vm.queryParams;

				var queue,
					params = {
						limit: vm.queryParams.limit,
						offset: vm.queryParams.offset,
						search: vm.searchTerm,
						sortBy: vm.queryParams.sortby,
						additionalFields: ['parentName']
					};

				vm.searching = reset !== false;
				vm.foundItems = reset ? [] : vm.foundItems;

				if (angular.isDefined(vm.parentIds) && vm.parentIds.length > 0) {
					queue = vm.setupQueue(vm.parentIds, vm.dataType, params);
					$q.all(queue)
						.then(vm.handleResponse)
						.finally(function() {
							if (callback !== undefined && typeof callback === 'function') {
								callback();
							}
						});
				}
			}
		}

		function setupQueue(parentIds, type, params) {
			var queue = [],
				inc = 0;
			if (type === ComponentsDropdownDataTypes.organization) {
				angular.forEach(parentIds, function(parentId) {
					if (
						vm.showLoadMoreCollection.length === 0 ||
						(angular.isDefined(vm.showLoadMoreCollection[inc]) && vm.showLoadMoreCollection[inc])
					) {
						if (angular.isDefined(vm.subDataType) && vm.subDataType === OrganizationType.school) {
							queue.push(DropdownFactory.organizations.schools(parentId, params));
						} else if (angular.isDefined(vm.subDataType) && vm.subDataType === OrganizationType.district) {
							queue.push(DropdownFactory.organizations.children(parentId, params));
						} else {
							queue.push(DropdownFactory.organizations.all(params));
						}
					}
					inc++;
				});

				if (vm.includeParent) {
					angular.forEach(vm.parentIds, function(parentOrg) {
						queue.push(DropdownFactory.organizations.get(parentOrg, params));
					});
				}
			} else if (type === ComponentsDropdownDataTypes.group) {
				angular.forEach(vm.parentIds, function(parentId) {
					parentId = parentId.trim();
					if (
						vm.showLoadMoreCollection.length === 0 ||
						(angular.isDefined(vm.showLoadMoreCollection[inc]) && vm.showLoadMoreCollection[inc])
					) {
						queue.push(DropdownFactory.organizations.groups(parentId, params));
					}
					inc++;
				});
			} else if (type === ComponentsDropdownDataTypes.user) {
				angular.forEach(vm.parentIds, function(parentId) {
					parentId = parentId.trim();
					if (
						vm.showLoadMoreCollection.length === 0 ||
						(angular.isDefined(vm.showLoadMoreCollection[inc]) && vm.showLoadMoreCollection[inc])
					) {
						queue.push(DropdownFactory.organizations.user(parentId, params));
					}
					inc++;
				});
			}
			return queue;
		}

		function handleResponse(response) {
			// make sure we exclude records already selected
			if (angular.isArray(response)) {
				var data = [],
					inc = 0,
					preparedItem,
					responseData;

				angular.forEach(response, function(r) {
					if (angular.isArray(r)) {
						data.push(r);
					} else if (angular.isDefined(r.items)) {
						data.push(r.items);
					} else {
						data.push([r]);
					}
				});

				angular.forEach(data, function(resp) {
					responseData =
						vm.dataType === ComponentsDropdownDataTypes.organization && angular.isDefined(vm.subDataType)
							? resp
							: vm.dataType === ComponentsDropdownDataTypes.organization
							? resp
							: vm.dataType === ComponentsDropdownDataTypes.group
							? resp
							: vm.dataType === ComponentsDropdownDataTypes.user
							? resp
							: [];
					if (responseData.length > 0) {
						angular.forEach(responseData, function(item) {
							if (!itemsHasItem(item, vm.chosenItems, 'id') && !itemsHasItem(item, vm.foundItems, 'id')) {
								preparedItem = vm.prepareItemData(item);
								vm.foundItems.push(preparedItem);
							}
						});
						inc++;
					}
					vm.showLoadMoreCollection.push(resp.totalCount > vm.queryParams.offset + offsetIncrement);
				});

				vm.foundItems = _.sortBy(vm.foundItems, function(o) {
					return o[vm.queryParams.sortby].toLowerCase();
				});
				vm.showLoadMore = vm.showLoadMoreCollection.indexOf(true) !== -1;
				vm.searching = false;
			} else {
				angular.forEach(response.items, function(item) {
					if (!itemsHasItem(item, vm.chosenItems, 'id') && !itemsHasItem(item, vm.foundItems, 'id')) {
						vm.foundItems.push(item);
					}
				});

				if (vm.includeParent) {
					angular.forEach(vm.parentIds, function(parentOrg) {
						vm.foundItems.push(parentOrg.trim());
					});
				}

				if (vm.foundItems.length === 1) {
					vm.addItem(vm.foundItems[0]);
				}
				vm.showLoadMore = response.totalCount > vm.queryParams.offset + offsetIncrement;
				vm.searching = false;
			}
			vm.foundItems = _.uniq(vm.foundItems, 'id');
			vm.setFieldReadyState(true);
		}

		function prepareItemData(item) {
			var parentIdProperty =
				vm.dataType === ComponentsDropdownDataTypes.group
					? 'organizationId'
					: vm.dataType === ComponentsDropdownDataTypes.organization
					? 'parentId'
					: 'unknown';

			if (vm.dataType !== ComponentsDropdownDataTypes.user) {
				if (!itemsHasItem(item, vm.foundOrgs, 'id')) {
					var regex = new RegExp('00000000-0000-0000-0000');
					if (!regex.test(item[parentIdProperty])) {
						if (itemsHasItem(item, vm.parentFieldModel, 'parentId')) {
							DropdownFactory.organizations.get(item.organizationId).then(function(o) {
								item.ildParentName = o.name;
								item.ildParentId = o.id;
							});
						} else {
							item.ildParentName = item.parentName;
							item.ildParentId = item.parentId;
						}
					}
				} else {
					angular.forEach(vm.foundOrgs, function(org) {
						if (org.id === item[parentIdProperty]) {
							if (itemsHasItem(item, vm.parentFieldModel, 'parentId')) {
								DropdownFactory.organizations.get(org.parentId).then(function(o) {
									item.ildParentName = o.name;
									item.ildParentId = o.id;
								});
							} else {
								item.ildParentName = org.name;
								item.ildParentId = org.id;
							}
						}
					});
				}
			} else {
				item.ildParentName = '';
				item.ildParentId = '';
				//item.ildFoundById = parentRefOrg.id;
				item.name = item.firstName + ' ' + item.lastName;
				// ToDo: need to figure out how to handle user
				// 	since we query against users
				// 	which can be of many roles and not teacher
			}
			return item;
		}

		function itemsHasItem(item, items, property) {
			return _.find(items, function(o) {
				return o[property] === item[property];
			})
				? true
				: false;
		}

		function addItem(item) {
			if (!itemsHasItem(item, vm.chosenItems, 'id')) {
				vm.chosenItems.push(item); // add to chosenItems
				if (itemsHasItem(item, vm.foundItems, 'id')) {
					vm.foundItems.splice(vm.foundItems.indexOf(item), 1); // remove from foundItems
				}
				vm.showDrop = false;
			}
			if (vm.directiveInit && typeof vm.directiveInit === 'function') {
				vm.directiveInit();
			}
		}

		function removeItem(item) {
			if (!vm.readOnly) {
				vm.chosenItems.splice(vm.chosenItems.indexOf(item), 1); // remove from chosenItems
				if (vm.directiveInit && typeof vm.directiveInit === 'function') {
					vm.directiveInit();
				}
			}
		}

		function addNewGroup(groupName, organization) {
			if (vm.dataType === ComponentsDropdownDataTypes.group) {
				var currentUser = ApplicationUserService.getUser(),
					isTeacher = ApplicationUserService.getUserRole() === UserRole.teacher;

				var newlyAddedGroup,
					newGroup = {
						entityType: Types.group,
						name: groupName,
						organizationId: organization.id,
						userIds: isTeacher ? [currentUser.id] : []
					};
				DropdownFactory.group
					.add(newGroup)
					.then(function(response) {
						NotificationFactory.notify(
							{
								heading: 'Student Group ' + newGroup.name + ' created',
								content: 'Student Group ' + newGroup.name + ' created',
								closeAfter: 5
							},
							true
						);

						newlyAddedGroup = {
							id: response.id,
							groupType: response.groupType,
							name: response.name,
							organizationId: response.organizationId
						};
						vm.addItem(newlyAddedGroup);
					})
					.catch(function(error) {
						NotificationFactory.notify(
							{
								heading: 'Something went wrong',
								content: error.data.message,
								closeAfter: 5
							},
							true
						);
					});
			}
		}
	}
})();
