(function() {
	angular.module('portal.manager.components.dropdown').directive('managementDropdown', directive);

	directive.$inject = ['$timeout'];

	function directive($timeout) {
		return {
			restrict: 'A',
			scope: {
				label: '=',
				mode: '=',

				readOnly: '=?',
				dataType: '=',
				subDataType: '=',

				defaultIds: '=',
				parentIds: '=',
				includeParent: '=',

				parentFieldIsReady: '=',
				parentFieldModel: '=',
				modelIsReady: '=',
				fieldIsReady: '='
			},
			templateUrl: 'management/components/dropdown/dropdown.tpl.html',
			controller: 'ManagementDropdownController',
			controllerAs: 'ctrl',
			bindToController: true,
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
				var ctrl = angular.isDefined(scope.ctrl) ? scope.ctrl : {};
				attrs.$observe(
					'disabled',
					function(value) {
						ctrl.disabled = value;
					},
					true
				);

				attrs.$observe(
					'required',
					function(value) {
						ctrl.required = value;
					},
					true
				);

				ctrl.element = element;

				scope.safeApply = function(fn) {
					var phase = this.$root.$$phase;
					if (phase === '$apply' || phase === '$digest') {
						if (fn && typeof fn === 'function') {
							fn();
						}
					} else if (phase === null) {
						fn();
					} else {
						this.$apply(fn);
					}
				};

				scope.$watchCollection(
					function() {
						return ctrl.chosenItems;
					},
					function(n, o) {
						ngModel.$setViewValue(n);
						if (ctrl.required && n.length === 0) {
							ngModel.$invalid = true;
							ngModel.$valid = false;
						}
					}
				);

				ctrl.directiveInit = function() {
					if (ctrl.required) {
						$timeout(function() {
							ngModel.$setValidity('required', !!ctrl.chosenItems.length);
							scope.$apply();
						});
					}
				};

				scope.$watch(
					function() {
						return ctrl.showDrop;
					},
					function(n, o) {
						if (n) {
							element.find('input#components_dropdown_search_term').bind('keydown keypress', ctrl.keypressHandler);
						}
					}
				);

				function plusClickHandler(event) {
					ctrl.dropCss = {
						position: 'fixed',
						top: parseInt(event.clientY) + 16 + 'px'
					};
				}

				element.find('.label .title .icon_plus').bind('click', plusClickHandler);
			}
		};
	}
})();
