(function() {
	angular.module('ildashboard.iladmins').controller('ILAdminsController', controller);

	controller.$inject = ['$scope', '$q', '$filter', 'NotificationFactory', 'ILAdmin', 'PagedList', 'schoolYear', 'CurrentDate'];

	function controller($scope, $q, $filter, NotificationFactory, ILAdmin, PagedList, schoolYear, CurrentDate) {
		var ctrl = this;
		ctrl.id = $scope.$id;
		ctrl.searchTerm = undefined;
		ctrl.ilAdminList = undefined;

		// methods
		ctrl.refreshModel = refreshModel;

		function refreshModel() {
			ctrl.ilAdminList = new PagedList(ILAdmin.query);
			ctrl.ilAdminList.showPerPage = true;
			// ctrl.ilAdminList.params.desc = true;
			ctrl.ilAdminList.params.sortby = ctrl.searchTerm && ctrl.searchTerm.length ? undefined : 'username';
			ctrl.ilAdminList.params.search = ctrl.searchTerm && ctrl.searchTerm.length ? ctrl.searchTerm : undefined;
			ctrl.ilAdminList.load();
		}

		refreshModel();
	}
})();
