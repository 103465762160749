(function() {
	'use strict';

	angular.module('portal.manager.utils.fileChange').directive('ilFileChange', directive);

	directive.$inject = [];

	function directive() {
		return {
			scope: {
				ilFileChange: '&'
			},
			link: function(scope, elem) {
				elem.on('change', function(event) {
					scope.ilFileChange({ $event: event });
				});
				scope.$on('$destroy', function() {
					elem.off();
				});
			}
		};
	}
})();
