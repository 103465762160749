(function() {
	angular
		.module('portal.manager.studentcard', [
			'ui.router',
			'ui.loader',
			'portal.manager.navigation',
			'web.qrcode',
			'api.manager',
			'api.application.user',
			'ui.notifications'
		])
		.config(config)
		.factory('ManagerStudentCardFactory', factory)
		.controller('ManagerStudentCardController', controller);

	config.$inject = ['$stateProvider'];

	function config($stateProvider) {
		$stateProvider
			.state('manager.studentcard', {
				url: '/studentcard',
				controller: controller,
				controllerAs: 'model',
				abstract: true,
				templateUrl: 'management/studentcard/studentcard.layout.tpl.html'
			})
			.state('manager.studentcard.group', {
				url: '/:groupId/group',
				views: {
					students: {
						templateUrl: 'management/studentcard/studentcard.tpl.html'
					}
				}
			})
			.state('manager.studentcard.student', {
				url: '/:studentId/student',
				views: {
					students: {
						templateUrl: 'management/studentcard/studentcard.tpl.html'
					}
				}
			})
			.state('manager.studentcard.bulk', {
				url: '/:type/bulk',
				views: {
					students: {
						templateUrl: 'management/studentcard/studentcard.tpl.html'
					}
				}
			});
	}

	function factory() {
		return {
			students: [],
			groups: [],
			reset: function() {
				delete this.students;
				delete this.groups;
				this.students = [];
				this.groups = [];
			},
			add: function(type, item) {
				if (type === 'student') {
					this.students.push(item);
				}
				if (type === 'group') {
					this.groups.push(item);
				}
			},
			get: function(type) {
				if (type === 'student') {
					return this.students;
				}
				if (type === 'group') {
					return this.groups;
				}
			}
		};
	}

	controller.$inject = [
		'$scope',
		'$state',
		'$q',
		'$window',
		'$timeout',
		'QRCode',
		'NotificationFactory',
		'ManagerStudentCardFactory',
		'Organization',
		'Student',
		'Class'
	];

	function controller(
		$scope,
		$state,
		$q,
		$window,
		$timeout,
		QRCode,
		NotificationFactory,
		ManagerStudentCardFactory,
		Organization,
		Student,
		Class
	) {
		var model = this;

		model.students = [];
		model.loading = false;
		model.returnState = {};
		model.loadStudents = loadStudents;
		model.loadStudentsFinally = loadStudentsFinally;
		model.stateChanged = stateChanged;
		model.studentCardFacory = ManagerStudentCardFactory;
		model.$window = $window;
		model.$timeout = $timeout;
		model.goBack = goBack;
		model._generateQrcode = _generateQrcode;

		angular.element('body').addClass('print');

		function _generateQrcode(student) {
			var str, qrcode;
			if (student.username && student.password && student.sitecode) {
				str = 'username ' + student.username + ' ' + 'password ' + student.password + ' ' + 'sitecode ' + student.sitecode + ' ';
				qrcode = QRCode.generate(str);
				$timeout(function() {
					var img = document.createElement('img');
					img.src = qrcode;
					document.getElementById(student.username).append(img);
				});
			}
			return qrcode;
		}

		function loadStudents(toState, toParams, fromState, fromParams) {
			model.loading = true;
			var params = { offset: 0, limit: -1, sortby: 'lastName,firstName' },
				items,
				dataQueue = [];
			model.returnState.fromState = fromState;
			model.returnState.fromParams = fromParams;
			if (toState.name === 'manager.studentcard.group') {
				Class.students(toParams.groupId, params)
					.then(function(results) {
						model.students = _.sortBy(results.items, 'lastName');
						return model.students;
					})
					.then(function(students) {
						angular.forEach(students, function(student) {
							if (angular.isDefined(student.organizationIds)) {
								var fetchSiteCodeQueue = [];
								angular.forEach(student.organizationIds, function(orgId) {
									fetchSiteCodeQueue.push(Organization.siteCode(orgId));
								});
								$q.all(fetchSiteCodeQueue).then(function(sitecodes) {
									student.sitecode = _.uniqBy(sitecodes, function(e) {
										return e;
									}).toString();
									student.qrcode = model._generateQrcode(student);
									return student;
								});
							} else {
								student.sitecode = 'NONE ASSIGNED';
							}
						});
					})
					.catch(loadStudentsCatch)
					.finally(model.loadStudentsFinally);
			}
			if (toState.name === 'manager.studentcard.student') {
				Student.get(toParams.studentId, params)
					.then(function(student) {
						model.students = [student];
						return student;
					})
					.then(function(student) {
						return _queueLoadStudents(student);
					})
					.catch(loadStudentsCatch)
					.finally(model.loadStudentsFinally);
			}
			if (toState.name === 'manager.studentcard.bulk') {
				items = model.studentCardFacory.get(toParams.type);
				dataQueue = [];
				if (toParams.type === 'group') {
					angular.forEach(items, function(group) {
						dataQueue.push(Class.students(group.id, params));
					});
					$q.all(dataQueue)
						.then(function(groups) {
							angular.forEach(groups, function(group) {
								angular.forEach(group.items, function(student) {
									model.students.push(student);
								});
							});
							_.sortBy(model.students, 'lastName');
							return model.students;
						})
						.then(function(students) {
							angular.forEach(students, function(student) {
								if (angular.isDefined(student.organizationIds)) {
									_queueLoadStudents(student);
								} else {
									student.sitecode = 'NONE ASSIGNED';
								}
							});
							return $q.when();
						})
						.catch(loadStudentsCatch)
						.finally(model.loadStudentsFinally);
				}
				if (toParams.type === 'student') {
					model.studentBulkOperationInSession = true;
					model.students = items;
					angular.forEach(model.students, function(student) {
						if (angular.isDefined(student.organizationIds)) {
							return _queueLoadStudents(student);
						} else {
							student.sitecode = 'NONE ASSIGNED';
						}
					});
				}
			}
		}

		function _queueLoadStudents(student) {
			var fetchSiteCodeQueue = [];
			angular.forEach(student.organizationIds, function(orgId) {
				fetchSiteCodeQueue.push(Organization.siteCode(orgId));
			});
			return $q.all(fetchSiteCodeQueue).then(function(sitecodes) {
				student.sitecode = _.uniqBy(sitecodes, function(e) {
					return e;
				}).toString();
				student.qrcode = model._generateQrcode(student);
				return student;
			});
		}

		function loadStudentsCatch(error) {
			NotificationFactory.error(error);
		}

		function goBack() {
			$timeout(function() {
				model.studentCardFacory.reset();
				$state.go(model.returnState.fromState.name, model.returnState.fromParams, {
					location: 'replace'
				});
			});
		}

		model.studentBulkOperationInSession = false;
		model.studentBulkOperationProgressCount = 1;

		function loadStudentsFinally() {
			if (!model.studentBulkOperationInSession) {
				model.loading = false;
				$timeout(function() {
					model.$window.print();
					model.goBack();
				}, 1000);
			} else {
				model.studentBulkOperationProgressCount++;
				if (model.studentBulkOperationProgressCount >= model.students.length) {
					model.studentBulkOperationInSession = false;
					model.studentBulkOperationProgressCount = 1;
				}
			}
		}

		function stateChanged(event, toState, toParams, fromState, fromParams) {
			if (toState && toParams) {
				model.loadStudents(toState, toParams, fromState, fromParams);
			}
		}

		$scope.$on('$stateChangeSuccess', model.stateChanged);
	}
})();
