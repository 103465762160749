(function() {
	angular
		.module('portal.routing', ['ui.router', 'oauthSignin'])
		.constant('DEFAULT_LOCATION', '/dashboard')
		.constant('DEFAULT_STATE', 'dashboard.user')
		.config(configure);

	configure.$inject = ['$urlRouterProvider', '$stateProvider', '$locationProvider', 'DEFAULT_LOCATION', 'DEFAULT_STATE'];

	function configure($urlRouterProvider, $stateProvider, $locationProvider, DEFAULT_LOCATION, DEFAULT_STATE) {
		$locationProvider.html5Mode(true);
		$locationProvider.hashPrefix('!');

		$stateProvider
			.state('root', {
				controller: 'AppCtrl',
				templateUrl: 'layout/layout.tpl.html',
				data: {
					pageTitleTranslationId: 'header.site_title'
				}
			})
			.state('root.layout', {
				url: '/',
				abstract: true,
				views: {
					header: {
						templateUrl: 'layout/header.tpl.html'
					},
					'': {
						template: '<div ui-view></div>'
					}
				}
			})
			.state('signin', {
				parent: 'root.layout',
				url: 'signin',
				views: { '': { template: `<oauth-signin default-url="'${DEFAULT_LOCATION}'"></oauth-signin>` } }
			});

		$urlRouterProvider.otherwise(function($injector) {
			var $state = $injector.get('$state');
			$state.go(DEFAULT_STATE);
		}); //https://github.com/angular-ui/ui-router/issues/600
	}
})();
