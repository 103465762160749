(function() {
	angular
		.module('portal.manager.group', [
			'ui.router',
			'portal.manager.navigation',
			'portal.manager.paginator',
			'angular.filter',
			'api.manager',
			'ui.notifications'
		])
		.config(config)
		.controller('ManagerGroupController', controller);

	config.$inject = ['$stateProvider', '$urlRouterProvider'];

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('manager.group', {
				controller: controller,
				controllerAs: 'model',
				url: '/group',
				abstract: true,
				templateUrl: 'management/group/group.layout.tpl.html'
			})
			.state('manager.group.staff', {
				url: '/:id/staff',
				views: {
					actions: {
						template: '<section il-manager-actions="paginator_staff" type="staff"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_staff" type="staff"></section>'
					}
				},
				data: { displayName: 'Management/Group/Staff' }
			})
			.state('manager.group.students', {
				url: '/:id/students',
				views: {
					actions: {
						template:
							'<section il-manager-actions="paginator_students" data-organization-id="model.group.organizationId" type="student"></section>'
					},
					'': {
						template: '<section il-manager-paginator="paginator_students" type="student"></section>'
					}
				},
				data: { displayName: 'Management/Group/Students' }
			});
	}

	controller.$inject = ['$scope', '$state', 'Paginator', 'NotificationFactory', 'Navigation', 'Class', '$timeout', 'Types'];

	function controller($scope, $state, Paginator, NotificationFactory, Navigation, Class, $timeout, Types) {
		var model = this;
		model.group = undefined;
		model.id = undefined;
		model.state = undefined;
		model.stateChanged = stateChanged;

		var staff = new Paginator(
			$scope,
			'paginator_staff',
			function() {
				return Class.staff(model.id, staff.params);
			},
			model.id
		);
		staff.params = {
			sortby: 'lastName,firstName',
			additionalFields: ['studentCount']
		};

		var students = new Paginator(
			$scope,
			'paginator_students',
			function() {
				return Class.students(model.id, students.params);
			},
			model.id
		);
		students.params = {
			sortby: 'lastName,firstName',
			additionalFields: ['lexileSettings']
		};

		function getGroup(id) {
			if (!id) {
				NotificationFactory.error({ message: 'Invalid id' });
			}

			model.id = id;
			if (model.group && model.group.id === id) {
				return;
			}

			Class.get(id)
				.then(function(result) {
					model.group = result;
					model.group.organizationId = result.organizationId;
					model.group.displayText = result.name;
					model.group.entityType = Types.group;
					Navigation.fetchBreadcrumbs(model.group);
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		function stateChanged(event, toState, toParams, fromState, fromParams) {
			if (toState.name.indexOf('manager.group') !== -1) {
				model.state = toState.name;
				getGroup(toParams.id);

				switch (model.state) {
					case 'manager.group.staff':
						$timeout(function() {
							$scope.paginator_staff.doInitialLoad();
						});
						break;

					case 'manager.group.students':
						$timeout(function() {
							$scope.paginator_students.doInitialLoad();
						});
						break;
				}
			}
		}

		$scope.$on('$stateChangeSuccess', stateChanged);
	}
})();
